import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(({ spacing }) => ({
  card: {
    marginTop: 40,
    borderRadius: spacing(0.5),
    transition: '0.3s',
    maxWidth: 500,
    minWidth: 500,
    margin: 40,
    overflow: 'initial',
    background: '#ffffff',
  },
  centerInfo: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  content: {
    paddingTop: 10,
    textAlign: 'left',
    overflowX: 'auto',
    overflowY: 'auto',
    maxWidth: 500,
    maxHeight: 310,
    '& table': {
      marginBottom: 0,
    },
  },
  cardBackground: {
    backgroundColor: '#01579b',
    color: 'white',
  },
  typografyTitle: {
    fontSize: 20,
  },
  typografyInfo: {
    fontSize: 14,
    marginBottom: 10,
  },
  chartPie: {
    maxWidth: 300,
    maxHeight: 310,
    overflowX: 'auto',
    overflowY: 'auto',
    textAlign: 'left',
    paddingTop: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
