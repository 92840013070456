import React, { useState } from 'react';
import ButtonLoading from '../../commons/buttonLoading/ButtonLoading';
import { Typography } from '@material-ui/core';
import LogoTipoTelliou from '../../assets/img/logotipo_telliou.png';
import InputPasswordNew from '../../commons/InputPasswordNew';
const ResetPassword = ({
  handleSubmitCode,
  errorNewPassword,
  setNewPassword,
  errorCode,
  setCode,
  loading,
  classes,
  error,
}) => {
  const [seePassword, setSeePassword] = useState(false);

  return (
    <form className={classes.container} onSubmit={handleSubmitCode}>
      <div className={classes.containerResetPassword}>
        <div className={classes.resetPassword}>
          <div className={classes.resetPasswordTitle}>
            <img
              src={LogoTipoTelliou}
              alt='Logo Telliou'
              className={classes.imgTelliou}
            />
            <h3>Restablecer la contraseña</h3>
          </div>

          <p style={{ textAlign: 'center', fontSize: '14px', color: '#fff' }}>
            Por favor, ingresar el código enviado a su correo electrónico e
            ingresa una nueva contraseña.
          </p>

          <input
            className={classes.inputResetPassword}
            type='number'
            name='codigo'
            id=''
            placeholder='Codigo*'
            onChange={(e) => setCode(e.target.value)}
            // helperText={errorCode}
            // error={Boolean(errorCode)}
          />

          <InputPasswordNew
            placeholder='Nueva Contraseña *'
            visibility={seePassword}
            onChange={(e) => setNewPassword(e.target.value)}
            // helperText={errorNewPassword}
            // error={Boolean(errorNewPassword)}
            onClick={() => setSeePassword(!seePassword)}
          />

          <Typography className={classes.messageError} variant='subtitle1'>
            {error}
          </Typography>

          <div className={classes.container_button}>
            <ButtonLoading loading={loading} label='Guardar' />
          </div>
        </div>
      </div>
    </form>
  );
};

export default ResetPassword;
