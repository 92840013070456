import React from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Graph from '../../../commons/Graph';

import {
  listAllAnswerCallByIdPerDay,
  listAllAnswerCallByIdPerWeek,
  listAllAnswerCallByIdPerMonth,
  listAllResponseCallByIdPerDay,
  listAllResponseCallByIdPerWeek,
  listAllResponseCallByIdPerMonth,
} from '../../../Service/graphics/GraphicsConsumeService';
import {
  defaultValuesCallsAnswer,
  defaultValuesCallsEffective,
  defaultData,
} from '../ProcessedCalls';

const GroupButton = ({
  data,
  setData,
  legend,
  setCallAllsAnswers,
  setAllCallsEffective,
  isActive,
  setIsActive,
}) => {
  let idAccount = localStorage.getItem('idAccount');
  return (
    <>
      <ButtonGroup color='primary' aria-label='outlined primary button group'>
        <Button
          onClick={() => {
            setCallAllsAnswers(defaultValuesCallsAnswer());
            setAllCallsEffective(defaultValuesCallsEffective());
            setData(defaultData());
            (async () => {
              let responseDay = await listAllResponseCallByIdPerDay(idAccount);
              let answersDay = await listAllAnswerCallByIdPerDay(idAccount);
              setData([
                {
                  name: '12-6 am',
                  llamadasEfectivas: responseDay[0].zeroToSix,
                  llamadasRecibidas: answersDay[0].zeroToSix,

                  amt: 100,
                },
                {
                  name: '6-12 m',
                  llamadasEfectivas: responseDay[0].sixToTwelve,
                  llamadasRecibidas: answersDay[0].sixToTwelve,
                  amt: 100,
                },
                {
                  name: '12-6 pm',
                  llamadasEfectivas: responseDay[0].twelveToEighteen,
                  llamadasRecibidas: answersDay[0].twelveToEighteen,
                  amt: 100,
                },
                {
                  name: '6-12 pm',
                  llamadasEfectivas: responseDay[0].eighteenToTwentyFour,
                  llamadasRecibidas: answersDay[0].eighteenToTwentyFour,
                  amt: 100,
                },
              ]);
            })();
            setIsActive(true);
          }}
        >
          Diarias
        </Button>
        <Button
          onClick={() => {
            setCallAllsAnswers(defaultValuesCallsAnswer());
            setAllCallsEffective(defaultValuesCallsEffective());
            setData(defaultData());
            (async () => {
              let responseWeek = await listAllResponseCallByIdPerWeek(
                idAccount,
              );
              let answersWeek = await listAllAnswerCallByIdPerWeek(idAccount);
              let answersWeekCallZeroToSix = answersWeek[1] !== undefined ?  answersWeek[1].zeroToSix: 0;
              let answersWeekCallSixToTwelve = answersWeek[1] !== undefined ?  answersWeek[1].sixToTwelve: 0;
              let answersWeekCallTwelveToEighteen = answersWeek[1] !== undefined ?  answersWeek[1].twelveToEighteen: 0;
              let answersWeekEighteenToTwentyFour = answersWeek[1] !== undefined ?  answersWeek[1].eighteenToTwentyFour: 0;
              setData([
                {
                  name: '12-6 am',
                  llamadasEfectivas: responseWeek[0].zeroToSix,
                  llamadasRecibidas: answersWeek[0].zeroToSix + answersWeekCallZeroToSix,

                  amt: 100,
                },
                {
                  name: '6-12 m',
                  llamadasEfectivas: responseWeek[0].sixToTwelve,
                  llamadasRecibidas:
                    answersWeek[0].sixToTwelve + answersWeekCallSixToTwelve,
                  amt: 100,
                },
                {
                  name: '12-6 pm',
                  llamadasEfectivas: responseWeek[0].twelveToEighteen,
                  llamadasRecibidas:
                    answersWeek[0].twelveToEighteen +
                    answersWeekCallTwelveToEighteen,
                  amt: 100,
                },
                {
                  name: '6-12 pm',
                  llamadasEfectivas: responseWeek[0].eighteenToTwentyFour,
                  llamadasRecibidas:
                    answersWeek[0].eighteenToTwentyFour +
                    answersWeekEighteenToTwentyFour,
                  amt: 100,
                },
              ]);
            })();
            setIsActive(true);
          }}
        >
          Semanal
        </Button>
        <Button
          onClick={() => {
            setCallAllsAnswers(defaultValuesCallsAnswer());
            setAllCallsEffective(defaultValuesCallsEffective());
            setData(defaultData());
            (async () => {
              let responseMonth = await listAllResponseCallByIdPerMonth(
                idAccount,
              );
              let answersMonth = await listAllAnswerCallByIdPerMonth(idAccount);
              let answersMonthZeroToSix = answersMonth[1] !== undefined ? answersMonth[1].zeroToSix : 0;
              let answersMonthSixToTwelve = answersMonth[1] !== undefined ? answersMonth[1].sixToTwelve : 0;
              let answersMonthTwelveToEighteen = answersMonth[1] !== undefined ? answersMonth[1].twelveToEighteen : 0;
              let answersMonthEighteenToTwentyFour = answersMonth[1] !== undefined ? answersMonth[1].eighteenToTwentyFour : 0;
              setData([
                {
                  name: '12-6 am',
                  llamadasEfectivas: responseMonth[0].zeroToSix,
                  llamadasRecibidas:
                    answersMonth[0].zeroToSix + answersMonthZeroToSix,

                  amt: 100,
                },
                {
                  name: '6-12 m',
                  llamadasEfectivas: responseMonth[0].sixToTwelve,
                  llamadasRecibidas:
                    answersMonth[0].sixToTwelve + answersMonthSixToTwelve,
                  amt: 100,
                },
                {
                  name: '12-6 pm',
                  llamadasEfectivas: responseMonth[0].twelveToEighteen,
                  llamadasRecibidas:
                    answersMonth[0].twelveToEighteen +
                    answersMonthTwelveToEighteen,
                  amt: 100,
                },
                {
                  name: '6-12 pm',
                  llamadasEfectivas: responseMonth[0].eighteenToTwentyFour,
                  llamadasRecibidas:
                    answersMonth[0].eighteenToTwentyFour +
                    answersMonthEighteenToTwentyFour,
                  amt: 100,
                },
              ]);
            })();

            setIsActive(true);
          }}
        >
          Mensual
        </Button>
      </ButtonGroup>
      {isActive && data !== [] ? (
        <Graph data={data} legend={legend} />
      ) : (
        <h1>No hay datos</h1>
      )}
    </>
  );
};

export default GroupButton;
