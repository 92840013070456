import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
    marginTop: 50,
  },
  button: {
    backgroundColor: '#01579b',
    marginTop: '18px',
    margin: theme.spacing(1),
  },
  btn: {
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    height: '33px',
    padding: '0px 15px',
    margin: '1em',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Roboto Condensed',
    fontWeight: '300',
    borderRadius: '25px',
    backgroundColor: '#e55959',
    '&:hover': {
      backgroundColor: '#e55959',
      filter: 'brightness(70%)',
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,

  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  textArea: {
    width: '20%',
    marginRight: '10px',
    height: '150px',
    border: '2px solid #CCCCCC',
    background: '#F2F2F2',
    fontSize: '20px',
    fontFamily: 'arial',
  },
  import: {
    width: '80%',
    marginTop: '10px',
  },
  btnCSV: {
    fontFamily: 'Roboto Condensed',
    width: 'fit-content',
    fontSize: '20px',
    cursor: 'pointer',
    height: '33px',
    padding: '0px 15px',
    margin: '1em',
    borderRadius: '25px',
    backgroundColor: '#e55959',
    '&:hover': {
      backgroundColor: '#e55959',
      filter: 'brightness(70%)',
    },
  },
  labelbtnCSV:{
    color: 'white',
  },
  grid:{
    display: 'flex',
    justifyContent: 'center'
  },

}));
export default useStyles;
