import React, { useState, useEffect } from 'react';
import { findAccountSubscription } from '../../Service/account/AccountConsumeService';
import ImgMediaCard from '../../components/card/botCard';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  centerInfo: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  }

})

const SuperAdmin = () => {
  const classes = useStyles();
  const [request, setRequest] = useState([]);

  useEffect(() => {
    const getListAccountSubscription = async () => {
      let dataRequest = await findAccountSubscription();
      setRequest(dataRequest);
    };
    getListAccountSubscription();
  }, []);

  return (

    <Grid container spacing={1}>
      <Grid container item xs={12} spacing={3} className={classes.centerInfo}>
        {request.map((item) => (
          <ImgMediaCard card={item} />
        ))}
      </Grid>
    </Grid>
  );
};

export default SuperAdmin;
