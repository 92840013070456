import axios from 'axios';
import {UriConfiguration}  from '../configuration/ApiServices'

let baseURL = UriConfiguration();


export const insertAccount = async (data) => {
  try {
    return await axios
      .post(`${baseURL}/AccountControllerNew`, data)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error insert account service', { error });
  }
};

export const createAccount = async (data) => {
  try {
    return await axios
      .post(`${baseURL}/AccountControllerNew`, data)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error create service IntentService', { error });
  }
};

export const findAccountById = async (id) => {
  try {
    return await axios
      .get(`${baseURL}/AccountControllerNew/${id}`)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error create service IntentService', { error });
  }
};

export const updateAccountById = async (id, body) => {
  try {
    return await axios
      .put(`${baseURL}/AccountControllerNew/${id}`, body)
      .then((response) => response.data.accountCreated);
  } catch (error) {
    console.error('Error create service IntentService', { error });
  }
};

export const findAccountSubscription = async () => {
  try {
    return await axios
      .get(`${baseURL}/AccountControllerNew/findAllAccountSubscription`)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error create service IntentService', { error });
  }
};

export const findAccountSubscriptionDetails = async (id) => {
  try {
    return await axios
      .get(`${baseURL}/AccountControllerNew/findAccountSubscriptionDetails/${id}`)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error create service IntentService', { error });
  }
};

export const findBotDetails = async (id) => {
  try {
    return await axios
      .get(`${baseURL}/AccountControllerNew/findBotDetails/${id}`)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error create service IntentService', { error });
  }
};

export const findMinutesByIdPerMonth = async (id) => {
  try {
    return await axios
      .get(`${baseURL}/api/v1/account/findMinutesByIdPerMonth/${id}`)
      .then((response) => response.data.minutesByIdPerMonthCreated);
  } catch (error) {
    console.error('Error create service IntentService', { error });
  }
};

export const findMinutesAvailableById = async (id) => {
  try {
    return await axios
      .get(`${baseURL}/v2/subscription/minutesAvailable/${id}`)
      .then((response) => response.data.minutesAvailable);
  } catch (error) {
    console.error('Error create service IntentService', { error });
  }
};


export const getListVoices = async () => {
  try {
    return await axios
      .get(`${baseURL}/VoiceController/`)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error create service list Voices Polly aws', { error });
  }
};