import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  Container: {
    display: 'grid',
    marginTop: theme.spacing(6),
    justifyItems: 'center',
    gridAutoRows: 'auto',
  },
  Container_user: {
    display: 'grid',
    maxWidth: '430px',
    justifyItems: 'center',
    marginTop: '120px',
  },
  button: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 14,
  },
}));

export default useStyles;
