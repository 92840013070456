import axios from 'axios';

import {UriConfiguration}  from '../configuration/ApiServices'

let baseURL = UriConfiguration();

export const listUserByDocument = async (phoneNumber, idAccount) => {
  try {
    return await axios
      .get(`${baseURL}/UserControllerNew/findUserInfoByPhoneNumberAndIdAccount/${phoneNumber}/${idAccount}`)
      .then((response) => response.data);
  } catch {
    console.error('Error delete service IntentService');
  }
};

export const conversationList = async (idUser) => {
  try {
    return await axios
      .get(`${baseURL}/CallsControllerNew/findUserAnswers/${idUser}`)
      .then((response) => response.data);
  } catch {
    console.error('Error delete service IntentService');
  }
};

export const updateStateUser = async (id, state) => {
  try {
    return await axios
      .put(`${baseURL}/api/v1/updateState/${id}/${state}`)
      .then((response) => response.data);
  } catch {
    console.error('Error delete service IntentService');
  }
};

export const listUsers = async (id) => {
  try {
    return await axios
      .get(`${baseURL}/UserControllerNew/findUserCallsResponse/${id}`)
      .then((response) => response.data);
  } catch {
    console.error('Error delete service IntentService');
  }
};