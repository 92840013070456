import React, { useState,useEffect } from 'react';
import {InputLabel,MenuItem,TextField,FormControl,Container,Paper,TableBody,TableCell,TablePagination,TableContainer,TableHead,TableRow,Button,Box,Select,Table} from '@material-ui/core';
import {DateTimePicker} from '@material-ui/pickers';
import {addPeriodicCall,listPeriodicCallsByIdAndState,cancelPeriodicCall} from '../../../Service/calls/CallsConsumeService';
import useStyles from './styles';

const PeriodicCalls = () => {
  let idAccount = localStorage.getItem('idAccount');
  const [phoneNumber, setPhoneNumber] = useState({});
  const [fechaPeriodica, cambiarFechaPeriodica] = useState(new Date());
  const [periodicList, setPeriodicRows] = useState([]);
  const regex = /^[0-9]*$/;
  const classes = useStyles();

  // Listar perdiodic calls al cargar la pagina
  useEffect(() => {
    async function listFirstPeriodicCalls(){
      const listPeriodicCallsFirts = await listPeriodicCallsByIdAndState(idAccount, 'active');
      setPeriodicRows(listPeriodicCallsFirts);
    }
    listFirstPeriodicCalls();
  }, []);

  /*Listar llamadasperiodicas por id de la cuenta y los estados */
  const listPeriodicCallsActivated = async () => {
    const listPeriodicCalls = await listPeriodicCallsByIdAndState(idAccount,"active");
    setPeriodicRows(listPeriodicCalls);
  };

  const listPeriodicCallsCanceled = async () => {
    const listPeriodicCalls = await listPeriodicCallsByIdAndState(idAccount,"canceled");
    setPeriodicRows(listPeriodicCalls);
  };

  
  /*Periodicas que se repita cada i veces*/
  const [repeat, setRepeat] = useState([]);
  const changeRepeat = (e) => {
    setRepeat(e.target.value);
  };

  /*Periodicas Cada Dia, Cada Semana o Cada Mes*/
  const [option, setOption] = useState([]);
  const changeOption = (e) => {
    setOption(e.target.value);
  };

  const periodicCall = async (e) => {
    e.preventDefault();

    let validateNumber = numberValidation(phoneNumber);
    let validateDate = dateValidation(fechaPeriodica);
    let validateForm = formValidation();

    if(validateNumber && validateDate && validateForm){
      const optionSplit = option.split("-");
      const do_Every = repeat+"-"+optionSplit[1];
  
      let repeat_Every = (optionSplit[0]/repeat)*1000;
      let hoy = new Date();
      
      const periodic ={
        id: "",
        account_id: idAccount,
        phoneNumber: phoneNumber,
        do_Every: do_Every,
        repeat_Every: repeat_Every,
        startime: fechaPeriodica,
        state: null,
        createAt: hoy,
        updateAt: null,
      };
      console.log(periodic);
  
      await addPeriodicCall(periodic);
      let periodicCalls = await listPeriodicCallsByIdAndState(idAccount, 'active');
      setPeriodicRows(periodicCalls);
      alert("Se ha agregado la llamada periodica");
    }else{
      alert("ERROR AL PROGRAMAR UNA LLAMADA PERIODICA");
    }
  }

  //Cancelar programacion de la llamada
  const quitarLlamadaPeriodica = async (scheduledCall) => {
    window.confirm(
      'Quieres eliminar la llamada periodica del numero->' +
        scheduledCall.phoneNumber +
        '?'
    );

    await cancelPeriodicCall(scheduledCall.id);

    let periodicCalls = await listPeriodicCallsByIdAndState(
      idAccount,
      'active'
    );
    setPeriodicRows(periodicCalls);
  };

  //Traduccion del estado de las llamadas
  let traduccion = (state) => {
    if (state == 'active') {
      return 'Activo';
    } else if (state == 'canceled') {
      return 'Cancelada';
    }
  };

  /*Validaciones de numero y de fecha */
  const numberValidation = (e) => {
    if(!regex.test(e)){
      alert("El numero no es valido");
      return false;
    }else if(e.length>10 || e.length<10){
      alert("El numero debe contener 10 digitos");
      return false;
    }
    return true;
  }

  const dateValidation = (e) => {
    let hoy = new Date();
    if(e<hoy){
      alert("La fecha no puede ser antes de la fecha actual");
      return false;
    }else{
      return true;
    }
  }

  const formValidation = () => {
    if(option.length >0 && repeat > 0){
      return true;
    }else{
      alert("POR FAVOR ELIJA LAS OPCIONES DE REPETIR LLAMADA PERIODICAMENTE");
      return false;
    }
  }

  /*Paginacion de la tabla*/
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, periodicList.length - page * rowsPerPage);

  return (
    <div className='call__box'>
      <Box>
        <h2>Hacer Llamadas Periodicas</h2>
        <p>Repetir Cada</p>
        <FormControl required >
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          Veces
        </InputLabel>
          <Select
            value={repeat}
            onChange={changeRepeat}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </FormControl>
        <FormControl required >
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          Cada
        </InputLabel>
          <Select
            value={option}
            onChange={changeOption}
          >
            <MenuItem value={"86400-Dia"}>Día</MenuItem>
            <MenuItem value={"604800-Semana"}>Semana</MenuItem>
            <MenuItem value={"2629743.8-Mes"}>Mes</MenuItem>
          </Select>
        </FormControl>
        <br></br>
        <DateTimePicker 
            label= 'Empieza'
            value={fechaPeriodica.setSeconds(0)}
            minDate={new Date()}
            ampm={true}
            onChange={cambiarFechaPeriodica}
            disablePast={true}
            format='yyyy-MM-dd h:mm'
            margin='normal'
        />
        <br></br>
        <TextField
          required={true}
          id='standard-number'
          label='Ingrese número teléfono'
          type='number'
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          margin='normal'
          onChange={(evt) => setPhoneNumber(evt.target.value)}
        />

        <Button 
          variant='contained'
          color='primary'
          className={classes.btn}
          onClick={periodicCall}
        >
          Programar
        </Button>
      </Box>

      <button className={classes.btn} onClick={listPeriodicCallsActivated}>
        Llamadas Programadas
      </button>

      <button className={classes.btn} onClick={listPeriodicCallsCanceled}>
        Llamadas Canceladas
      </button>

      <Container style={{ margin: 'auto' }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label='custom pagination table'>
            <TableHead style={{ backgroundColor: '#1b3c5a' }}>
              <TableRow>
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Numero de teléfono
                </TableCell>
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Fecha de createAr
                </TableCell>
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Repetir
                </TableCell>
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Fecha de Startime
                </TableCell>
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Estado
                </TableCell>
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Eliminar programación
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {periodicList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((x) => (
                <TableRow>
                  <TableCell align='center'>
                    {x.phoneNumber}
                  </TableCell>
                  <TableCell align='center'>
                    {x.createAt}
                  </TableCell>
                  <TableCell align='center'>
                    {x.do_Every}
                  </TableCell>
                  <TableCell align='center'>
                    {x.startime}
                  </TableCell>
                  <TableCell align='center'>
                    {traduccion(x.state)}
                  </TableCell>
                  <TableCell align='center'>
                    <button
                      className={classes.btn}
                      onClick={() => quitarLlamadaPeriodica(x)}
                    >
                      Eliminar
                    </button>
                  </TableCell>
                </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={periodicList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Container>
    </div>
  );
};

export default PeriodicCalls;
