import { TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ButtonLoading from '../../commons/buttonLoading/ButtonLoading';
import { Auth } from 'aws-amplify';
import { insertAccount } from '../../Service/account/AccountConsumeService';
import { insertUser } from '../../Service/platformUser/PlatformUserConsumeService';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
let platformU = null;

const User = () => {
  const [username, setUsername] = useState('');
  const [userSub, setUserSub] = useState('');
  const [idAccount, setIdAccount] = useState('');

  const platformUser = {
    nameCognito: username,
    idCognito: userSub,
    idAccount: idAccount,
  };

  const [account] = useState({
    botName: 'Modificar bot name',
    sipAddress: 'Modificar SIP address',
    sipExtension: 'Modificar SIP extension',
    sipPort: 'Modificar SIP port',
    idSubscriptionModel: '1',
    idVoice: 'Penelope',
  });

  platformU = platformUser;

  const [email, setEmail] = useState('');
  const [password,] = useState('');

  const [errorUsername, setErrorUsername] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [, setErrorPassword] = useState('');

  const [error, setError] = useState('');
  const [save, setSave] = useState('');
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!username) {
      setLoading(false);
      setErrorUsername('Ingrese un nombre de usuario');
    }
    if (!email.includes('@')) {
      setLoading(false);
      setErrorEmail('Ingrese un correo electrónico');
    }
    if (!password) {
      setLoading(false);
      setErrorPassword('Ingrese una contraseña');
    }

    Auth.signUp({
      username: email,
      password: 'juancrv123',
      attributes: {
        email: email,
      },
    })
      .then((data) => {
        setUserSub(data.userSub);
        setSave('Se ha guardado exitosamente');
        insertPlatformUserFunction();
      })

      .catch((err) => {
        setLoading(false);
        if (err.code === 'UsernameExistsException') {
          clean();
          setError('Ya existe una cuenta con este correo electrónico.');
        }
      });

    Auth.verifyTotpToken(userSub)
      .then(() => history.push('/user'))
      .catch((err) => console.log(JSON.stringify(err)));
  };

  const insertPlatformUserFunction = async () => {
    await insertAccount(account);
    setIdAccount(localStorage.getItem('idAccount'));
    await insertUser(platformU);
    console.log(idAccount);
  };

  const clean = () => {
    setErrorUsername('');
    setErrorEmail('');
    setErrorPassword('');
  };

  /* const handle = (e) => {
     Auth.resendSignUp();
   };
   */

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <div className={classes.Container}>
        <div>
          <TextField
            helperText={errorUsername}
            onChange={(e) => setUsername(e.target.value)}
            variant='outlined'
            fullWidth
            margin='normal'
            label='Agregar usuario *'
            error={Boolean(errorUsername)}
          />
          <TextField
            helperText={errorEmail}
            onChange={(e) => setEmail(e.target.value)}
            variant='outlined'
            fullWidth
            margin='normal'
            label='Correo electrónico *'
            error={Boolean(errorEmail)}
          />
          {/* <div>
           <TextField
            helperText={errorPassword}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
            label="Contraseña *"
            error={Boolean(errorPassword)}
          />
          </div> */}

          <Typography className='' color='primary' variant='subtitle1'>
            {save}
          </Typography>
          <Typography className='' color='secondary' variant='subtitle1'>
            {error}
          </Typography>

          <div className={classes.button}>
            <ButtonLoading loading={loading} label='Invitar usuario' />

            {/* <Button color='primary' variant='contained' onClick={handle}>
            Mandar mensaje
          </Button>  */}
          </div>
        </div>
      </div>
    </form>
  );
};

export default User;
