import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Container, TableHead, Checkbox, Typography } from '@material-ui/core';
import Chat from '@material-ui/icons/Chat';
import ArrowBack from '@material-ui/icons/ArrowBack';
import InputSearch from '../../commons/InputSearch';
import Modal from './modal';

import {
  listUsers,
  listUserByDocument,
  conversationList
} from '../../Service/conversationAnalysis/conversationAnalysisConsumeService';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  tableCell: {
    fontWeight: 'bold',
    fontSize: 15,
    color: '#FFFFFF',
  },
});

export default function CustomPaginationActionsTable() {
  const [userInformation, setUserInformation] = useState([]);
  const [conversation, setConversation] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [
    pageDetailsUserInformation,
  ] = React.useState(0);

  const [rows, setRows] = useState([]);
  const [tableInfoUser, setTableInfoUser] = useState(false);
  const [search, setSearch] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const [, setCheckedItems] = useState([]);
  const id_account = localStorage.getItem('idAccount');
  const idAsistant = '3470f142-46a9-4c12-b893-acd362a0bd63';
  const classes = useStyles2();

  useEffect(() => {
    const users = async () => {
      const user = await listUsers(id_account);
      setRows(user);
    };
    users();
  }, []);

  const userByDocument = async (phoneNumber) => {
    const user = await listUserByDocument(phoneNumber, id_account);
    //console.log(user);
    setUserInformation(user);
    setTableInfoUser(true);
  };

  const listConversation = async (idUser) => {
    const listConver = await conversationList(idUser);
    setConversation(listConver);

    //console.log(conversation);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeCheck = (e, row) => {
    let checked = e.target.checked;
    setCheckedItems(
      rows.map((data) => {
        if (row.phone_number === data.phone_number) {
          data.state = checked;
         // updateStateUser(data.id, data.state);
        }
        return data;
      })
    );
  };

  const filterData = rows.filter((item) => {
    let date = item.date_time.replaceAll("T", " ")
    return `${item.phone_number} ${date}`
      .toLowerCase()
      .includes(search.toLowerCase());
  });

  const filterDataInfo = userInformation.filter((item) => {
    let date = item.date_time.replaceAll("T", " ")
    return `${date}`.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <Container style={{ margin: 'auto' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 22,
        }}
      >
        {tableInfoUser && (
          <IconButton onClick={() => setTableInfoUser(false)}>
            <ArrowBack />
          </IconButton>
        )}
        <InputSearch
          widthContainer='100%'
          widthInput='70%'
          onChange={(e) => setSearch(e.target.value)}
          classes={classes}
          value={search}
          label='Buscar'
        />
      </div>
      {console.log(tableInfoUser)}
      {tableInfoUser ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label='custom pagination table'>
            <TableHead style={{ backgroundColor: '#1b3c5a' }}>
              <TableRow>
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Teléfono Usuario
                </TableCell>
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Fecha de llamada
                </TableCell>
                {id_account === idAsistant ?
                  <TableCell style={{ color: '#FFF' }} align='center'>
                    Documento de usuario
                  </TableCell>
                  : null}
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Conversación
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 ? (
                <TableCell>
                  <Typography variant='subtitle1'>
                    No hay registros de llamadas
                  </Typography>
                </TableCell>
              ) : (
                <>
                  {(rowsPerPage > 0
                    ? filterDataInfo.slice(
                      pageDetailsUserInformation * rowsPerPage,
                      pageDetailsUserInformation * rowsPerPage + rowsPerPage,
                    )
                    : rows
                  ).map((row, key) => (
                    <TableRow
                      style={{ cursor: 'pointer' }}
                      key={key}
                      hover
                      onClick={() => {
                        listConversation(row.id);
                      }}
                    >
                      <TableCell align='center'>
                        {row.phone_number}
                      </TableCell>
                      <TableCell align='center'>{row.date_time.replaceAll("T", " ")}</TableCell>
                      {row.document_value !== "Sin Registro" ?
                        <TableCell align='center'>{row.document_value}</TableCell>
                        : null}
                      <TableCell align='center'>
                        <IconButton onClick={() => setOpenModal(true)}>
                          <Chat />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}

              {emptyRows > 0 && rows.length !== 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={pageDetailsUserInformation}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label='custom pagination table'>
            <TableHead style={{ backgroundColor: '#1b3c5a' }}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Teléfono Usuario
                </TableCell>
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Fecha de llamada
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle1'>
                        No hay registros de llamadas
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  {(rowsPerPage > 0
                    ? filterData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                    : rows
                  ).map((row, key) => (
                    <TableRow
                      key={key}
                      hover
                      style={{ cursor: 'pointer' }}
                      onClick={() => userByDocument(row.phone_number)}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          checked={row.state}
                          color='primary'
                          onChange={(e) => handleChangeCheck(e, row)}
                        />
                      </TableCell>
                      <TableCell align='center'>{row.phone_number}</TableCell>
                      <TableCell align='center'>
                        {row.date_time.replaceAll("T", " ")}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
              {emptyRows > 0 && rows.length !== 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}

      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        conversation={conversation}
      />
    </Container>
  );
}
