import React, { useState } from 'react';
import useStyles from '../../../commons/TreeModal/styles';
import { Box, Grid, TextField, Tooltip } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import IconoModificar from '../../../assets/img/icono_guardar.png';
import Modal from '@material-ui/core/Modal';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        color: 'white',
        backgroundColor: '#e55959',
      },
    },
  },
});
// import {addMessageTag} from '../../../commons/TreeModal/modal'
function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //height : "200px"
  };
}
const ModalUpdateNode = ({
  open,
  onClose,
  intent,
  handleSaveIntent,
  setMessagesUpdate,
  messagesUpdate,
  phraseUpdate,
  setPhraseUpdate,
  progreso,
  initialStatePhrases,
  validationInsert,
  stateValidationInsert,
  stateValidation,
}) => {
  let inputMessage = React.createRef();
  let inputPhrase = React.createRef();
  let idGUpdateMessage = 0;
  let idGUpdatePhrase = 0;

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [stateMessagesUpdate, setStateMessagesUpdate] = useState(true);
  const [, setMessagesUpdateUpdate] = useState('');
  const [statePhraseUpdate, setStatePhraseUpdate] = useState(true);
  const [, setPhraseUpdateUpdate] = useState('');
  console.log(stateValidation);
  console.log(stateValidationInsert);

  const removeMessageTag = (indexRemove) => {
    if (window.confirm('¿Deseas eliminar el mensaje?')) {
      setMessagesUpdate(messagesUpdate.filter((_, i) => i !== indexRemove));
      inputMessage.current.value = '';
      setStateMessagesUpdate(true);
    }
  };

  const addMessageTag = (event) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      setMessagesUpdate([...messagesUpdate, event.target.value]);
      event.target.value = '';
    }
  };

  const updateMessageTagFun = (event2) => {
    if (event2.key === 'Enter' && event2.target.value !== '') {
      if (window.confirm('¿Deseas modificar el mensaje?')) {
        messagesUpdate[idGUpdateMessage] = inputMessage.current.value;
        setMessagesUpdateUpdate(messagesUpdate[idGUpdateMessage]);
        setStateMessagesUpdate(true);
        inputMessage.current.value = '';
      }
    }
  };

  const updateMessageTag = (index, message) => {
    inputMessage.current.value = message;
    setStateMessagesUpdate(false);
    idGUpdateMessage = index;
  };

  const removePhraseTag = (indexRemove) => {
    if (window.confirm('¿Deseas eliminar la frase de entrenamiento?')) {
      setPhraseUpdate(phraseUpdate.filter((_, i) => i !== indexRemove));
      inputPhrase.current.value = '';
      setStatePhraseUpdate(true);
    }
  };

  const updatePhraseTag = (index, phrase) => {
    inputPhrase.current.value = phrase;
    setStatePhraseUpdate(false);
    idGUpdatePhrase = index;
  };

  const addPhraseTag = (event) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      setPhraseUpdate([...phraseUpdate, event.target.value]);
      event.target.value = '';
    }
  };

  const updatePraseTagFun = (event) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      if (window.confirm('¿Deseas modificar la frase de entrenamiento?')) {
        phraseUpdate[idGUpdatePhrase] = inputPhrase.current.value;
        setPhraseUpdateUpdate(phraseUpdate[idGUpdatePhrase]);
        setStatePhraseUpdate(true);
        inputPhrase.current.value = '';
      }
    }
  };

  const body = (
    <div className='caja'>
      <div style={modalStyle} className={classes.paper}>
        <div className={classes.containerTitle}>
          <h2 id='simple-modal-title'>Modificar nodo.</h2>
        </div>
        <div className={classes.containerDark}>
          <h3 id='simple-modal-title'>
            {intent.displayName
              .slice(intent.displayName.indexOf('_') + 1)
              .replaceAll('_', ' ')}
          </h3>
        </div>
        {!intent.fallBackIntent ? (
          <div className={classes.containerClear}>
            {messagesUpdate.map((message, index) => (
              <span key={index}>
                <Chip
                  className={classes.chip}
                  style={{ marginTop: '5px' }}
                  label={message}
                  clickable
                  deleteIcon={<CloseIcon />}
                  color='secondary'
                  variant='outlined'
                  onDelete={() => removeMessageTag(index)}
                  onClick={() => updateMessageTag(index, message)}
                />
              </span>
            ))}
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title={
                  'Solo se permiten letras, números y caracteres como ,.?¿'
                }
                placement='top-start'
              >
                <TextField
                  inputRef={inputMessage}
                  margin='normal'
                  label={
                    stateMessagesUpdate
                      ? 'Insertar mensaje'
                      : 'Modificar mensaje'
                  }
                  onKeyUp={
                    stateMessagesUpdate ? addMessageTag : updateMessageTagFun
                  }
                  fullWidth
                />
              </Tooltip>
            </MuiThemeProvider>
          </div>
        ) : (
          <div className={classes.containerClear}>
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title={
                  'Solo se permiten letras, números y caracteres como ,.?¿'
                }
                placement='top-start'
              >
                <TextField
                  inputRef={inputMessage}
                  onChange={(e) => setMessagesUpdate([e.target.value])}
                  defaultValue={messagesUpdate}
                  label='Modificar mensaje'
                  fullWidth
                />
              </Tooltip>
            </MuiThemeProvider>
          </div>
        )}

        {initialStatePhrases ? (
          <div className={classes.containerDark}>
            {phraseUpdate.map((phrase, index) => (
              <span key={index}>
                <Chip
                  className={classes.chip}
                  style={{ marginTop: '5px' }}
                  label={phrase}
                  clickable
                  deleteIcon={<CloseIcon />}
                  color='secondary'
                  variant='outlined'
                  onDelete={() => removePhraseTag(index)}
                  onClick={() => updatePhraseTag(index, phrase)}
                />
              </span>
            ))}
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title={'Solo se permiten letras, números y el carácter ,'}
                placement='top-start'
              >
                <TextField
                  inputRef={inputPhrase}
                  margin='normal'
                  label={
                    statePhraseUpdate
                      ? 'Insertar frase de entrenamiento'
                      : 'Modificar frase de entrenamiento'
                  }
                  onKeyUp={statePhraseUpdate ? addPhraseTag : updatePraseTagFun}
                  fullWidth
                />
              </Tooltip>
            </MuiThemeProvider>
          </div>
        ) : null}
        <div className={classes.containerClear}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {stateValidation ? (
                <div>
                  {stateValidationInsert ? (
                    <Alert severity='success'>{validationInsert}</Alert>
                  ) : (
                    <Alert severity='error'>{validationInsert}</Alert>
                  )}
                </div>
              ) : null}
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: '20px' }}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box>
                <button
                  className={classes.btnAddIntent}
                  onClick={() => onClose()}
                >
                  <KeyboardBackspaceIcon className={classes.imgBtn} />
                  Volver
                </button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box>
                <div className={classes.containerBtnAdd}>
                  {progreso ? (
                    <CircularProgress color='primary' />
                  ) : (
                    <button
                      className={classes.btnModificar}
                      onClick={(event) => handleSaveIntent(event)}
                    >
                      <img
                        src={IconoModificar}
                        alt='Icono Modificar'
                        className={classes.imgBtn}
                      />
                      Modificar
                    </button>
                  )}
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      {body}
    </Modal>
  );
};
export default ModalUpdateNode;
