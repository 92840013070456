import axios from 'axios';
import {UriConfiguration}  from '../configuration/ApiServices'

let baseURL = UriConfiguration();

export const insertIntent = async (data) => {
    try {
      return await axios
        .post(`${baseURL}/BotTreeDialogFlowControllerNew`, data)
        .then((response) => response.data);
    } catch (error) {
      console.error('Error insert intent service', { error });
    }
  };

  export const deleteIntent = async (id) => {
    try {
      return axios
        .delete(`${baseURL}/BotTreeDialogFlowControllerNew/${id}`)
        .then((response) => response.data);
    } catch {
      console.error('Error delete service IntentService');
    }
  };

  export const updateIntent = async (id, body) => {
    try {
      return await axios
        .put(`${baseURL}/BotTreeDialogFlowControllerNew/${id}`, body)
        .then((response) => response.data);
    } catch (error) {
      console.error('Error delete service IntentService');
    }
  };

  export const findIntentByIdIntent = (itemId) => {
    try {
      return axios
        .get(`${baseURL}/BotTreeDialogFlowControllerNew/findIntentByIdIntent/${itemId}`)
        .then((response) => response.data);
    } catch {
      console.error('Error delete service IntentService');
    }
  };

export const findByDisplayName = async (data) => {
    try {
      return await axios
        .get(`${baseURL}/BotTreeDialogFlowControllerNew/findIntentByDisplayName/${data}`)
        .then((response) => response.data);
    } catch {
      console.error('Error service findByDisplayName');
    }
  };
