import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { List, ListSubheader, ListItem, ListItemText } from '@material-ui/core';
import { findBotDetails } from '../../Service/account/AccountConsumeService';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },

  Bot: {
    color: "#01579b"


  }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const handleClose = (setOpenModal) => {
  setOpenModal(false);
};

export default function CustomizedDialogs({
  openModal,
  setOpenModal,
  conversation,
}) {
  const classes = useStyles();
  const [botName, setBotName] = useState();

  const idAccount = localStorage.getItem('idAccount');

  useEffect(() => {
    const getBotName = async () => {
      let response = await findBotDetails(idAccount)
      setBotName(response.bot_name)
    };
    getBotName();
  }, []);

  return (
    <div>
      <Dialog
        onClose={() => handleClose(setOpenModal)}
        aria-labelledby="customized-dialog-title"
        open={openModal}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose(setOpenModal)}
        >
          Conversación
        </DialogTitle>
        <DialogContent dividers>
          <List className={classes.root} subheader={<li />}>
            {conversation.map((sectionId, key) => (
              <li key={key} className={classes.listSection}>
                <ul className={classes.ul}>
                  <ListSubheader>{sectionId.question}</ListSubheader>
                  {[sectionId.messages.replaceAll("]", "").replaceAll("[", "")].map((item, key2) => (
                    <>
                      <h4 className={classes.Bot}> {botName}: </h4>
                      <ListItem key={key2}>
                        <ListItemText primary={item} />
                      </ListItem>
                    </>
                  ))}
                  {[sectionId.answer].map((item, key2) => (
                    <>
                      <h4>User: </h4>
                      <ListItem key={key2}>
                        <ListItemText primary={item} />
                      </ListItem>
                    </>
                  ))}

                </ul>
              </li>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
}
