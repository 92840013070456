import React, { useState } from 'react';
import Tree from 'react-d3-tree';
import '../../../src/index.css';
import useStyles from '../../commons/TreeModal/styles';
import Modal from '@material-ui/core/Modal';
import {TextField,Box,Grid,Radio,Tooltip,IconButton} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import IconoModificar from '../../assets/img/icono_guardar.png';
import IconoAgregar from '../../assets/img/icono_agregar.png';
import IconoCerrar from '../../assets/img/icono_cerrar.png';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import {updateIntentDatabase,findIntentDatabase,insertIntentDatabase} from '../../Service/tree/TreeDataBaseConsumeService';
import {insertIntent,updateIntent} from '../../Service/tree/TreeDialogFlowConsumeService';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { onNodeClickLogic } from './LogicTree/ClickNode';
import { deleteIntentionLogic } from './LogicTree/DeleteIntent';
import { updateIntentModal } from './LogicTree/UpdateIntens';
import { fieldValidationLogic } from './fieldValidation';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { informationUserDatabase } from '../../Service/platformUser/PlatformUserConsumeService';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        color: 'white',
        backgroundColor: '#e55959',
      },
    },
  },
});
const containerStyles = {
  width: '100%',
  height: '100vh',
};
const getModalStyle = () => {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
};

let inputMessage = React.createRef();
let inputPhrase = React.createRef();
let inputTitle = React.createRef();
let idGUpdateMessage = 0;
let idGUpdatePhrase = 0;
let idIntent='';
let idDialog='';

const TreeIntents = (props) => {
  const [linearProgress, setLinearProgress] = useState(false);
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [stateDelete, setStateDelete] = useState(true);
  const [displayName, setDisplayName] = useState(' ');
  const [node, setNode] = useState({});
  const [displayNameUpdate, setDisplayNameUpdate] = useState('');
  const [accountant, setAccountant] = useState(0);

  const [messages, setMessage] = useState([]);
  const [stateMessage, setStateMessage] = useState(true);
  const [, messageUpate] = useState('');
  const [messagesUpdate, setMessagesUpdate] = useState([]);
  const [stateMessagesUpdate, setStateMessagesUpdate] = useState(true);
  const [, setMessagesUpdateUpdate] = useState('');

  //frases de entrenamiento
  const [trainingPhrasesParts, setTrainingPhrasesParts] = useState([]);
  const [stateTrainingPhrasesParts, setStateTrainingPhrasesParts] = useState(true);
  const [, setTrainingPhrasesPartsUpdate] = useState('');

  const [phraseUpdate, setPhraseUpdate] = useState([]);
  const [statePhraseUpdate, setStatePhraseUpdate] = useState(true);
  const [, setPhraseUpdateUpdate] = useState('');
  const [, setContextInUpdate] = useState([]);
  const [contextOutUpdate, setContextOutUpdate] = useState([]);
  const [validationInsert, setValidationInsert] = useState('');
  const [stateValidationInsert, setStateValidationInsert] = useState(false);
  const [stateValidation, setStateValidation] = useState(false);

  const [clickNodeId, setClickNodeId] = useState('');
  const [idNode, setIdNode] = useState('');
  const [nodeDataChild, setNodeDataChild] = useState();
  const [accountant2, setAccountant2] = useState(0);
  const [botonState, setBotonState] = useState(false);
  const [initialStatePhrases, setInitialStatePrhases] = useState(false);
  const [fallBackIntent, setFallBackIntent] = useState(false);
  const [progresoDelete, setProgresoDelete] = useState(false);
  const [selectedValue, setSelectedValue] = useState('basic');
  const [typeIntent, setTypeIntent] = useState('');
  const [previousIntentType, setPreviousIntentType] = useState('');

  const setStates = () => {
    setDisplayName('');
    setMessage([]);
    setStateMessage(true);
    messageUpate('');
    setTrainingPhrasesParts([]);
    setStateTrainingPhrasesParts(true);
    setTrainingPhrasesPartsUpdate('');
  };

  const onNodeClick = (nodeData) => {
    onNodeClickLogic(
      nodeData,
      setLinearProgress,
      setActiveButton,
      setNodeDataChild,
      setClickNodeId,
      setAccountant,
      setNode,
      setDisplayNameUpdate,
      setIdNode,
      setMessagesUpdate,
      setPhraseUpdate,
      setContextInUpdate,
      setContextOutUpdate,
      setOpen,
      setBotonState,
      setInitialStatePrhases,
      setFallBackIntent,
      setTypeIntent,
      setPreviousIntentType
    );
    setDisplayNameUpdate(nodeData.title);
    setActiveButton(setActiveButton);
    setStateValidation(false);
    setNode(setNode);
  };

  const deleteIntention = () => {
    deleteIntentionLogic(
      clickNodeId.id,
      setStateDelete,
      setStateValidationInsert,
      setValidationInsert,
      props.state,
      setOpen,
      setAccountant,
      setProgresoDelete
    );
  };

  const childNodes = () => {
    for (let i = 0; i <= nodeDataChild.length; i++) {
      if (
        nodeDataChild[i] &&
        parseInt(nodeDataChild[i].title.slice(-1)) === i + 1
      ) {
        console.log('ya existe');
      } else {
        setAccountant2(i + 1);
        return;
      }
    }
  };

  const handleOpen2 = () => {
    setValidationInsert('');
    setAccountant(accountant + 1);
    setOpen(true);
    childNodes();
  };
  const handleClose = () => {
    setAccountant(0);
    setOpen(false);
    setStates();
    setValidationInsert('');
    setStateValidationInsert(false);
    setStateValidation(false);
    setStateDelete(false);
  };
  const backed = () => {
    setStateValidation(false);
    setAccountant(1);
    setOpen(true);
    setStates();
  };
  const updateIntentJson = {
    displayName: displayNameUpdate,
    messages: messagesUpdate,
    trainingPhrasesParts: phraseUpdate,
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const getName = () => {
    let result = node.name.charAt(node.name.length - 2);
    let abecedario = 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
    let result2 = abecedario.indexOf(result);
    let result3 = abecedario.charAt(result2 + 1);
    console.log('name: ' + node.name + result3 + accountant2);
    return node.name + result3 + accountant2;
  };

  const removeMessageTag = (indexRemove) => {
    if (accountant === 1) {
      if (window.confirm('¿Deseas eliminar el mensaje?')) {
        setMessagesUpdate(messagesUpdate.filter((_, i) => i !== indexRemove));
        inputMessage.current.value = '';
        setStateMessagesUpdate(true);
      }
    } else {
      if (window.confirm('¿Deseas eliminar el mensaje?')) {
        setMessage(messages.filter((_, i) => i !== indexRemove));
        inputMessage.current.value = '';
        setStateMessage(true);
      }
    }
  };

  const addMessageTag = (event) => {
    if (accountant === 1) {
      if (event.key === 'Enter' && event.target.value !== '') {
        setMessagesUpdate([...messagesUpdate, event.target.value]);
        event.target.value = '';
      }
    } else {
      if (event.key === 'Enter' && event.target.value !== '') {
        setMessage([...messages, event.target.value]);
        event.target.value = '';
      }
    }
  };

  const updateMessageTagFun = (event2) => {
    if (accountant === 1) {
      if (event2.key === 'Enter' && event2.target.value !== '') {
        if (window.confirm('¿Deseas modificar el mensaje?')) {
          setMessagesUpdateUpdate(
            (messagesUpdate[idGUpdateMessage] = inputMessage.current.value)
          );
          setStateMessagesUpdate(true);
          inputMessage.current.value = '';
        }
      }
    } else {
      if (event2.key === 'Enter' && event2.target.value !== '') {
        if (window.confirm('¿Deseas modificar el mensaje?')) {
          messageUpate(
            (messages[idGUpdateMessage] = inputMessage.current.value)
          );
          setStateMessage(true);
          inputMessage.current.value = '';
        }
      }
    }
  };

  const updateMessageTag = (index, message) => {
    if (accountant === 1) {
      inputMessage.current.value = message;
      setStateMessagesUpdate(false);
      idGUpdateMessage = index;
    } else {
      inputMessage.current.value = message;
      setStateMessage(false);
      idGUpdateMessage = index;
    }
  };

  const removePhraseTag = (indexRemove) => {
    if (accountant === 1) {
      if (window.confirm('¿Deseas eliminar la frase de entrenamiento?')) {
        setPhraseUpdate(phraseUpdate.filter((_, i) => i !== indexRemove));
        inputPhrase.current.value = '';
        setStatePhraseUpdate(true);
      }
    } else {
      if (window.confirm('¿Deseas eliminar la frase de entrenamiento?')) {
        setTrainingPhrasesParts(
          trainingPhrasesParts.filter((_, i) => i !== indexRemove)
        );
        inputPhrase.current.value = '';
        setStateTrainingPhrasesParts(true);
      }
    }
  };

  const updatePhraseTag = (index, phrase) => {
    if (accountant === 1) {
      inputPhrase.current.value = phrase;
      setStatePhraseUpdate(false);
      idGUpdatePhrase = index;
    } else {
      inputPhrase.current.value = phrase;
      setStateTrainingPhrasesParts(false);
      idGUpdatePhrase = index;
    }
  };

  const addPhraseTag = (event) => {
    if (accountant === 1) {
      if (event.key === 'Enter' && event.target.value !== '') {
        setPhraseUpdate([...phraseUpdate, event.target.value]);
        event.target.value = '';
      }
    } else {
      if (event.key === 'Enter' && event.target.value !== '') {
        setTrainingPhrasesParts([...trainingPhrasesParts, event.target.value]);
        event.target.value = '';
      }
    }
  };

  const updatePraseTagFun = (event) => {
    if (accountant === 1) {
      if (event.key === 'Enter' && event.target.value !== '') {
        if (window.confirm('¿Deseas modificar la frase de entrenamiento?')) {
          setPhraseUpdateUpdate(
            (phraseUpdate[idGUpdatePhrase] = inputPhrase.current.value)
          );
          setStatePhraseUpdate(true);
          inputPhrase.current.value = '';
        }
      }
    } else {
      if (event.key === 'Enter' && event.target.value !== '') {
        if (window.confirm('¿Deseas modificar la frase de entrenamiento?')) {
          setTrainingPhrasesPartsUpdate(
            (trainingPhrasesParts[idGUpdatePhrase] = inputPhrase.current.value)
          );
          setStateTrainingPhrasesParts(true);
          inputPhrase.current.value = '';
        }
      }
    }
  };

  const fieldValidation = () => {
    return fieldValidationLogic(
      setProgreso,
      setValidationInsert,
      inputTitle.current.value,
      setStateValidationInsert,
      messages,
      trainingPhrasesParts,
      initialStatePhrases,
      typeIntent,
      previousIntentType,
      'save'
    );
  };

  const saveIntent = async () => {
    setStateValidation(true);
    let responseLeafNode;
    let validation = fieldValidation();

    const emailDB = await informationUserDatabase(localStorage.getItem('idCognito'));
    const userEmail = emailDB.email.split("@")

    console.log('previous' + previousIntentType);
    if (validation !== false) {
      setStateValidation(false);
      if (selectedValue === 'dtmf' ||selectedValue === 'basic' ||selectedValue === 'soap') {
        console.log('tipo de nodo  anterior ' + typeIntent);
        responseLeafNode = await insertIntent({
          id: idIntent,
          dialogFlowName:  userEmail[0]+"-"+idDialog,
          messages: messages,
          trainingPhrasesParts: trainingPhrasesParts,
          contextIn: contextOutUpdate,
          contextOut: [idDialog],
          endInteraction: true,
          fallBackIntent: false,
        });
      }

      let level = calculateLevel();
      if (responseLeafNode !== undefined) {
        console.log(responseLeafNode);
        await insertIntentDatabase({
          json_intent: JSON.stringify({
            id: responseLeafNode.id,
            displayName: displayName,
            dialogFlowName: responseLeafNode.dialogFlowName,
            messages: messages,
            trainingPhrasesParts: trainingPhrasesParts,
            contextIn: contextOutUpdate,
            contextOut: [responseLeafNode.dialogFlowName],
            endInteraction: true,
            fallBackIntent: false,
            children: 0,
          }),
          id_intent: responseLeafNode.id,
          id_account: localStorage.getItem('idAccount'),
          messages: JSON.stringify(messages),
          id_type:selectedValue === 'basic'? 1: selectedValue === 'dtmf'? 3: selectedValue === 'soap'? 4 :undefined,
          level: level,
          state: 'active',
          id_previous_intent: idNode,
        });
        updChildrenPreviousNode(idNode);
        setProgreso(false);
        setStateValidationInsert(true);
        setStates();
        inputTitle.current.value = '';
        setOpen(false);
        props.state(new Date().valueOf());
      } else {
        setValidationInsert('Ocurrió un error con el servidor!!!');
        setProgreso(false);
        setStateValidation(true);
        setStateValidationInsert(false);
      }
    }
  };

  const updChildrenPreviousNode = async (id) => {
    const findIntent = await findIntentDatabase(id);
    let jsonObject = JSON.parse(JSON.stringify(findIntent.json_intent));
    let jsonParse = JSON.parse(jsonObject);

    const updatePreviousNode = {
      id: jsonParse.id,
      displayName: jsonParse.displayName,
      dialogFlowName: jsonParse.dialogFlowName,
      messages: jsonParse.messages,
      trainingPhrasesParts: jsonParse.trainingPhrasesParts,
      contextIn: jsonParse.contextIn,
      contextOut: [jsonParse.dialogFlowName],
      endInteraction: false,
      children: nodeDataChild.length + 1,
      fallBackIntent: jsonParse.fallBackIntent,
    };

    const updatePreviousNodeBotTreeDataBase = {
      json_intent: JSON.stringify({
        id: jsonParse.id,
        displayName: jsonParse.displayName,
        dialogFlowName: jsonParse.dialogFlowName,
        messages: jsonParse.messages,
        trainingPhrasesParts: jsonParse.trainingPhrasesParts,
        contextIn: jsonParse.contextIn,
        contextOut: [jsonParse.dialogFlowName],
        endInteraction: false,
        children: nodeDataChild.length + 1,
        fallBackIntent: jsonParse.fallBackIntent,
      }),
      id_intent: jsonParse.id,
      id_account: localStorage.getItem('idAccount'),
      messages: JSON.stringify(jsonParse.messages),
      fall_back_intent: jsonParse.fallBackIntent === false ? 0 : 1,
      children: nodeDataChild.length + 1,
      id_type: findIntent.id_type,
      level: findIntent.level,
      state: findIntent.state,
      id_previous_intent: findIntent.id_previous_intent,
    };
    await updateIntent(jsonParse.id, updatePreviousNode);
    await updateIntentDatabase(jsonParse.id, updatePreviousNodeBotTreeDataBase);
  };

  const updateIntentView = async () => {
    if (!fallBackIntent) {
      await updateIntentModal(
        setStateDelete,
        setValidationInsert,
        setProgreso,
        setStateValidationInsert,
        updateIntent,
        inputTitle.current.value,
        node,
        updateIntentJson,
        setDisplayNameUpdate,
        nodeDataChild,
        messagesUpdate,
        phraseUpdate,
        setStateValidation,
        initialStatePhrases,
        typeIntent,
        previousIntentType
      );
    } else {
      setStateDelete(true);
      setStateValidation(false);
      let validation = fieldValidationLogic(
        setProgreso,
        setValidationInsert,
        '',
        setStateValidationInsert,
        messagesUpdate,
        '',
        initialStatePhrases,
        typeIntent,
        '',
        'update'
      );
      if (validation !== false) {
        const findFallBackIntent = await findIntentDatabase(idNode);
        let jsonObject = JSON.parse(
          JSON.stringify(findFallBackIntent.json_intent)
        );
        let fallback = JSON.parse(jsonObject);

        const updateFallBackIntent = {
          id: idNode,
          displayName: fallback.displayName,
          dialogFlowName: fallback.dialogFlowName,
          messages: messagesUpdate,
          trainingPhrasesParts: fallback.trainingPhrasesParts,
          contextIn: fallback.contextIn,
          contextOut: fallback.contextOut,
          endInteraction: fallback.endInteraction,
          fallBackIntent: fallback.fallBackIntent,
          children: fallback.children,
        };

        const updateFallBackIntentBotTreeDataBase = {
          json_intent: JSON.stringify({
            id: idNode,
            displayName: fallback.displayName,
            dialogFlowName: fallback.dialogFlowName,
            messages: JSON.stringify(messagesUpdate),
            trainingPhrasesParts: fallback.trainingPhrasesParts,
            contextIn: fallback.contextIn,
            contextOut: fallback.contextOut,
            endInteraction: fallback.endInteraction,
            fallBackIntent: fallback.fallBackIntent,
            children: fallback.children,
          }),
          id_intent: idNode,
          id_account: localStorage.getItem('idAccount'),
          messages: JSON.stringify(messagesUpdate),
          fall_back_intent: fallback.fallBackIntent === false ? 0 : 1,
          children: fallback.children,
          id_type: findFallBackIntent.id_type,
          level: findFallBackIntent.level,
          state: findFallBackIntent.state,
          id_previous_intent: findFallBackIntent.id_previous_intent,
        };

        const update = await updateIntent(idNode, updateFallBackIntent);

        if (update === undefined) {
          setStateValidationInsert(false);
          setStateValidation(true);
          setValidationInsert('Error al actualizar el nodo');
          setProgreso(false);
        } else {
          setStateValidationInsert(true);
          await updateIntentDatabase(idNode,updateFallBackIntentBotTreeDataBase);
          setStateValidation(true);
          setValidationInsert('Nodo actualizada correctamente');
          setProgreso(false);
        }
      }
      setStateValidation(true);
    }
  };

  const calculateLevel = () => {
    return parseInt(displayName.indexOf('_') / 2);
  };

  const [progreso, setProgreso] = useState(false);
  const [activeButton, setActiveButton] = useState(false);

  const bodyAdd = (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.containerTitle}>
        <h2 id='simple-modal-title'>Insertar nodo.</h2>
      </div>
      <h4 className={classes.titleIntentType}>Tipo de nodo</h4>
      <div className={classes.radioIntentType}>
        <Radio
          checked={selectedValue === 'basic'}
          onChange={handleChange}
          value='basic'
          name='radio-button-demo'
          label='Basico'
          inputProps={{ 'aria-label': 'BASIC' }}
        />
        <label for='1'>Básico</label>
        <Radio
          checked={selectedValue === 'dtmf'}
          onChange={handleChange}
          value='dtmf'
          name='radio-button-demo'
          inputProps={{ 'aria-label': 'DTMF' }}
        />
        <label for='1'>DTMF</label>
        <Radio
          checked={selectedValue === 'soap'}
          onChange={handleChange}
          value='soap'
          name='radio-button-demo'
          inputProps={{ 'aria-label': 'SOAP' }}
        />
        <label for='1'>SOAP</label>
      </div>
      <div className={classes.containerClear}>
        <MuiThemeProvider theme={theme}>
          <Tooltip
            title={'Solo se permiten letras y números'}
            placement='top-start'
          >
            <TextField
              inputRef={inputTitle}
              onChange={(e) => setDisplayName(getName() + '_' + e.target.value)}
              margin='normal'
              label='Nombre del nodo'
              fullWidth
            />
          </Tooltip>
        </MuiThemeProvider>
      </div>
      <div className={classes.containerDark}>
        {messages.map((message, index) => (
          <span key={index}>
            <Chip
              className={classes.chip}
              style={{ marginTop: '5px' }}
              label={message}
              clickable
              deleteIcon={<CloseIcon />}
              color='secondary'
              variant='outlined'
              onDelete={() => removeMessageTag(index)}
              onClick={() => updateMessageTag(index, message)}
            />
          </span>
        ))}
        <MuiThemeProvider theme={theme}>
          <Tooltip
            title={
              selectedValue === 'dtmf'? 'Como vas a crear un nodo DTMF debes incluir en tu mensaje que se debe oprimir la tecla numeral despues de la interacción'
              : 'Solo se permiten letras, números y caracteres como ,.?¿' }
            placement='top-start'>
            <TextField
              inputRef={inputMessage}
              margin='normal'
              label={stateMessage ? 'Insertar mensaje' : 'Modificar mensaje'}
              onKeyUp={stateMessage ? addMessageTag : updateMessageTagFun}
              fullWidth
            />
          </Tooltip>
        </MuiThemeProvider>
      </div>
      <div className={classes.containerClear}>
        {trainingPhrasesParts.map((phrase, index) => (
          <span key={index}>
            <Chip
              className={classes.chip}
              style={{ marginTop: '5px' }}
              label={phrase}
              clickable
              deleteIcon={<CloseIcon />}
              color='secondary'
              variant='outlined'
              onDelete={() => removePhraseTag(index)}
              onClick={() => updatePhraseTag(index, phrase)}
            />
          </span>
        ))}
        <MuiThemeProvider theme={theme}>
          <Tooltip
            title={
              typeIntent === '3'
                ? 'Como el nodo anterior es DTMF aquí deberían ir campos numéricos por ejemplo: 12345 o 1 2 3 4 5'
                : 'Solo se permiten letras, números y el carácter ,'}
            placement='top-start'>
            <TextField
              inputRef={inputPhrase}
              margin='normal'
              label={stateTrainingPhrasesParts? 'Insertar frase de entrenamiento': 'Modificar frase de entrenamiento'}
              onKeyUp={stateTrainingPhrasesParts ? addPhraseTag : updatePraseTagFun}
              fullWidth/>
          </Tooltip>
        </MuiThemeProvider>
      </div>
      <div className={classes.containerClear}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {stateValidation ? (
              <div>
                {stateValidationInsert ? null : (
                  <Alert severity='error'>{validationInsert}</Alert>
                )}
              </div>
            ) : null}
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Box>
              <button className={classes.btnAddIntent} onClick={backed}>
                <KeyboardBackspaceIcon className={classes.imgBtn} />
                Volver
              </button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Box>
              <div className={classes.containerBtnAdd}>
                {progreso ? (
                  <CircularProgress color='primary' />
                ) : (
                  <button className={classes.btnAddIntent} onClick={saveIntent}>
                    <SaveIcon className={classes.imgBtn} />
                    Agregar nodo
                  </button>
                )}
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  const bodyUpdate = (
    <div className='caja'>
      <div style={modalStyle} className={classes.paper}>
        <div className={classes.containerTitle}>
          <Grid container style={{ marginTop: '5px' }}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
             
              <h2 id='simple-modal-title'>Modificar nodo.</h2>
           
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box>
                <div
                  id='simple-modal-description'
                  className={classes.container}
                >
                  <IconButton variant='contained' onClick={handleClose}>
                    <img src={IconoCerrar} alt='Icono Cerrar' />
                  </IconButton>
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div className={classes.containerClear}>
          {activeButton ? (
            <div id='simple-modal-description' className={classes.container}>
              {progresoDelete ? (
                <CircularProgress color='primary' />
              ) : (
                <button
                  className={classes.btnEliminar}
                  onClick={deleteIntention}
                >
                  <DeleteIcon className={classes.imgBtn} />
                  Eliminar nodo
                </button>
              )}
            </div>
          ) : null}
          {/* <h3 id="simple-modal-title">{displayNameUpdate}</h3>
        <p id="simple-modal-description" className={classes.container}>
        </p> */}
        </div>
        {!fallBackIntent ? (
          <div className={classes.containerDark}>
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title={'Solo se permiten letras y números'}
                placement='top-start'
              >
                <TextField
                  inputRef={inputTitle}
                  onChange={(e) =>setDisplayNameUpdate(node.name + '_' + e.target.value)}
                  defaultValue={displayNameUpdate.slice(displayNameUpdate.indexOf('_') + 1).replaceAll('_', ' ')}
                  margin='normal'
                  label='Nombre del nodo'
                  fullWidth
                />
              </Tooltip>
            </MuiThemeProvider>
          </div>
        ) : (
          <div className={classes.containerClear}>
            Este mensaje responderá el bot cuando no entiende o no escucha
            respuesta del usuario.
          </div>
        )}
        {!fallBackIntent ? (
          <div className={classes.containerClear}>
            {messagesUpdate.map((message, index) => (
              <span key={index}>
                <Chip
                  className={classes.chip}
                  style={{ marginTop: '5px' }}
                  label={message.replaceAll('[', '').replaceAll(']', '')}
                  clickable
                  deleteIcon={<CloseIcon />}
                  color='secondary'
                  variant='outlined'
                  onDelete={() => removeMessageTag(index)}
                  onClick={() =>updateMessageTag(index,message.replaceAll('[', '').replaceAll(']', ''))}
                />
              </span>
            ))}
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title={
                  typeIntent === '3'
                    ? 'Solo se permiten letras, números y caracteres como ,.?¿ y recuerda incluir en tu mensaje oprimir la tecla numeral'
                    : 'Solo se permiten letras, números y caracteres como ,.?¿'
                }
                placement='top-start'
              >
                <TextField
                  inputRef={inputMessage}
                  margin='normal'
                  label={
                    stateMessagesUpdate
                      ? 'Insertar mensaje'
                      : 'Modificar mensaje'
                  }
                  onKeyUp={
                    stateMessagesUpdate ? addMessageTag : updateMessageTagFun
                  }
                  fullWidth
                />
              </Tooltip>
            </MuiThemeProvider>
          </div>
        ) : (
          <div className={classes.containerClear}>
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title={
                  'Solo se permiten letras, números y caracteres como ,.?¿'
                }
                placement='top-start'
              >
                <TextField
                  inputRef={inputMessage}
                  onChange={(e) => setMessagesUpdate([e.target.value])}
                  defaultValue={
                    messagesUpdate[0] === undefined || ''
                      ? ''
                      : messagesUpdate[0]
                          .replaceAll('[', '')
                          .replaceAll(']', '')
                  }
                  label='Modificar mensaje'
                  fullWidth
                />
              </Tooltip>
            </MuiThemeProvider>
          </div>
        )}
        {initialStatePhrases ? (
          <div className={classes.containerDark}>
            {phraseUpdate.map((phrase, index) => (
              <span key={index}>
                <Chip
                  className={classes.chip}
                  style={{ marginTop: '5px' }}
                  label={phrase}
                  clickable
                  deleteIcon={<CloseIcon />}
                  color='secondary'
                  variant='outlined'
                  onDelete={() => removePhraseTag(index)}
                  onClick={() => updatePhraseTag(index, phrase)}
                />
              </span>
            ))}
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title={
                  'Si el anterior es DTMF solo se permiten números por ejemplo: 12345 o 1 2 3 4 5 sino solo se permiten letras, números y caracteres como ,'
                }
                placement='top-start'
              >
                <TextField
                  inputRef={inputPhrase}
                  margin='normal'
                  label={
                    statePhraseUpdate
                      ? 'Insertar frase de entrenamiento'
                      : 'Modificar frase de entrenamiento'
                  }
                  onKeyUp={statePhraseUpdate ? addPhraseTag : updatePraseTagFun}
                  fullWidth
                />
              </Tooltip>
            </MuiThemeProvider>
          </div>
        ) : null}
        <div className={classes.containerClear}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {stateValidation ? (
                <div>
                  {stateValidationInsert ? (
                    <Alert severity='success'>{validationInsert}</Alert>
                  ) : (
                    <Alert severity='error'>{validationInsert}</Alert>
                  )}
                  {stateDelete ? null : (
                    <Alert severity='error'>
                      Error al eliminar el nodo
                    </Alert>
                  )}
                </div>
              ) : null}
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: '20px' }}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box>
                {progreso ? (
                  <CircularProgress color='primary' />
                ) : (
                  <button
                    className={classes.btnModificar}
                    onClick={updateIntentView}
                  >
                    <img
                      src={IconoModificar}
                      alt='Icono Modificar'
                      className={classes.imgBtn}
                    />
                    Modificar
                  </button>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box>
                <div className={classes.containerBtnAdd}>
                  {!botonState ? (
                    <button
                      className={classes.btnAdd}
                      disabled={botonState}
                      onClick={handleOpen2}
                    >
                      <img
                        src={IconoAgregar}
                        alt='Icono Agregar'
                        className={classes.imgBtn}
                      />
                      Agregar nodo
                    </button>
                  ) : null}
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );

  //   const type = async(id) =>{
  //     let findIntent = await findIntentDatabase(id)
  //     console.log( findIntent.id_type)
  //    return findIntent.id_type;
  //   }
  //   const color =  (id) =>{
  //     let res = type(id.id)
  //  //console.log(res)
  //   if(res ==="1"){
  //     return "blue"
  //   }else if(res==="2"){
  //     return "yellow"
  //   }
  //   }

  const renderNodeWithCustomEvents = ({nodeDatum,toggleNode}) => (
    <g>
      <circle
        r='15'
        fill={nodeDatum.title === 'A1' ? '#e55959' : '#1b3c5a'}
        onClick={() => onNodeClick(nodeDatum)}
      />
      <text fill='gray' strokeWidth='0' x='-25' y='-25' onClick={toggleNode}>
        {nodeDatum.name}👆🏻
      </text>
    </g>
  );
  return (
    <div style={containerStyles}>
      {linearProgress ? <LinearProgress color='secondary' /> : null}
      <div>
        <Tree
          data={props.data[0]}
          orientation={'horizontal'}
          rootNodeClassName='node__fallback'
          collapsible={false}
          directed={false}
          zoomable={false}
          translate={{
            x: 70,
            y: 70,
          }}
          onNodeClick={onNodeClick}
        />
      </div>
      <Tree
        data={props.data[1]}
        orientation={'horizontal'}
        rootNodeClassName='node__root'
        branchNodeClassName='node__branch'
        //collapsible={onNodeClick === true ? false : true}
        directed={false}
        depthFactor={280}
        renderCustomNodeElement={(rd3tProps) =>
          renderNodeWithCustomEvents({ ...rd3tProps, onNodeClick })
        }
        translate={{
          x: 70,
          y: 300,
        }}
        onNodeClick={onNodeClick}
      />
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          {accountant === 1 ? bodyUpdate : bodyAdd}
        </Modal>
      </div>
    </div>
  );
};
export default TreeIntents;