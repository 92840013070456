import React, { useState, useEffect } from 'react';
import Drawer from '../drawer/index';
import { Link, useHistory } from 'react-router-dom';

import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Menu from '../../commons/Menu';
import useStyles from './styles';
import {
  ACCOUNT_ROUTE,
  USER_ROUTE,
  HOME_ADMIN_ROUTE,
  HOME_ROUTE,
} from '../../constants/Routes/constantsRoutes';
import LogoTelliouAppBar from '../../assets/img/logo_telliou.png';
import UsuarioTelliou from '../../assets/img/usuario.png';
const closeSession = () => {
  Auth.signOut()
    .then(() => {
      localStorage.clear();
      window.location = '/';
    })
    .catch((err) => err);
};

const currentSession = ({ setEmail }) => {
  Auth.currentSession()
    .then((data) => {
      setEmail(data.getIdToken().decodePayload().email);
    })
    .catch(() => {
      console.log('error');
    });
};

const handleClick = (e, setAnchorEl) => {
  setAnchorEl(e.currentTarget);
  console.log(e.currentTarget);
};

const handleClose = (setAnchorEl) => {
  setAnchorEl(null);
};

const AppBarAssistant = () => {
  const idAccount = localStorage.getItem('idAccount');
  const adminId = 'e2651393-36d1-41d0-93c6-f08112613c52';
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let history = useHistory();

  useEffect(() => {
    currentSession({ setEmail });
  }, [history]);

  return (
    <AppBar position='static' style={{backgroundColor: '#1b3c5a'}}>
      <Toolbar>
        <Drawer />
        {idAccount === adminId ? (
          <>
            <Link to={HOME_ADMIN_ROUTE} className={classes.title}>
              <img src={LogoTelliouAppBar} alt='Telliou Logo AppBar' />
            </Link>
          </>
        ) : (
          <>
            <Link to={HOME_ROUTE} className={classes.title}>
              <img src={LogoTelliouAppBar} alt='Telliou Logo AppBar' />
            </Link>
          </>
        )}
        <span className={classes.user}>{email}</span>
        <IconButton
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          onClick={(e) => handleClick(e, setAnchorEl)}
          className={classes.close}
        >
          {/* <Person /> */}
          <img src={UsuarioTelliou} alt='Usuario Telliou' />
        </IconButton>
      </Toolbar>
      <Menu
        open={open}
        onClose={() => handleClose(setAnchorEl)}
        anchorEl={anchorEl}
      >
        <Link to={ACCOUNT_ROUTE} key='Configuracion' className={classes.menu}>
          <MenuItem className={classes.menuItem}>Cuenta</MenuItem>
        </Link>
        <Link to={USER_ROUTE} key='user' className={classes.menu}>
          <MenuItem oclassName={classes.menuItem}>Agregar usuario</MenuItem>
        </Link>
        <MenuItem
          onClick={() => {
            handleClose(setAnchorEl);
            closeSession();
          }}
        >
          <ExitToApp style={{ paddingRight: 6 }} />
          Cerrar sesión
        </MenuItem>
      </Menu>
    </AppBar>
  );
};

export default AppBarAssistant;
