import React from 'react';
import useStyles from './styles';
import fotoCreacion from '../../../../assets/img/foto_creacion.jpg';
import fotoMetricas from '../../../../assets/img/foto_metricas.jpg';
import fotoEconomico from '../../../../assets/img/foto_economico.jpg';
import TextAndButton from '../textAndButton';

function Information() {
  const style = useStyles();
  let titleCreation = 'Creación y entrenamiento intuitivo.';
  let textInfoCreation =
    'En pocos pasos, crea tu asistente, entrénalo y comienza la interacción con tus usuarios.';
  let buttonCreation = 'VER MÁS...';
  let titleMetrics = 'Analiza tus Métricas.';
  let infoMetrics =
    'Estudia las dinámicas de tu asistente, y enriquece sus interacciones con tus usuarios.';
  let buttonMetrics = 'VER MÁS...';
  let titleEconomic = 'Económico.';
  let infoEconomic =
    'Explora nuestros modelos por suscripción. Paga sólo por las interacciones que tu asistente tenga con tus usuarios.';
  let buttonEconomic = 'VER MÁS...';

  return (
    <>
      <div className={style.container}>
        <img src={fotoCreacion} alt="Creation Logo" className={style.imagenCreacion} />
        <div className={style.containerTextInfo}>
          <TextAndButton
            titleCreation={titleCreation}
            textInfoCreation={textInfoCreation}
            buttonCreation={buttonCreation}
          />
        </div>
      </div>
      <div className={style.container}>
        <div className={style.containerTextInfo}>
          <TextAndButton
            titleMetrics={titleMetrics}
            infoMetrics={infoMetrics}
            buttonMetrics={buttonMetrics}
          />
        </div>
        <img src={fotoMetricas} alt="Metricas Logo" className={style.imagenCreacion} />
      </div>

      <div className={style.container}>
        <img src={fotoEconomico} alt="Economic Logo" className={style.imagenCreacion} />
        <div className={style.containerTextInfo}>
          <TextAndButton
            titleEconomic={titleEconomic}
            infoEconomic={infoEconomic}
            buttonEconomic={buttonEconomic}
          />
        </div>
      </div>
    </>
  );
}

export default Information;
