import React from 'react';
import { Link } from 'react-router-dom';
import ButtonLoading from '../../../commons/buttonLoading/ButtonLoading';
import InputPasswordNew from '../../../commons/InputPasswordNew';
import { Typography } from '@material-ui/core';
import LogoTipoTelliou from '../../../assets/img/logotipo_telliou.png';
const FormRegister = ({
  handleSubmit,
  setPassword,
  errorPassword,
  setEmail,
  errorEmail,
  setPhoneNumber,
  errorPhoneNumber,
  error,
  loading,
  classes,
  seePassword,
  setSeePassword,
}) => (
  <form
    className={classes.container}
    onSubmit={handleSubmit}
    autoComplete='off'
  >
    <div className={classes.containerRegister}>
      <div className={classes.register}>
        <div className={classes.registerTitle}>
          <img
            src={LogoTipoTelliou}
            alt='Logo Telliou'
            className={classes.imgTelliou}
          />
          <h3>Crea tu cuenta</h3>
        </div>

        {/* <div className={styles.inputRegister}> */}
        <input
          className={classes.inputRegister}
          type='email'
          name='correo'
          id=''
          placeholder='Correo electrónico*'
          onChange={(e) => setEmail(e.target.value)}
          // helperText={errorUsername}
          // error={Boolean(errorUsername)}
        />

        <InputPasswordNew
          // helperText={errorPassword}
          visibility={seePassword}
          onChange={(e) => setPassword(e.target.value)}
          onClick={() => setSeePassword(!seePassword)}
          // label='Contraseña *'
          // error={Boolean(errorPassword)}
        />

        <input
          className={classes.inputRegister}
          type='number'
          name='telefono'
          id=''
          placeholder='Numero de telefono*'
          onChange={(e) => setPhoneNumber(e.target.value)}
          // helperText={errorUsername}
          // error={Boolean(errorUsername)}
        />

        <Typography className='error__register' variant='subtitle1'>
          {error}
        </Typography>
        {/* </div> */}

        <div className={classes.container_button}>
          <ButtonLoading label='Registrarse' loading={loading} />
        </div>

        <div className={classes.checkAccount}>
          <h3>¿Ya tienes una cuenta?</h3>
          <Link to='/login' className={classes.sessionAccountLink}>
            inicia sesión aquí
          </Link>
        </div>
      </div>
    </div>
  </form>
);

export default FormRegister;
