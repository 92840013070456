import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles({
  information: {
    marginTop: '70px',
    marginBottom: '70px',
  },
  footer: {
    width: '100%',
    height: '90px',
    backgroundColor: '#1b3c5a',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
});

export default useStyle;
