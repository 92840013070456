import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    // marginTop: '40px',
    display: 'flex',
    // padding: '0',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  imagenCreacion: {
    width: '600px',
    height: '300px',
  },
  containerTextInfo: {
    width: '600px',
    height: '300px',
    backgroundColor: '#e9e9e9',
  },
  containerTextInfoFirst: {
    width: '600px',
    height: '300px',
    backgroundColor: '#e9e9e9',
    // margin: '0px 55px',
  }
});

export default useStyles;
