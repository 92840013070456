import axios from 'axios';
import {UriConfiguration}  from '../configuration/ApiServices'

let baseURL = UriConfiguration();

export const insertIntentDatabase = async (data) => {
    try {
      return await axios
        .post(`${baseURL}/BotTreeControllerNew`, data)
        .then((response) => response.data);
    } catch (error) {
      console.error('Error insert intent database service', { error });
    }
  };

  export const deleteIntentDatabase = async (idIntent) => {
    try {
      return await axios
        .delete(`${baseURL}/BotTreeControllerNew/${idIntent}`)
        .then((response) => response.data);
    } catch {
      console.error('Error service IntentService');
    }
  };

  export const deleteByIdIntentDatabase = async (idIntent) => {
    try {
      return await axios
        .put(`${baseURL}/BotTreeControllerNew/deleteBotTreeByIdIntent/${idIntent}`)
        .then((response) => response.data);
    } catch {
      console.error('Error service IntentService');
    }
  };

  export const updateIntentDatabase = async (id, body) => {
    try {
      return await axios
        .put(`${baseURL}/BotTreeControllerNew/updateBotTreeByIdIntent/${id}`, body)
        .then((response) => response.data);
    } catch {
      console.error('Error service IntentService');
    }
  };

  export const findIntentDatabase = async (id) => {
    try {
      return await axios
        .get(`${baseURL}/BotTreeControllerNew/findBotTreeByIdIntent/${id}`)
        .then((response) => response.data);
    } catch {
      console.error('Error service findIntentDatabase');
    }
  };
  
export const findIntentsByIdAccount = async (sub) => {
    try {
      return await axios
        .get(`${baseURL}/BotTreeControllerNew/findIntentsByIdAccount/${sub}`)
        .then((response) => response.data);
    } catch {
      console.error('Error service IntentService treeIntents');
    }
  };

export const listTreeIntents = async (id) => {
  try {
    return await axios
      .get(`${baseURL}/BotTreeControllerNew/findListTreeIntentsByIdAccount/${id}`)
      .then((response) => response.data);
  } catch {
    console.error('Error service listTreeIntents');
  }
};