import React from 'react';
import useStyle from './style';

function VideoDemo() {
  const style = useStyle();

  return (
    <div className={style.demo}>
      <h1 className={style.videoTitle}> ¿Cómo funciona? </h1>
      <iframe
        className={style.videoContainer}
        width='560'
        height='315'
        src='https://www.youtube.com/embed/jAbNEr9A7rM'
        title='YouTube video player'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowfullscreen
      ></iframe>
    </div>
  );
}

export default VideoDemo;
