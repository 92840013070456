import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
  colorPrimary: {
    color: '#fff',
    width: '20px',
  },
  title: {
    lineHeight: '22px',
  },
  container: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#1b3c5a',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerResetPassword: {
    width: '60%',
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  resetPassword: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  resetPasswordTitle: {
    textAlign: 'center',
    color: '#fff',
    fontSize: '30px',
    fontFamily: 'Roboto condensed',
    fontWeight: '700',
    marginBottom: '-20px',
  },
  imgTelliou: {
    margin: 'auto',
  },
  container_button: {
    justifyContent: 'center',
    marginTop: '25px',
    display: 'flex',
    width: '100%',
  },
  container_options: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: '600',
  },
  sessionAccountLink: {
    color: '#e55959',
    fontSize: '18px',
    textDecoration: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  inputResetPassword: {
    width: '80%',
    height: '45px',
    marginTop: '15px',
    fontSize: '15px',
    borderRadius: '5px',
    textIndent: '1em',
    outline: 'none',
    '&::placeholder': {
      fontSize: '15px',
      textAlign: 'center',
    },
  },
  input: {
    borderRadius: '5px',
    width: '80%',
    height: '50px',
  },
  messageError: {
    textAlign: 'center',
    color: 'red',
  },
}));

export default useStyles;
