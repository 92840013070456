import React, { useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import GroupButton from './Components/GroupButton';
import useStyles from './styles';
const ProcessedCalls = () => {
  const [isActive, setIsActive] = useState(false);
  const [allCallsAnswers, setCallAllsAnswers] = useState(
    defaultValuesCallsAnswer()
  );
  const [allCallsffective, setAllCallsEffective] = useState(
    defaultValuesCallsEffective()
  );
  const [data, setData] = useState(defaultData());
  const [legend] = useState({
    llamadasEfectivas: 'llamadasEfectivas',
    llamadasRecibidas: 'llamadasRecibidas',
  });

  const classes = useStyles();
  return (
    <>
      <Container className={'container-mt-10'}>
        <Grid container className={classes.root}>
          <GroupButton
            data={data}
            setData={setData}
            legend={legend}
            allCallsAnswers={allCallsAnswers}
            setCallAllsAnswers={setCallAllsAnswers}
            allCallsffective={allCallsffective}
            setAllCallsEffective={setAllCallsEffective}
            isActive={isActive}
            setIsActive={setIsActive}
            container
            className={classes.root}
          />
        </Grid>
      </Container>
    </>
  );
};
export const defaultValuesCallsEffective = () => ({
  zeroToSix: 0,
  sixToTwelve: 0,
  twelveToEighteen: 0,
  eighteenToTwentyFour: 0,
});
export const defaultValuesCallsAnswer = () => ({
  zeroToSix: 0,
  sixToTwelve: 0,
  twelveToEighteen: 0,
  eighteenToTwentyFour: 0,
});
export const defaultData = () => [
  {
    name: '',
    llamadasEfectivas: 0,
    llamadasRecibidas: 0,

    amt: 0,
  },
];
export default ProcessedCalls;
