import React from 'react';
import { Pie } from 'react-chartjs-2';
import useChartPieForMonthMinutes from './useChartPieForMonthMinutes';

export const ChartPieForMonthMinutes = ({ ID }) => {
  const {data}   =  useChartPieForMonthMinutes(ID);
  return <Pie data={data} />;
};

export default ChartPieForMonthMinutes;
