import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
  container: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#1b3c5a',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerRegister: {
    width: '60%',
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  register: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  registerTitle: {
    textAlign: 'center',
    color: '#fff',
    fontSize: '30px',
    fontFamily: 'Roboto condensed',
    fontWeight: '700',
  },
  imgTelliou: {
    margin: 'auto',
  },
  inputRegister: {
    width: '80%',
    height: '45px',
    marginTop: '15px',
    fontSize: '15px',
    borderRadius: '5px',
    textIndent: '1em',
    outline: 'none',
    backgroundColor: '#fff',
    '&::placeholder': {
      fontSize: '15px',
      textAlign: 'center',
    },
  },
  checkAccount: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: '600',
  },
  sessionAccountLink: {
    color: '#e55959',
    fontSize: '18px',
    textDecoration: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  colorPrimary: {
    color: '#fff',
    width: '20px',
  },
  title: {
    lineHeight: '22px',
  },
  container_button: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 14,
  },
  format_title: {
    textAlign: 'center',
    fontSize: '14px',
  },
}));

export default useStyles;
