import { updateIntentDatabase, findIntentsByIdAccount, findIntentDatabase, deleteByIdIntentDatabase } from '../../../Service/tree/TreeDataBaseConsumeService'
import { deleteIntent, findByDisplayName, updateIntent} from '../../../Service/tree/TreeDialogFlowConsumeService';

export const deleteIntentionLogic = async (clickNodeId,setStateDelete, setStateValidationInsert, setValidationInsert, state, setOpen, setAccountant, setProgresoDelete) => {
  
  setStateValidationInsert(true)
  setValidationInsert("")
  
  const findPreviousIntent = await findIntentDatabase(clickNodeId)

  let jsonObject = JSON.parse(JSON.stringify(findPreviousIntent.json_intent));
  let previousIntent = JSON.parse(jsonObject);

  if (window.confirm("¿Estas seguro de eliminar el nodo?")) {
    setProgresoDelete(true)
    const deleteDB = await deleteByIdIntentDatabase(clickNodeId);

    if (deleteDB!==undefined) {

        await deleteIntent(clickNodeId)
        if(previousIntent.contextIn.length !==0){

          state(new Date().valueOf())
          setOpen(false)
          setAccountant(1)
          setStateDelete(true)

          const find = await findByDisplayName(previousIntent.contextIn)
          const findIntent = await findIntentDatabase(find.id)

          let jsonObjectIntentDb = JSON.parse(JSON.stringify(findIntent.json_intent));
          let jsonParseIntentDb = JSON.parse(jsonObjectIntentDb);

          let childrenNumber = (jsonParseIntentDb.children - 1)
          let  messagesList = new Array();
          
          find.messages.forEach(function(element) {
            let name = element.replaceAll("[", "").replaceAll("]", "")
            messagesList.push(name)
          });

          const updatePreviousNode = ({
            id: find.id,
            dialogFlowName: find.dialogFlowName,
            messages: messagesList,
            trainingPhrasesParts: find.trainingPhrasesParts,
            contextIn: find.contextIn,
            contextOut: [find.dialogFlowName],
            endInteraction: jsonParseIntentDb.children === 0 ? true: false,
            fallBackIntent: find.fallBackIntent
          })

          const updatePreviousNodeBotTreeDataBase = ({
            "json_intent" : JSON.stringify({
              id: find.id,
              displayName: jsonParseIntentDb.displayName,
              dialogFlowName : find.dialogFlowName,
              messages: messagesList,
              trainingPhrasesParts: find.trainingPhrasesParts,
              contextIn: find.contextIn,
              contextOut: [find.dialogFlowName],
              endInteraction: jsonParseIntentDb.children === 0 ? true: false,
              children: childrenNumber >= 0 ? childrenNumber : 0,
              fallBackIntent: find.fallBackIntent
            }),
            "id_intent" : find.id,
            "id_account" : localStorage.getItem("idAccount"),
            "messages" : JSON.stringify(messagesList),
            "fall_back_intent": find.fallBackIntent === false ? 0: 1,
            "children":  childrenNumber >= 0 ? childrenNumber : 0,
            "id_type": findIntent.id_type,
            "level": findIntent.level,
            "state": findIntent.state,
            "id_previous_intent": findIntent.id_previous_intent
           })

          await updateIntent(find.id,updatePreviousNode)
          await updateIntentDatabase(find.id, updatePreviousNodeBotTreeDataBase)

        } else {

          const findIntent = await findIntentsByIdAccount(localStorage.getItem("idAccount"))
          let listIntentText = JSON.stringify(findIntent)
          let listIntentObject = JSON.parse(listIntentText)[0].id

          await deleteByIdIntentDatabase(listIntentObject)
          await deleteIntent(listIntentObject)
          state(new Date().valueOf())
          setOpen(false)
          setAccountant(1)
          setStateDelete(true)
          console.log("se borró el nodo de resguardo")
        }
      }else {
        setStateDelete(false) 
        setProgresoDelete(false)
      }
      setProgresoDelete(false)
    }
}