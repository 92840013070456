import { useState } from 'react';
import {
  listAllAnswerCallByIdPerDay,
  listAllAnswerCallByIdPerWeek,
  listAllAnswerCallByIdPerMonth,
} from '../../../Service/graphics/GraphicsConsumeService';

const useChartForDayWeekMonth = ({ ID }) => {
  const [isActive, setIsActive] = useState(false);
  const [dataChart, setDataChart] = useState([]);

  const setIntervalTime = (array) => {
    return array.map((item) => {
      return {
        '12 a 6 am': item.zeroToSix,
        '6 a 12 m': item.sixToTwelve,
        '12 a 6 pm': item.twelveToEighteen,
        '6 a 12 pm': item.eighteenToTwentyFour,
      };
    });
  };

  const handleClickDay = async () => {
    let respAnswerPerDay = await listAllAnswerCallByIdPerDay(ID);
    let arregloDay = respAnswerPerDay;
    if (arregloDay.length === 2) {
      let day = setIntervalTime(arregloDay);

      const infoChart = {
        labels: Object.keys(day[0]),
        datasets: [
          {
            label: 'Llamadas recibidas',
            data: Object.values(day[0]),
            backgroundColor: 'rgb(75, 192, 192)',
          },
          {
            label: 'Llamadas efectivas',
            data: Object.values(day[1]),
            backgroundColor: 'rgb(54, 162, 235)',
          },
        ],
      };
      setDataChart(infoChart);
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const handleClickWeek = async () => {
    let resAnsweredCallPerWeek = await listAllAnswerCallByIdPerWeek(ID);
    let arregloWeek = resAnsweredCallPerWeek;
    if (arregloWeek.length === 2) {
      let week = setIntervalTime(arregloWeek);

      const infoChart = {
        labels: Object.keys(week[0]),
        datasets: [
          {
            label: 'Llamadas recibidas',
            data: Object.values(week[0]),
            backgroundColor: 'rgb(75, 192, 192)',
          },
          {
            label: 'Llamadas efectivas',
            data: Object.values(week[1]),
            backgroundColor: 'rgb(54, 162, 235)',
          },
        ],
      };

      setDataChart(infoChart);
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const handleClickMonth = async () => {
    let respAnswerPerMonth = await listAllAnswerCallByIdPerMonth(ID);
    let arregloMonth = respAnswerPerMonth;
    if (arregloMonth.length === 2) {
      let month = setIntervalTime(arregloMonth);

      const infoChart = {
        labels: Object.keys(month[0]),
        datasets: [
          {
            label: 'Llamadas recibidas',
            data: Object.values(month[0]),
            backgroundColor: 'rgb(75, 192, 192)',
          },
          {
            label: 'Llamadas efectivas',
            data: Object.values(month[1]),
            backgroundColor: 'rgb(54, 162, 235)',
          },
        ],
      };
      setDataChart(infoChart);
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  return {
    isActive,
    dataChart,
    handleClickDay,
    handleClickWeek,
    handleClickMonth,
  };
};

export default useChartForDayWeekMonth;
