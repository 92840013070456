import React from 'react';
import { TextField } from '@material-ui/core';
import useStyles from './styles';

function InputComponent({ name, setName, placeholder, fullWidth }) {
  const styles = useStyles();
  return (
    <>
      <TextField
        className={styles.input}
        id='standard-adornment-amount'
        placeholder={placeholder}
        value={name.label}
        InputProps={{ disableUnderline: true }}
        fullWidth={fullWidth}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setName([...name, e.target.value ]);
            console.log(name)
            e.target.value = '';
          }
        }}
      />
    </>
  );
}

export default InputComponent;
