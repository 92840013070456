import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles({
  textInfo: {
    fontSize: '45px',
    fontFamily: 'Roboto Condensed',
    fontWeight: '300',
    color: 'white',
  },
  buttonStyle: {
    fontSize: '20px',
    textAlign: 'center',
    color: 'white',
    width: '240px',
    border: 'none',
    height: '40px',
    fontFamily: 'Roboto',
    fontWeight: '900',
    borderRadius: '25px',
    backgroundColor: '#e55959',
    marginTop: '20px',
    cursor: 'pointer',
  },
  tituloResaltado: {
    fontSize: '45px',
    fontFamily: 'Roboto Condensed',
    fontWeight: '700',
    color: '#e55959',
    marginRight: '5px',
  },
  interLine: {
    lineHeight: '55px',
    width: '450px',
    marginTop: '50px',
  },
  styleLink: {
    textDecoration: 'none',
  },
  titleInfo: {
    fontSize: '35px',
    fontFamily: 'Roboto Condensed',
    fontWeight: '700',
    color: '#1b3c5a',
    textAlign: 'center',
    height: '0px',
  },
  longText: {
    fontSize: '20px',
    fontFamily: 'Roboto Condensed',
    fontWeight: '400',
    textAlign: 'center',
  },
  interLineInfo: {
    width: '450px',
    marginTop: '40px',
    margin: '0px 55px',
  },
  infoButton: {
    fontSize: '20px',
    textAlign: 'center',
    color: 'white',
    width: '120px',
    border: 'none',
    height: '40px',
    fontFamily: 'Roboto Condensed',
    fontWeight: '400',
    borderRadius: '25px',
    backgroundColor: '#e55959',
    marginTop: '20px',
    cursor: 'pointer',
    margin: '0px 55px',
  },
  titleLeft: {
    fontSize: '35px',
    fontFamily: 'Roboto Condensed',
    fontWeight: '700',
    color: '#1b3c5a',
    marginLeft: '55px',
    height: '0px',
  },
});

export default useStyle;
