import React from 'react';
import ButtonLoading from '../../../commons/buttonLoading/ButtonLoading';
import TextField from '@material-ui/core/TextField';

const ValidateCode = ({ handleSubmitCode, setCode, classes, loading }) => (
  <form className={classes.container} onSubmit={handleSubmitCode}>
    <div className={classes.containerRegister}>
      <div className={classes.register}>
        <div className={classes.registerTitle}>
          <h3>Introducir código de seguridad</h3>
        </div>

        <p className={classes.format_title}>
          Ingresar el código enviado a tu correo electrónico
        </p>

        {/* <TextField
          label="Código *"
          fullWidth
          margin="normal"
          onChange={(e) => setCode(e.target.value)}
          variant="outlined"
        /> */}
         <input
          className={classes.inputRegister}
          type='number'
          name='codigo'
          id=''
          placeholder='Codigo*'
          onChange={(e) => setCode(e.target.value)}
        />

        <div className={classes.conatiner_button}>
          <ButtonLoading label="Confirmar" loading={!loading} />
        </div>
      </div>
    </div>
  </form>
);

export default ValidateCode;
