import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  containerInput: {
    width: '82%',
    marginTop: '30px',
    marginBottom: '15px',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  inputPaswordNew: {
    width: '100%',
    height: '45px',
    fontSize: '15px',
    borderRadius: '5px',
    textIndent: '1em',
    outline: 'none',
    backgroundColor: '#fff',
    '&::placeholder': {
      fontSize: '15px',
      textAlign: 'center',
    },
  },
  iconEye: {
    marginTop: '-50px',
  },
}));

export default useStyles;
