import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import SimpleModal from '../modals/modal/modal';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import {
  findBotDetails,
  findAccountSubscriptionDetails,
} from '../../Service/account/AccountConsumeService';

export default function ImgMediaCard({ card }) {
  const classes = useStyles();
  let { bot_name, name, id } = card;
  const [request, setRequest] = useState({});
  const [bot, setBot] = useState({});
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getAccountSubscriptionDetails = async () => {
      let dataRequest = await findAccountSubscriptionDetails(id);
      let dataBot = await findBotDetails(id);
      setRequest(dataRequest);
      setBot(dataBot);
    };

    getAccountSubscriptionDetails();
  }, []);

  return (
    <Card className={classes.root}>
      <Link
        className={classes.textLink}
        to={{ pathname: `/report/${id}`, state: { bot, id } }}
      >
        <CardActionArea>
          <CardMedia color='textSecondary' />
          <CardContent className={classes.cardBackground}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography component='h2' className={classes.typografyTitle}>
                  {bot_name}
                </Typography>
                <Typography component='h2' className={classes.typografyInfo}>
                  Nombre Bot
                </Typography>
                <Typography component='h2' className={classes.typografyTitle}>
                  {name}
                </Typography>
                <Typography component='h2' className={classes.typografyInfo}>
                  Suscripción
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.icon}>
                <CardMedia className={classes.iconImage}/>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Link>
      <CardActions className={classes.centerButton}>
        <button type='button' onClick={handleOpen} className={classes.button}>
          {' '}
          DETALLES{' '}
        </button>
        <SimpleModal open={open} resp={request} close={handleClose} />
      </CardActions>
    </Card>
  );
}
