import { useEffect, useState, useCallback } from 'react';
import { findMinutesAvailableById} from '../../../Service/graphics/GraphicsConsumeService';

const useChartPieForMonthMinutes = (ID) => {
  const [data, setData] = useState({
    labels: ['Disponibles', 'Consumidos'],
    datasets: [
      {
        data: [200, 1000],
        backgroundColor: ['rgb(54, 162, 235)', 'rgb(75, 192, 192)'],
        borderColor: ['rgba(0, 0, 0, 1)', 'rgba(0, 0, 0, 1)'],
        borderWidth: 0.5,
        maxWidth: '50%',
      },
    ],
  });

  const minutesByIdPerMonth = useCallback(() => {
    const getMinutes = async () => {
      const responseMinuteDisp = await findMinutesAvailableById(ID);
      const info = {
        labels: ['Disponibles', 'Consumidos'],
        datasets: [
          {
            data: [responseMinuteDisp.minutes_available,responseMinuteDisp.minutes_consumed],
            backgroundColor: ['rgb(54, 162, 235)', 'rgb(75, 192, 192)'],
            borderColor: ['rgba(0, 0, 0, 1)', 'rgba(0, 0, 0, 1)'],
            borderWidth: 0.5,
            maxWidth: '50%',
          },
        ],
      };
      setData(info);
    };
    getMinutes();
  }, [ID]);

  useEffect(() => {
    minutesByIdPerMonth();
  }, [minutesByIdPerMonth]);

  return {
    data
  };
};

export default useChartPieForMonthMinutes;
