import React from 'react';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { saveIntentWelcome } from '../LogicTree/SaveIntents';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
//import useStyles from './styles';
const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        color: 'white',
        backgroundColor: '#00bfff',
      },
    },
  },
});

const guardarIntencion = (
  displayName,
  setProgressBtn,
  setTextValidation,
  setReload
) => {
  saveIntentWelcome(displayName, setProgressBtn, setTextValidation, setReload);
};

export const intentWelcome = (
  setDisplayName,
  progressBtn,
  textValidation,
  displayName,
  setProgressBtn,
  setTextValidation,
  setReload
) => {
  //const styles = new useStyles();
  return (
    <div className='containerTree'>
      <div className='bienvenidaTree'>
        <div className='bienvenida'>
          <h2 style={{ textAlign: 'center' }}>
            Bienvenidos a la sección de configuración de nuestro bot.
          </h2>
        </div>
        <h4 style={{ fontWeight: 'normal', padding: '10px' }}>
          En esta sección encontraras el arbol de intenciones de nuestro bot,
          este es el diseño del flujo conversacional que tiene el bot con
          nuestros clientes.
        </h4>
        <h4 style={{ color: '#1b3c5a' }}>
          ¿Que esperas para crear tu primera intención?
        </h4>
        <MuiThemeProvider theme={theme}>
          <Tooltip
            title={'Solo se permiten letras y números'}
            placement='top-start'
          >
            <TextField
              id='outlined-basic'
              label='Nombre de la intención'
              variant='outlined'
              onChange={(e) => setDisplayName('A1_' + e.target.value)}
              fullWidth
            />
          </Tooltip>
        </MuiThemeProvider>
        {progressBtn ? (
          <CircularProgress style={{ marginTop: '15px' }} color='primary' />
        ) : (
          <center>
                {/* <botton
                className={styles.button}
            //   style={{ marginTop: '15px' }}
            //   variant='contained'
            //   color='primary'
              onClick={() => {
                guardarIntencion(
                  displayName,
                  setProgressBtn,
                  setTextValidation,
                  setReload
                );
              }}
            >
              Guardar mi primer intención
            </botton> */}
            <Button
              style={{ marginTop: '15px' }}
              variant='contained'
              color='primary'
              onClick={() => {
                guardarIntencion(
                  displayName,
                  setProgressBtn,
                  setTextValidation,
                  setReload
                );
              }}
            >
              Guardar mi primer intención
            </Button>
          </center>
        )}

        {textValidation !== '' ? (
          <Alert style={{ marginTop: '15px' }} severity='error'>
            {textValidation}
          </Alert>
        ) : null}
      </div>
    </div>
  );
};
