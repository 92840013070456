import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import useStyles from './styles';
import ForgetPassword from './forgetPassword';
import ResetPassword from './resetPassword';

const Forget = () => {
  const classes = useStyles();
  const history = useHistory();

  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    if (!email.includes("@")) {
      setLoading(false);
      setError(
        "Por favor, vuelva a escribir la dirección de correo electrónico."
      );
    } else {
      setError("");
      Auth.forgotPassword(email)
        .then(() => {
          setLoading(false);
          setStatus(true);
        })
        .catch(err => {
          setLoading(false);
          if (err.code === "UserNotFoundException") {
            setError("Este correo electrónico no existe.");
          } else if (err.code === "LimitExceededException") {
            setError("Has excedido el numero de intentos, intente más tarde.");
          } else {
            console.log(err);
          }
        });
    }
  };

  const handleSubmitCode = e => {
    e.preventDefault();

    setLoading(true);

    if (!email.includes("@")) {
      setLoading(false);
      setErrorEmail(
        "Por favor, vuelva a escribir la dirección de correo electrónico."
      );
    }

    if (!code) {
      setErrorCode("Ingrese un código");
    }

    if (!newPassword) {
      setErrorNewPassword("Ingrese la nueva contraseña");
    }

    Auth.forgotPasswordSubmit(email, code, newPassword)
      .then(() => history.push("/login"))
      .catch(err => {
        console.log({ err });
        setLoading(false);
        if (err.code === "InvalidParameterException") {
          clean();
          setErrorNewPassword("La contraseña deber ser mayor a 8 caracteres");
        } else if (err.code === "CodeMismatchException") {
          clean();
          setError("Codigo de verificación es incorrecto");
        } else if (err.code === "LimitExceededException") {
          clean();
          setError("Has excedido el numero de intentos, intente más tarde.");
        }
      });
  };

  const clean = () => {
    setErrorCode("");
    setErrorNewPassword("");
  };

  return (
    <>
      <Collapse in={status}>
        <ResetPassword
          handleSubmitCode={handleSubmitCode}
          errorNewPassword={errorNewPassword}
          setNewPassword={setNewPassword}
          errorCode={errorCode}
          loading={loading}
          setCode={setCode}
          classes={classes}
          error={error}
        />
      </Collapse>
      <Collapse in={!status}>
        <ForgetPassword
          handleSubmit={handleSubmit}
          errorEmail={errorEmail}
          setEmail={setEmail}
          loading={loading}
          classes={classes}
          error={error}
        />
      </Collapse>
    </>
  );
};

export default Forget;
