import axios from 'axios';

import {UriConfiguration}  from '../configuration/ApiServices'
let baseURL = UriConfiguration();

export const insertEntityDatabase = async (data) => {
    try {
      return await axios
        .post(`${baseURL}/EntitiesControllerNew`, data)
        .then((response) => response.data);
    } catch (error) {
      console.error('Error insert entity data base service', { error });
    }
  };

  export const updateEntityDatabase = async (body, id) => {
    try {
      return await axios
        .put(`${baseURL}/EntitiesControllerNew/${body}`, id)
        .then((response) => response.data);
    } catch (error){
      console.error('Error update entity data base', { error });
    }
  };

  export const deleteEntityDatabase = async (id) => {
    try {
      return await axios
        .delete(`${baseURL}/EntitiesControllerNew/${id}`)
        .then((response) => response.data);
    } catch (error){
      console.error('Error service delete Entity data base', { error });
    }
  };

  export const findEntitiesDatabase = async (id) => {
    try {
      return await axios
        .get(`${baseURL}/EntitiesControllerNew/${id}`)
        .then((response) => response.data);
    } catch(error) {
      console.error('Error service GetEntitiesDatabase', { error });
    }
  };
  
  export const findAllEntitiesDatabase = async () => {
    try {
      return await axios
        .get(`${baseURL}/EntitiesControllerNew`)
        .then((response) => response.data);
    } catch(error) {
      console.error('Error service GetAllEntitiesDatabase', { error });
    }
  };