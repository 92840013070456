import React from 'react';
import LogoRojo from '../../../../assets/img/logotipo_telliou_rojo.png';
import InstagramBlanco from '../../../../assets/img/instagram_blanco.png';
import FacebookBlanco from '../../../../assets/img/faceook_blanco.png';
import TwitterBlanco from '../../../../assets/img/twitter_blanco.png';
import YoutubeBlanco from '../../../../assets/img/youtube_blanco.png';
import LinkedinBlanco from '../../../../assets/img/linkedin_blanco.png';
import IconoCorreo from '../../../../assets/img/icono_correo.png';
import LogoIas from '../../../../assets/img/logotipo_iassoftware.png';
import useStyle from './styles';
function Footer() {
  const style = useStyle();
  return (
    <>
      <div className={style.socialLogosFot}>
        <a href='https://www.instagram.com/iassoftware/'><img className={style.iconSocialLogoFot} alt="Instagram Logo" src={InstagramBlanco} /></a>
        <a href='https://www.facebook.com/iassoftware'><img className={style.iconSocialLogoFot} alt="Facebook Logo" src={FacebookBlanco} /></a>
        <a href='https://twitter.com/IASSOFTWARE?t=UEWYOxvrnpK8uLzpIj-5KQ&s=09'><img className={style.iconSocialLogoFot} alt="Twitter Logo" src={TwitterBlanco} /></a>
        <a href='https://www.youtube.com/c/IASSOFTWARE'><img className={style.iconSocialLogoFot} alt="Youtube Logo" src={YoutubeBlanco} /></a>
        <a href='https://www.linkedin.com/company/iassoftware/mycompany/'><img className={style.iconSocialLogoFot} alt="Linkedin Logo" src={LinkedinBlanco} /></a>

        <h1 className={style.textCorreo}>
          <img src={IconoCorreo} alt="Correo Icon" className={style.iconCorreo} />
          info@telliou.com
        </h1>
      </div>
      <div>
        <img src={LogoRojo} alt="Logo Icon" className={style.LogoTelliou} />
      </div>
      <div>
        <h1 className={style.textLogo}>
          Un producto
          <a href='https://www.ias.com.co/'><img src={LogoIas} alt="Ias Logo" className={style.IconLogoIas} /></a>
        </h1>
      </div>
    </>
  );
}

export default Footer;
