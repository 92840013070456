import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  conteiner: {
    display: 'grid',
    gridGap: '1rem',
  },
  voiceContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
}));

export default useStyles;
