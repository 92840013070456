import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  colorPrimary: {
    color: '#fff',
    width: '20px',
  },
  wrapper: {
    display: 'flex',
    margin: '23px auto 23px auto',
    justifyContent: 'space-around',
  },
  messageError: {
    color: 'red',
    textAlign: 'center',
  },
  container: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#1b3c5a',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerLogin: {
    width: '60%',
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  login: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoTelliou: {
    height: '110px',
  },
  inputLogin: {
    width: '80%',
    height: '45px',
    fontSize: '15px',
    borderRadius: '5px',
    textIndent: '1em',
    outline: 'none',
    backgroundColor: '#fff',
    '&::placeholder': {
      fontSize: '15px',
      textAlign: 'center',
    },
  },

  forgetPassword: {
    color: '#e55959',
    float: 'right',
    fontSize: '14px',
    marginTop: '6px',
    lineHeight: '25px',
    textDecoration: 'none',
    marginLeft: '50px',
  },
  createAccount: {
    color: '#fff',
    fontSize: '15px',
    fontWeight: '600',
    justifyContent: 'center',
    alignItems: 'center',
  },
  createAccountLink: {
    color: '#e55959',
    fontSize: '14px',
    textDecoration: 'none',
    marginLeft: '30px',
  },
}));

export default useStyles;
