import React from 'react';
import useStyle from './styles';
import LogoTelliou from '../../../../assets/img/logo_telliou_blanco.png';
import InstagramRojo from '../../../../assets/img/instagram_rojo.png';
import FacebookRojo from '../../../../assets/img/facebook_rojo.png';
import TwitterRojo from '../../../../assets/img/twitter_rojo.png';
import YoutubeRojo from '../../../../assets/img/youtube_rojo.png';
import LinkedinRojo from '../../../../assets/img/linkedin_rojo.png';
import TextAndButton from '../textAndButton';
function Banner() {
  const style = useStyle();
  let textForInfoRedBold = 'Asistente digital';
  let textForInfo = 'para recibir y realizar llamadas por canales telefónicos.';
  let textForButton = 'COMIENZA AHORA';

  return (
    <>
      <section className={style.bannerImage}>
        <div className={style.logosContainer}>
          <img src={LogoTelliou} alt="Telliou Logo" className={style.LogoTelliou} />
          <div className={style.socialLogos}>
            <a href='https://www.instagram.com/iassoftware/'><img className={style.iconSocialLogo} alt="Instagram Logo" src={InstagramRojo}/></a>
            <a href='https://www.facebook.com/iassoftware'><img className={style.iconSocialLogo} alt="Facebook Logo" src={FacebookRojo} /></a>
            <a href='https://twitter.com/IASSOFTWARE?t=UEWYOxvrnpK8uLzpIj-5KQ&s=09'><img className={style.iconSocialLogo} alt="Twitter Logo" src={TwitterRojo} /></a>
            <a href='https://www.youtube.com/c/IASSOFTWARE'><img className={style.iconSocialLogo} alt="Youtube Logo" src={YoutubeRojo} /> </a>
            <a href='https://www.linkedin.com/company/iassoftware/mycompany/'><img className={style.iconSocialLogo} alt="Linkedin Logo" src={LinkedinRojo} /> </a>
          </div>
        </div>
        <div className={style.textAndButton}>
          <TextAndButton
            textForInfoRedBold={textForInfoRedBold}
            textInfo={textForInfo}
            buttonText={textForButton}
          />
        </div>
      </section>
    </>
  );
}

export default Banner;
