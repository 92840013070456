import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import ValidateCode from './validateCode';
import FormRegister from './formRegister';
import useStyles from './styles';
import './SignUpForm.css';
import { insertAccount } from '../../../Service/account/AccountConsumeService';
import { insertUser } from '../../../Service/platformUser/PlatformUserConsumeService';
import { LOGIN_ROUTE } from '../../../constants/Routes/constantsRoutes';
let platformU = null;

const SignUpForm = () => {
  const [account] = useState({
    bot_name: 'Modificar bot name',
    sip_address: 'Modificar SIP address',
    sip_extension: 'Modificar SIP extension',
    idSubscriptionModel: '1',
    minutes_consumed: '0',
    id_voice: 'b69908e3-d137-416d-a874-56fe2d3d0f88', //Penelope
  });

 
  const [idAccount, setIdAccount] = useState('');
  const [userSub, setUserSub] = useState('');
  const [email, setEmail] = useState('');

  const platformUser = {
    email: email,
    id_cognito: userSub,
    id_account: idAccount,
    id_rol: '2',
  };

  platformU = platformUser;

  const [password, setPassword] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [seePassword, setSeePassword] = useState(false);

  const [errorUsername, setErrorUsername] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorPhoneNumber, setErrorPhoneNumber] = useState('');

  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [error, setError] = useState('');

  const classes = useStyles();
  let history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

  
    if (!email.includes('@')) {
      setLoading(false);
      setErrorEmail(
        'Por favor, vuelva a escribir la dirección de correo electrónico.'
      );
    }
    if (!password) {
      setLoading(false);
      setErrorPassword('Ingrese una contraseña');
    }
    if (!phone_number) {
      setLoading(false);
      setErrorPhoneNumber('Ingresa un número de teléfono.');
    }

    Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email: email,
        phone_number: `+57${phone_number}`,
      },
    })
      .then((data) => {
        setStatus(true);
        setUserSub(data.userSub);
        insertAccountFunction();
      })

      .catch((err) => {
        setLoading(false);
        if (err.code === 'UsernameExistsException') {
          clean();
          setError('Ya existe una cuenta con este correo electrónico.');
        }
      });
  };

  const insertAccountFunction = async () => {
    const insert = await insertAccount(account);
    setIdAccount(insert.id);
    await insertUser(platformU);
  };

  const handleSubmitCode = (e) => {
    e.preventDefault();
    Auth.confirmSignUp(email, code)
      .then(() => history.push(LOGIN_ROUTE))
      .catch((err) => console.log(JSON.stringify(err)));
  };

  const clean = () => {
    setErrorUsername('');
    setErrorEmail('');
    setErrorPassword('');
    setErrorPhoneNumber('');
  };

  return (
    <>
      <Collapse in={!status}>
        <FormRegister
          errorPhoneNumber={errorPhoneNumber}
          setSeePassword={setSeePassword}
          setPhoneNumber={setPhoneNumber}
          errorUsername={errorUsername}
          errorPassword={errorPassword}
          handleSubmit={handleSubmit}
          seePassword={seePassword}
          setPassword={setPassword}
          errorEmail={errorEmail}
          setEmail={setEmail}
          classes={classes}
          loading={loading}
          error={error}
        />
      </Collapse>
      <Collapse in={status}>
        <ValidateCode
          handleSubmitCode={handleSubmitCode}
          setCode={setCode}
          classes={classes}
          loading={loading}
        />
      </Collapse>
    </>
  );
};

export default SignUpForm;
