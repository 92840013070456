import { makeStyles } from '@material-ui/core/styles';
import Banner from '../../../../assets/img/banner.jpg';

export const useStyle = makeStyles({
  bannerImage: {
    backgroundImage: `url(${Banner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    height: '88vh',
    objectFit: 'cover',
  },
  logosContainer: {
    display: 'flex',
    paddingLeft: '30px',
    paddingRight: '30px',
    justifyContent: 'space-between',
    paddingTop: '50px'
  },
  LogoTelliou: {
    width: '300px',
    height: '100px',
    paddingTop: '40px',
    paddingLeft: '100px',
  },
  socialLogos: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingRight: '60px',
    paddingTop: '40px',
  },
  iconSocialLogo: {
    height: '35px',
    width: '35px',
    paddingTop: '20px',
  },
  textAndButton: {
    paddingTop: '40px',
    paddingLeft: '140px',
    width: '600px',
    height: 'auto'
  },
});

export default useStyle;
