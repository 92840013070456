import React, { useState, useEffect } from 'react';
import { Card, Box, IconButton, CardContent, Fab } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { withRouter } from 'react-router-dom';
import {listTreeIntents,updateIntentDatabase,findIntentDatabase} from '../../Service/tree/TreeDataBaseConsumeService';
import { updateIntent } from '../../Service/tree/TreeDialogFlowConsumeService';
import IconoEditar from '../../assets/img/icono_editar.png';
import ModalUpdateNode from './Modal/modalUpdateNode';
import useStyles from './styles';
import { fieldValidationLogic } from '../Tree/fieldValidation';

const Nodes = (props) => {
  const [especificIntent, setEspecificIntent] = useState({
    id: '',
    displayName: '',
    messages: [],
    trainingPhrasesParts: [],
    contextIn: [],
    contextOut: [],
    endInteraction: null,
    children: 0,
    fallBacIntent: null,
    id_type: 0,
    level: 0,
    state: null,
  });

  const [dataIntent, setDataIntent] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [messagesUpdate, setMessagesUpdate] = useState([]);
  const [phraseUpdate, setPhraseUpdate] = useState([]);
  const [progreso, setProgreso] = useState(false);
  const [initialStatePhrases, setInitialStatePrhases] = useState(false);
  const [validationInsert, setValidationInsert] = useState('');
  const [stateValidationInsert, setStateValidationInsert] = useState(false);
  const [stateValidation, setStateValidation] = useState(false);
  const [typeIntent, setTypeIntent] = useState('');

  const intent = {
    json_intent: JSON.stringify({
      id: especificIntent.id,
      displayName: especificIntent.displayName,
      dialogFlowName: especificIntent.dialogFlowName,
      messages: messagesUpdate,
      trainingPhrasesParts: phraseUpdate,
      contextIn: especificIntent.contextIn,
      contextOut: especificIntent.contextOut,
      endInteraction: especificIntent.endInteraction,
      children: especificIntent.children,
      fallBackIntent: especificIntent.fallBackIntent,
    }),
    id_intent: especificIntent.id,
    id_account: localStorage.getItem('idAccount'),
    messages: JSON.stringify(especificIntent.messages),
    fall_back_intent: especificIntent.fallBackIntent === true ? 1 : 0,
    children: especificIntent.children,
    id_type: 0,
    level: 0,
    state: '',
  };

  const updateIntentDialogFlow = {
    id: id,
    displayName: especificIntent.displayName,
    dialogFlowName: especificIntent.dialogFlowName,
    messages: messagesUpdate,
    trainingPhrasesParts: phraseUpdate,
    contextIn: especificIntent.contextIn,
    contextOut: especificIntent.contextOut,
    endInteraction: especificIntent.endInteraction,
    children: especificIntent.children,
    fallBackIntent: especificIntent.fallBackIntent,
  };

  const handleSaveIntent = async (e) => {
    //setProgreso(true);
    const findIntent = await findIntentDatabase(id);
    intent.messages = JSON.stringify(messagesUpdate);
    intent.id_type = findIntent.id_type;
    intent.level = findIntent.level;
    intent.state = findIntent.state;
    //intent.trainingPhrasesParts = phraseUpdate
    //console.log(id, intent);
    setStateValidation(false);
    let validation = fieldValidationLogic(
      setProgreso,
      setValidationInsert,
      updateIntentDialogFlow.displayName.slice(
        updateIntentDialogFlow.displayName.indexOf('_') + 1
      ),
      setStateValidationInsert,
      messagesUpdate,
      phraseUpdate,
      initialStatePhrases,
      typeIntent
    );

    console.log('validation' + validation);
    if (validation !== false) {
      const response = await updateIntentDatabase(id, intent);

      if (response !== undefined) {
        setStateValidationInsert(true);
        await updateIntent(id, updateIntentDialogFlow);
        setStateValidation(true);
        setValidationInsert('Nodo actualizado correctamente');
        setProgreso(false);
      } else {
        setStateValidationInsert(false);
        setStateValidation(true);
        setValidationInsert('Error al actualizar el nodo');
        setProgreso(false);
      }
      listIntents();
    }
    setStateValidation(true);
  };

  const handleEditIntent = async (theIntent) => {
    const findIntent = await findIntentDatabase(theIntent.id);
    setEspecificIntent(theIntent);
    setInitialStatePrhases(
      theIntent.contextIn.length === 0
        ? false
        : theIntent.fallBackIntent === true
        ? false
        : true
    );
    console.log(theIntent);
    setOpen(true);
    setId(theIntent.id);
    setTypeIntent(findIntent.id_type);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setStateValidation(false);
    setOpen(false);
  };
  const styles = useStyles();

  const listIntents = () => {
    const id_account = localStorage.getItem('idAccount');
    listTreeIntents(id_account).then((response) => {
      let listJsonIntent = new Array();
      response.forEach(function(element) {
        let jsonObject = listIntentsConvertToJson(element);
        listJsonIntent.push(jsonObject);
      });
      setDataIntent(listJsonIntent ? listJsonIntent : []);
    });
  };

  const listIntentsConvertToJson = (element) => {
    let jsonObject = JSON.parse(JSON.stringify(element.json_intent));
    return JSON.parse(jsonObject);
  };

  useEffect(() => {
    listIntents();
  }, []);

  return (
    <div>
      <div>
        {/* {dataIntent === undefined ? (
          <>
            <h2>No hay nodos</h2>
          </>
        ) : ( */}
        {dataIntent.length === 0 || dataIntent=== undefined ? (
          // <p>Cargando</p>
          <h2>No hay nodos</h2>
        ) : (
          <div>
            {dataIntent.map((item, key) => {
              return (
                <div className={styles.card} key={`key_key_${key}-${item.id}`}>
                  <div className={styles.containerIntent}>
                    <div className={styles.containerDetails}>
                      <p className={styles.statLabel2}>
                        Nombre del nodo
                      </p>

                      <h3 className={styles.heading}>
                        {item.displayName
                          .slice(item.displayName.indexOf('_') + 1)
                          .replaceAll('_', ' ')}
                      </h3>
                      <button
                        className={styles.button}
                        onClick={() =>
                          //props.history.push(`entities/${item.id}`,
                          props.history.push(`entities/`, {
                            item: item.id,
                          })
                        }
                      >
                        Detalles
                      </button>
                    </div>
                    <div className={styles.containerMessage}>
                      <p className={styles.statLabel}>Mensajes</p>
                      <p className={styles.statValue}>{item.messages.length}</p>
                    </div>
                    {/* </Box> */}
                    {/* <Box p={2} flex={'auto'}>
                          <p className={styles.statLabel}>
                            Frases de entrenamiento
                          </p>
                          <p className={styles.statValue}>
                            {item.trainingPhrasesParts.length}
                          </p>
                        </Box> */}
                    {/* <Box p={2} flex={'auto'} paddingTop={5}> */}
                    <div
                      onClick={() => {
                        handleOpen();
                        handleEditIntent(item);
                        setMessagesUpdate(item.messages);
                        setPhraseUpdate(item.trainingPhrasesParts);
                        console.log(item);
                      }}
                      className={styles.fabEdit}
                    >
                      <img
                        src={IconoEditar}
                        alt='Icono Editar'
                        className={styles.imgEdit}
                      />
                    </div>
                  </div>
                  <hr />

                  {/* </Box> */}
                  {/* <Box p={2} flex={'auto'} paddingTop={5}>
                          <Fab
                            size='small'
                            color='secondary'
                            aria-label='Borar'
                            onClick={() =>
                              advertDelete(item, [dataIntent, setDataIntent])
                            }
                          >
                            <DeleteIcon />
                          </Fab>
                        </Box> */}
                  {/* </Box> */}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div>
        <ModalUpdateNode
          open={open}
          onClose={() => handleClose()}
          intent={especificIntent}
          handleSaveIntent={handleSaveIntent}
          messagesUpdate={messagesUpdate}
          setMessagesUpdate={setMessagesUpdate}
          phraseUpdate={phraseUpdate}
          setPhraseUpdate={setPhraseUpdate}
          progreso={progreso}
          initialStatePhrases={initialStatePhrases}
          validationInsert={validationInsert}
          stateValidationInsert={stateValidationInsert}
          stateValidation={stateValidation}
        />
      </div>
    </div>
  );
};

export default withRouter(Nodes);
