import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles({
  demo: {
    width: '100%',
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#e55959',
  },
  videoContainer: {
    borderRadius: '8px',
    width: '900px',
    height: '500px',
    marginBottom: '40px',
  },
  videoTitle: {
    fontFamily: 'Roboto condensed',
    fontWeight: '700',
    fontSize: '60px',
    color: '#1b3c5a',
  },
});

export default useStyle;
