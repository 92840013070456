import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles({
  socialLogosFot: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  iconSocialLogoFot: {
    height: '30px',
    width: '30px',
  },
  iconCorreo: {
    width: '30px',
    height: '20px',
    marginLeft: '60px',
    marginRight: '10px',
    verticalAlign: 'middle',
  },
  textCorreo: {
    fontFamily: 'Roboto condensed',
    fontWeight: '300',
    fontSize: '16px',
    color: 'white',
  },
  LogoTelliou: {
    width: '120px',
    height: '45px',
    marginLeft: '-45%',
  },
  textLogo: {
    fontFamily: 'Roboto condensed',
    fontWeight: '300',
    fontSize: '16px',
    color: 'white',
  },
  IconLogoIas: {
    width: '170px',
    height: '30px',
    marginLeft: '10px',
    verticalAlign: 'middle',
  },
});

export default useStyle;
