import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: '6px 35px',
    backgroundColor: '#03A9F4',
  },
  formGroup: {
    display: 'block',
  },
  formControl: {
    display: 'grid',
    margin: theme.spacing(3),
  },
  input: {
    '&::before': {
      content: '',
      position: 'absolute',
      left: '2.5%',
      bottom: 0,
      right: '2.5%',
      width: '95%',
      borderBottom: '1px solid magenta',
    },
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.background.paper}`,
  },
  item: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.background.paper}`,
  },
  box: {
    width: '83%',
  },
}));

export default useStyles;
