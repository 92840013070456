import React, { useState } from 'react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import Routes from './Routes';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';
import StorageContext from './reducer/index';
import Storage from '@aws-amplify/storage';

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  esES
);

Amplify.configure(awsconfig);

const App = () => (
  <StorageContext>
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  </StorageContext>
);

export default App;
