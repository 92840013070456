import {updateIntentDatabase,findIntentDatabase} from "../../../Service/tree/TreeDataBaseConsumeService"
import { fieldValidationLogic } from '../fieldValidation';

export const updateIntentModal = async (setStateDelete,setValidationInsert,setProgreso, setStateValidationInsert, updateIntent, inputTitle,node, updateIntentJson, setDisplayNameUpdate,nodeDataChild,messages, trainingPhrasesParts,setStateValidation,initialStatePhrases,typeIntent, previousIntentType) => {
      const fieldValidation = () =>{
        return fieldValidationLogic(setProgreso,setValidationInsert,inputTitle,setStateValidationInsert,messages,trainingPhrasesParts,initialStatePhrases,typeIntent,previousIntentType,"update")
      };

      setStateDelete(true)
      setStateValidation(false)
      let validation = fieldValidation();
      if (validation !== false) {
        
      let response = null
      const findIntent = await findIntentDatabase(node.id)
      let jsonParse = JSON.parse(JSON.stringify(findIntent.json_intent));
      let jsonObject = JSON.parse(jsonParse);
      jsonObject.trainingPhrasesParts = updateIntentJson.trainingPhrasesParts;

      let  messagesList = new Array();
      updateIntentJson.messages.forEach(function(element) {
        let name = element.replaceAll("[", "").replaceAll("]", "")
        messagesList.push(name)
      });

      updateIntentJson.messages = messagesList
      jsonObject.messages = updateIntentJson.messages;
      jsonObject.displayName = updateIntentJson.displayName
      
       const updateBotTreeDialogFlow = ({
         id : node.id,
         displayName : updateIntentJson.displayName,
         dialogFlowName : jsonObject.dialogFlowName,
         messages : updateIntentJson.messages,
         trainingPhrasesParts : updateIntentJson.trainingPhrasesParts,
         contextIn : jsonObject.contextIn,
         contextOut: [jsonObject.dialogFlowName],
         endInteraction : jsonObject.endInteraction,
         children : nodeDataChild.length,
         fallBackIntent : jsonObject.fallBackIntent
      })
      
      const idAccount = localStorage.getItem('idAccount');
      let fallBackIntentRta = jsonObject.fallBackIntent === false ? 0:1;

      const updateBotTreeDataBase = ({
        json_intent: JSON.stringify(jsonObject),
        id_intent : node.id,
        id_account: idAccount,
        messages : JSON.stringify(updateIntentJson.messages),
        children : nodeDataChild.length,
        fall_back_intent : fallBackIntentRta,
        id_type: findIntent.id_type,
        level: findIntent.level,
        state: findIntent.state,
        id_previous_intent: findIntent.id_previous_intent
     })

      if(JSON.stringify(nodeDataChild) === "[]"){
        updateBotTreeDialogFlow.endInteraction = true
        response =  await updateIntent(node.id, updateBotTreeDialogFlow)
     }else{
       response =  await updateIntent(node.id, updateBotTreeDialogFlow)
     }
      if(response===undefined){
        setStateValidationInsert(false)
        setStateValidation(true)
        setValidationInsert("Error al actualizar el nodo")
      }else{
        setStateValidationInsert(true)
        setDisplayNameUpdate(node.name+"_"+inputTitle)
        await updateIntentDatabase(node.id,updateBotTreeDataBase)
        setStateValidation(true)
        setValidationInsert("Nodo actualizado correctamente")        
      }
      setProgreso(false)
    }
    setStateValidation(true)
  }