export const LANDING_PAGE_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const REGISTER_ROUTE = "/register";
export const HOME_ROUTE = "/home";
export const HOME_ADMIN_ROUTE = "/homeAdmin";
export const PASSWORD_RESET_ROUTE =  "/password_reset";
export const REPORT_BYBOT_ROUTE = "/report/:idAccount";
export const ACCOUNT_ROUTE =  "/account";
export const USER_ROUTE =  "/user";
export const TREE_ROUTE =  "/tree";
export const START_CALL_ROUTE =  "/SimpleCall";
export const PERIODIC_CALLS_ROUTE =  "/PeriodicCalls";
export const TRAINING_ROUTE =  "/training";
export const NODES_ROUTE =  "/Nodes";
// export const ENTITIES_ROUTE = `${INTENTS_ROUTE}/entities`;
export const ENTITIES_ROUTE = `/entities`;
export const CALLS_PROCESED_ROUTE =  "/callsProcessed";
export const CALLS_EFECTIVES_ROUTE =  "/callsEffectives";
export const CONVERSATION_ROUTE =  "/conversation";