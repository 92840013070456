import axios from 'axios';
import {UriConfiguration}  from '../configuration/ApiServices'

let baseURL = UriConfiguration();

export const findMinutesByIdPerMonth = async (id) => {
  try {
    return await axios
      .get(`${baseURL}/AccountControllerNew/findMinutesByIdPerMonth/${id}`)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error create service IntentService', { error });
  }
};

export const findMinutesAvailableById = async (id) => {
  try {
    return await axios
      .get(`${baseURL}/SubscriptionModelControllerNew/findSubscriptionModelAndAccountDetailsByIdAccount/${id}`)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error create service IntentService', { error });
  }
};

export const listAllResponseCallByIdPerDay = async (id) => {
  try {
    return await axios
      .get(`${baseURL}/CallResponseAnalyticsController/findAllResponseCallByIdPerDay/${id}`)
      .then((response) => {
        return response.data;
      });
  } catch {
    console.error('Error list calls');
  }
};

export const listAllResponseCallByIdPerWeek = async (id) => {
  try {
    return await axios
      .get(`${baseURL}/CallResponseAnalyticsController/findAllResponseCallByIdPerWeek/${id}`)
      .then((response) => {
        return response.data;
      });
  } catch {
    console.error('Error list calls');
  }
};

export const listAllResponseCallByIdPerMonth = async (id) => {
  try {
    return await axios
      .get(`${baseURL}/CallResponseAnalyticsController/findAllResponseCallByIdPerMonth/${id}`)
      .then((response) => {
        return response.data;
      });
  } catch {
    console.error('Error list calls');
  }
};

export const listAllAnswerCallByIdPerWeek = async (id) => {
  try {
    return await axios
      .get(`${baseURL}/CallAnswerAnalyticsController/findAllAnswerCallByIdPerWeek/${id}`)
      .then((response) => {
        return response.data;
      });
  } catch {
    console.error('Error list calls');
  }
};
export const listAllAnswerCallByIdPerMonth = async (id) => {
  try {
    return await axios
      .get(`${baseURL}/CallAnswerAnalyticsController/findAllAnswerCallByIdPerMonth/${id}`)
      .then((response) => {
        return response.data;
      });
  } catch {
    console.error('Error list calls');
  }
};

export const listAllAnswerCallByIdPerDay = async (id) => {
  try {
    return await axios
      .get(`${baseURL}/CallAnswerAnalyticsController/findAnswerCallsByIdPerDay/${id}`)
      .then((response) => {
        return response.data;
      });
  } catch {
    console.error('Error list calls');
  }
};