import React, { useState } from 'react';
import Input from './components/Input';
import ButtonComponent from './components/Button';
import GroupCheckbox from './components/GroupCheckbox';
import { Container, Grid, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListEntry from './components/ListEntry';
import useStyles from './styles';
import Button from '@material-ui/core/Button';

const Entities = () => {
  const [name, setName] = useState('');
  const [item, setItem] = useState('');
  const classes = useStyles();
  return (
    <>
      <Container className={'container-mt-10'}>
        <Grid container className={classes.root}>
          <Grid item className={classes.item} xs={8}>
            <Input
              name={name}
              setName={setName}
              placeholder='Nombre de la Entidad'
              fullWidth={true}
            />
          </Grid>
          <Grid item className={classes.item} xs={'auto'}>
            <ButtonComponent name={name} setName={setName} />
          </Grid>
          <Grid item xs={'auto'}>
            <IconButton
              aria-label='more'
              aria-controls='long-menu'
              aria-haspopup='true'
              //onClick={''}
            >
              <MoreVertIcon />
            </IconButton>
          </Grid>
        </Grid>
        <hr />
        <Grid>
          <GroupCheckbox />
        </Grid>
        <Grid>
          <ListEntry name={item} setName={setItem} />
        </Grid>

        <Button>
        + Añadir fila
      </Button>
      </Container>
    </>
  );
};

export default Entities;
