import { findIntentByIdIntent } from '../../../Service/tree/TreeDialogFlowConsumeService'
import { findIntentDatabase } from '../../../Service/tree/TreeDataBaseConsumeService'

export const onNodeClickLogic = (nodeData, setLinearProgress, setActiveButton, setNodeDataChild, clickNode, setAccountant, setNode, setDisplayNameUpdate,  /*--*/ setIdNode, setMessagesUpdate, setPhraseUpdate, setContextInUpdate, setContextOutUpdate, setOpen,setBotonState, setInitialStatePrhases, setFallBackIntent, setTypeIntent,setPreviousIntentType) => {

  if (JSON.stringify(nodeData.children) === "[]") {
    setActiveButton(true)
  } else {
    setActiveButton(false)
  }
  
  if (nodeData.children.length === 5) {
    setBotonState(true)
  } else {
    setBotonState(false)
  }
  if (JSON.stringify(nodeData.children) === "[]") {
    setActiveButton(true)
  } else {
    setActiveButton(false)
  }

 
  setLinearProgress(true)
  setNodeDataChild(nodeData.children)
  clickNode(nodeData)
  setAccountant(1)
  searchIntent(nodeData.id, setDisplayNameUpdate, setIdNode, setMessagesUpdate, setPhraseUpdate, setContextOutUpdate, setOpen, setLinearProgress, setContextInUpdate, setInitialStatePrhases, setActiveButton, setBotonState, setFallBackIntent,setTypeIntent,setPreviousIntentType)
  setNode({
    name: nodeData.title,
    id: nodeData.id,
    title: nodeData.title,
    children: 12
  })
 
}

const searchIntent = async (item, setDisplayNameUpdate, setIdNode, setMessagesUpdate, setPhraseUpdate, setContextOutUpdate, setOpen, setLinearProgress, setContextInUpdate, setInitialStatePrhases, setActiveButton, setBotonState, setFallBackIntent,setTypeIntent,setPreviousIntentType) => {
  const detailNode = await findIntentByIdIntent(item)
  const findIntent = await findIntentDatabase(item)
  
  setTypeIntent(findIntent.id_type)

  if(detailNode.contextIn.length !== 0 && detailNode.fallBackIntent !== true){
    const findPreviousIntentType = await findIntentDatabase(findIntent.id_previous_intent)
    setPreviousIntentType(findPreviousIntentType.id_type)
  }

  if (detailNode.contextIn.length === 0) {
    setInitialStatePrhases(false)
  } else {
    setInitialStatePrhases(true)
  }
  if (detailNode.fallBackIntent === true) {
    setInitialStatePrhases(false)
    setActiveButton(false)
    setBotonState(true)
    setFallBackIntent(true)
  } else {
    setFallBackIntent(false)
  }

  console.log(findIntent)
  let jsonObject = JSON.parse(JSON.stringify(findIntent.json_intent));
  let jsonParse = JSON.parse(jsonObject);

  let  messagesList = new Array();
  detailNode.messages.forEach(function(element) {
    let name = element.replaceAll("[", "").replaceAll("]", "")
    messagesList.push(name)
  });

  setDisplayNameUpdate(jsonParse.displayName)
  setIdNode(detailNode.id)
  setMessagesUpdate(messagesList)
  setPhraseUpdate(detailNode.trainingPhrasesParts)
  setContextInUpdate(detailNode.contextIn)
  setContextOutUpdate(detailNode.contextOut)
  setOpen(true);
  setLinearProgress(false)
}

