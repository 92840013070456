import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'inherit',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
    justifyContent: 'space-between',
  },
  item: {
    marginRight: theme.spacing(2),
  },
}));

export default useStyles;