import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Input from './Input';
import { Box, Chip, Fab} from '@material-ui/core';
import useStyles from './styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {findListEntitiesDialogFlow} from '../../../Service/entities/EntitiesDialogFlowConsumeService';
import {findAllEntitiesDatabase} from '../../../Service/entities/EntitiesDataBaseConsumeService';

const ListEntry = ({ name, setName }) => {
  const classes = useStyles();
  const [synonimus, setSynonimus] = useState([]);
  const [, setSynonimus1] = useState([]);
  const [, setSynonimus2] = useState([]);
  const [, setSynonimus3] = useState([]);
  const [, setSynonimus4] = useState([]);
  const arrayItem = [
    setSynonimus1,
    setSynonimus2,
    setSynonimus3,
    setSynonimus4,
  ];
  /*const arraySynonyms = [
    { label: 'Entidad' },
    { label: 'Cosa' },
    { label: 'Objeto' },
    { label: 'Abstraccion' },
  ];
  */

  const entities = async () => {
    const response = await findListEntitiesDialogFlow();

    const response2 = await findAllEntitiesDatabase();
    // arrayItem.push(response2)
    //setSynonimus(response2)

    console.log(response2)
  //   let listJsonIntent = new Array();
  //   response.forEach(function(element) {
  //     let jsonObject = listIntentsConvertToJson(element);
  //     listJsonIntent.push(jsonObject);
  //   // console.log(response)
  //   // arrayItem.push(response)
  //   setSynonimus(listJsonIntent)

    console.log(JSON.parse(JSON.stringify(response)))
  // });
  // findListEntitiesDialogFlow().then((response) => {
  //   let listJsonIntent = new Array();
  //   response.forEach(function(element) {
  //     let jsonObject = listIntentsConvertToJson(element);
  //     listJsonIntent.push(jsonObject);
  //   });
  //   setSynonimus(listJsonIntent ? listJsonIntent : []);
  // });
  console.log(synonimus)
}
  useEffect(() => {
    entities();
  }, []);

  const listIntentsConvertToJson = (element) => {

    console.log(element)
    let jsonObject = JSON.parse(JSON.stringify(element.json_intent));
    return JSON.parse(jsonObject);
  };


  return (
    <List component='nav' className={classes.root} aria-label='contacts'>
      {arrayItem.map((item, index) => (
         <li key={index}>
        <ListItem button className={classes.item}>
          <Input
            name={item}
            setName={item}
            placeholder='Valor de Referencia'
            fullWidth={false}
          />
          <Box
            display='flex'
            flexWrap='wrap'
            p={1}
            m={1}
            bgcolor='background.paper'
            className={classes.box}
          >
            {synonimus.map((item2) => (
              
              <Chip label={item2.label}></Chip>
              
            ))}
            <Input
              name={name}
              setName={setName}
              placeholder='Introduce el sinónimo'
              fullWidth={true}
            />
          </Box>

          <Box p={2} flex={'auto'} paddingTop={5}>
                          <Fab
                            // onClick={() => {
                            //   handleOpen();
                            //   handleEditIntent(item);
                            //   setMessagesUpdate(item.messages)
                            //   setPhraseUpdate(item.trainingPhrasesParts)
                            //   console.log(item)
                            //   //console.log(phraseUpdate)
                            // }}
                            size='small'
                            //className={styles.fabEdit}
                            aria-label='Editar'
                          >
                            <EditIcon />
                          </Fab>
                        </Box>
                        <Box p={2} flex={'auto'} paddingTop={5}>
                          <Fab
                            size='small'
                            color='secondary'
                            aria-label='Borar'
                            // onClick={() =>
                            //   advertDelete(item, [dataIntent, setDataIntent])
                            // }
                          >
                            <DeleteIcon />
                          </Fab>
                        </Box>
                    
        </ListItem>
        </li>
      ))}
    </List>
  );
};

export default ListEntry;
