import React from 'react';
import Typography from '@material-ui/core/Typography';

export const ParameterConectionCard = (props) => {
  let { classes, state } = props;
  let {
    bot_name,
    sip_address,
    sip_extension,
    name,
  } = state.location.state.bot;
  return (
    <>
      <Typography component="h2" className={classes.typografyTitle}>
        Nombre Bot:
      </Typography>
      <Typography component="h2" className={classes.typografyInfo}>
        {bot_name}
      </Typography>
      <Typography component="h2" className={classes.typografyTitle}>
        SIP Address:
      </Typography>
      <Typography component="h2" className={classes.typografyInfo}>
        {sip_address}
      </Typography>
      <Typography component="h2" className={classes.typografyTitle}>
        SIP Extensión:
      </Typography>
      <Typography component="h2" className={classes.typografyInfo}>
        {sip_extension}
      </Typography>
      
      <Typography component="h2" className={classes.typografyTitle}>
        Subscripción:
      </Typography>
      <Typography component="h2" className={classes.typografyInfo}>
        {name}
      </Typography>
    </>
  );
};
