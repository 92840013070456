import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    width: 'inherit',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
    justifyContent: 'center',
  },
}));

export default useStyles;