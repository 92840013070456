import React from 'react';
import Entities from './components/Entities/Entities';
import { Route, Switch, BrowserRouter, useHistory } from 'react-router-dom';
import AppBarAssistant from './components/appBar';
import SignInForm from './components/form/SignInForm/index';
import SignUpForm from './components/form/SignUpForm/index';
import Forget from './components/forget/index';
import Training from './components/Nodes/index';
import SimpleCalls from './components/call/simpleCalls/simpleCalls';
import TableBasicUserInformation from './components/tableBasicUserInformation';
import LoadTreeNodes from './components/Nodes/loadTreeNodes';
import CallsProcessed from './components/ProcessedCalls/ProcessedCalls';
import CallsFfective from './components/EffectiveCalls/EffectiveCalls';
import ListNodes from './components/Nodes/listNodes';
import PeriodicCalls from './components/call/periodicCalls/periodicCalls';
import Account from './pages/AccountPage/Account';
import UserPage from './pages/UserPage/User';
import SuperAdmin from './pages/superAdmin';
import ReportByBot from './pages/ReportByBot';
import LandingPage from './components/landingPage';
import { informationUserDatabase } from './Service/platformUser/PlatformUserConsumeService';
import { Auth } from 'aws-amplify';
import {
  LANDING_PAGE_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  HOME_ROUTE,
  HOME_ADMIN_ROUTE,
  PASSWORD_RESET_ROUTE,
  REPORT_BYBOT_ROUTE,
  ACCOUNT_ROUTE,
  USER_ROUTE,
  TREE_ROUTE,
  START_CALL_ROUTE,
  PERIODIC_CALLS_ROUTE,
  TRAINING_ROUTE,
  NODES_ROUTE,
  ENTITIES_ROUTE,
  CALLS_PROCESED_ROUTE,
  CALLS_EFECTIVES_ROUTE,
  CONVERSATION_ROUTE,
} from './constants/Routes/constantsRoutes';

const routes = [
  {
    path: LANDING_PAGE_ROUTE,
    exact: true,
    component: () => <LandingPage />,
  },
  {
    path: LOGIN_ROUTE,
    exact: true,
    component: () => <SignInForm />,
  },
  {
    path: REGISTER_ROUTE,
    exact: true,
    component: () => <SignUpForm />,
  },
  {
    path: PASSWORD_RESET_ROUTE,
    exact: true,
    component: () => <Forget />,
  },
  {
    path: HOME_ROUTE,
    exact: true,
    component: () => <LoadTreeNodes />,
  },
  {
    path: HOME_ADMIN_ROUTE,
    exact: true,
    component: () => <SuperAdmin />,
  },
  {
    path: REPORT_BYBOT_ROUTE,
    exact: true,
    component: () => <ReportByBot />,
  },
  {
    path: ACCOUNT_ROUTE,
    exact: true,
    component: () => <Account />,
  },
  {
    path: USER_ROUTE,
    exact: true,
    component: () => <UserPage />,
  },
  {
    path: TREE_ROUTE,
    component: () => <LoadTreeNodes />,
  },
  {
    path: START_CALL_ROUTE,
    component: () => <SimpleCalls />,
  },
  {
    path: PERIODIC_CALLS_ROUTE,
    component: () => <PeriodicCalls />,
  },
  {
    path: TRAINING_ROUTE,
    component: () => <Training />,
  },
  {
    path: NODES_ROUTE,
    component: () => <ListNodes />,
  },
  {
    path: ENTITIES_ROUTE,
    component: () => <Entities />,
  },
  {
    path: CALLS_PROCESED_ROUTE,
    component: () => <CallsProcessed />,
  },
  {
    path: CALLS_EFECTIVES_ROUTE,
    component: () => <CallsFfective />,
  },
  {
    path: CONVERSATION_ROUTE,
    component: () => <TableBasicUserInformation />,
  },
];

const RouteWithSubRoutes = (route) => {
  let history = useHistory();
  const [verified, setVerified] = React.useState(false);
  const idCognito = localStorage.getItem('idCognito');
  const validationAccount = async ({ history }) => {
    try {
      const data = await Auth.currentSession();
      const email = data?.idToken?.payload.email;
      const emailDB = await informationUserDatabase(idCognito);
      if (email/*  === emailDB.email */) {
        setVerified(true);
      } else {
        history.push(LOGIN_ROUTE);
      }
    } catch (error) {
      console.log('Error:::', error);
    }
  };

  React.useEffect(() => {
    validationAccount({ history });
  }, [history]);

  return (
    <>
      {verified && history.location.pathname !== LANDING_PAGE_ROUTE ? (
        <AppBarAssistant />
      ) : (
        undefined
      )}
      {verified && history.location.pathname === LANDING_PAGE_ROUTE
        ? history.push(HOME_ROUTE)
        : undefined}
      <Route
        path={route.path}
        render={(props) => {
          return <route.component {...props} routes={route.routes} />;
        }}
      />
    </>
  );
};

const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </BrowserRouter>
  );
};

export default RoutesApp;
