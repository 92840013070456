import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const Graph = ({ data, legend }) => {
  return (
    <>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
        justify='center'
      >
        <Legend />
        <CartesianGrid strokeDasharray='4 4' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Bar dataKey={legend.llamadasEfectivas} fill='#5891AD' />
        <Bar dataKey={legend.llamadasRecibidas} fill='#004561' />
      </BarChart>
    </>
  );
};

export default Graph;
