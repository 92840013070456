import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

export default function SimpleModal({ open, resp, close }) {
  return (
    <div>
      <Dialog open={open} onClose={close} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>{resp.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>{resp.description}</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
