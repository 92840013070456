import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Button } from '@material-ui/core';
import useStyles from './styles';

const ButtonLoading = ({ loading, label, colorButton, sizeProgress }) => {
  const classes = useStyles();

  return (
    <button
    className={classes.buttonLogin}
      disabled={loading}
      // type='submit'
    >
      {loading ? (
        <div className={classes.container_button}>
          <CircularProgress size={sizeProgress} />
          <span className={classes.title}>{label}</span>
        </div>
      ) : (
        label
      )}
    </button>
  );
};

ButtonLoading.defaultProps = {
  sizeProgress: 20,
  colorButton: 'primary',
  loading: false,
  label: '',
};

ButtonLoading.propTypes = {
  sizeProgress: PropTypes.number,
  colorButton: PropTypes.string,
  loading: PropTypes.bool,
  label: PropTypes.string,
};

export default ButtonLoading;
