import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '30ch',
    },
  },
  rootCardGratis: {
    color: '#e55959',
    width: '125px',
    border: '4px solid #1b3c5a',
    height: '120px',
    margin: '1em',
    padding: '1em',
    borderRadius: '25px',
  },
  rootCardOro: {
    color: '#e55959',
    width: '125px',
    border: '4px solid #1b3c5a',
    height: '120px',
    margin: '1em',
    padding: '1em',
    borderRadius: '25px',
  },
  rootCardPlata: {
    color: '#e55959',
    width: '125px',
    border: '4px solid #1b3c5a',
    height: '120px',
    margin: '1em',
    padding: '1em',
    borderRadius: '25px',
  },
  rootCardBronce: {
    color: '#e55959',
    width: '125px',
    border: '4px solid #1b3c5a',
    height: '120px',
    margin: '1em',
    padding: '1em',
    borderRadius: '25px',
  },
  Container: {
    display: 'grid',
    marginTop: '15px',
    justifyItems: 'center',
    gridAutoRows: 'auto',
    gridTemplateRows: 'repeat(4,1fr)',
    gridTemplateColumns: 'repeat(3,1fr)',
  },
  Container__user: {
    display: 'grid',
    gridColumn: '2',
    gridRow: 'span 1',
    alignItems: 'center',
    gridGap: '1rem',
  },
  Container__password: {
    display: 'grid',
    gridGap: '1rem',
    gridRow: 'span 2',
    alignItems: 'center',
    gridColumn: 'span 3',
    marginTop: '-15px',
  },
  Container__package: {
    display: 'flex',
    marginTop: '-175px',
    alignItems: 'center',
    gridColumn: '2',
    justifyItems: 'center',
  },
  textTitle: {
    color: '#1b3c5a',
    textAlign: 'center',
  },
  imgGratis: {
    padding: '25px',
    maxWidth: '52px',
    marginTop: '-10px',
    marginLeft: '10px',
  },
  SetUpButton: {
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    height: '35px',
    margin: '0px 55px',
    marginTop: '20px',
    padding: '0px 25px',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Roboto Condensed',
    fontWeight: '300',
    borderRadius: '25px',
    backgroundColor: '#e55959',
    '&:hover': {
      backgroundColor: '#e55959',
      filter: 'brightness(70%)',
    },
  },
  hrCuenta: {
    width: '315%',
    marginTop: '16px',
    marginLeft: '-105%',
  },
  hrPassword: {
    width: '155%',
    marginTop: '-176px',
    marginLeft: '-150px',
  },
  title: {
    width: 'max-content',
  },
  titleGratis: {
    fontSize: '20px',
    marginTop: '5px',
    textAlign: 'center',
  },
  titleOro: {
    fontSize: '20px',
    marginTop: '5px',
    textAlign: 'center',
  },
  titlePlata: {
    fontSize: '20px',
    marginTop: '5px',
    textAlign: 'center',
  },
  titleBronce: {
    fontSize: '20px',
    marginTop: '5px',
    textAlign: 'center',
  },
  ButtonPasswordSave: {
    color: 'white',
    width: 'fitContent',
    border: 'none',
    cursor: 'pointer',
    height: '35px',
    padding: '0px 25px',
    fontSize: '20px',
    marginBlockEnd: '40px',
    marginTop: '-75px',
    textAlign: 'center',
    fontFamily: 'Roboto Condensed',
    fontWeight: '300',
    justifySelf: 'center',
    borderRadius: '25px',
    backgroundColor: '#e55959',
    '&:hover': {
      backgroundColor: '#e55959',
      filter: 'brightness(70%)',
    },
  },
  buttonSubscription: {
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    height: '33px',
    padding: '0px 15px',
    fontSize: '20px',
    marginTop: '35px',
    textAlign: 'center',
    fontFamily: 'Roboto Condensed',
    fontWeight: '300',
    borderRadius: '25px',
    backgroundColor: '#e55959',
    '&:hover': {
      backgroundColor: '#e55959',
      filter: 'brightness(70%)',
    },
  },
}));

export default useStyles;
