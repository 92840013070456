import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 270,
    // backgroundColor: '#e55959',
  },
  menu: {
    textDecoration: 'none',
    color: '#333',
  },
  subMenu: {
    paddingLeft: theme.spacing(4),
  },
}));

export default useStyles;
