import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React from 'react';
import useStyles from './styles';
const InputPasswordNew = ({ onChange, onClick, visibility }) => {
  const styles = new useStyles();
  return (
    <div  className={styles.containerInput}>
      <input
        className={styles.inputPaswordNew}
        type={visibility ? 'text' : 'password'}
        name='password'
        id='password'
        placeholder='Contraseña*'
        onChange={onChange}
        pattern=".{6,}"
      />
      <InputAdornment position='end'>
        <IconButton onClick={onClick} className={styles.iconEye} >
          {visibility ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    </div>
  );
};

export default InputPasswordNew;
