import React from 'react';
import { Link } from 'react-router-dom';
import ButtonLoading from '../../commons/buttonLoading/ButtonLoading';
import LogoTipoTelliou from '../../assets/img/logotipo_telliou.png';
const ForgetPassword = ({
  handleSubmit,
  classes,
  setEmail,
  loading,
  error,
  errorEmail,
}) => (
  <form
    className={classes.container}
    onSubmit={handleSubmit}
    noValidate
    autoComplete='off'
  >
    <div className={classes.containerResetPassword}>
      <div className={classes.resetPassword}>
        <div className={classes.resetPasswordTitle}>
          <img
            src={LogoTipoTelliou}
            alt='Logo Telliou'
            className={classes.imgTelliou}
          />
          <h3>¿Olvidaste tu contraseña?</h3>
        </div>

        {/* <InputEmail
          helperText={error || errorEmail}
          onChange={(e) => setEmail(e.target.value)}
          label='Ingresa tu correo electrónico *'
          error={Boolean(error) || Boolean(errorEmail)}
        /> */}
        <input
          className={classes.inputResetPassword}
          type='email'
          name='correo'
          id=''
          placeholder='Ingresa tu correo electrónico*'
          onChange={(e) => setEmail(e.target.value)}
        />

        <div className={classes.container_button}>
          <ButtonLoading loading={loading} label='Restablecer Contraseña' />
        </div>
        <div className={classes.container_options}>
          <h3>¿Ya tienes usuario y contraseña?</h3>
          <Link to='/login' className={classes.sessionAccountLink}>
            Iniciar sesión
          </Link>
        </div>
      </div>
    </div>
  </form>
);

export default ForgetPassword;
