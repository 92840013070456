import React, { useState } from 'react';
import useStyles from './styles';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

function GroupCheckbox() {
  const classes = useStyles();

  const [value, setValue] = useState('synonymus');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <>
      <FormControl component='fieldset' className={classes.formControl}>
        <RadioGroup
          className={classes.formGroup}
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value='synonymus'
            control={<Radio />}
            label='Definir sinónimo'
          />
          {/* <FormControlLabel
            value='regexp'
            control={<Radio />}
            label='Regexp entity'
          />
          <FormControlLabel
            value='allowExpansion'
            control={<Radio />}
            label='Allow atomated expansion'
          />
          <FormControlLabel
            value='fuzzyMatching'
            control={<Radio />}
            label='Fuzzy matching'
          /> */}
        </RadioGroup>
      </FormControl>
    </>
  );
}

export default GroupCheckbox;
