import { findIntentsByIdAccount} from '../../Service/tree/TreeDataBaseConsumeService';
import React, { useState, useEffect } from 'react';
import TreeIntents from '../Tree/TreeIntents';
import { intentWelcome } from '../Tree/Views/IntentWelcome';
import LinearProgress from '@material-ui/core/LinearProgress';

let jsonObject = null;

const LoadTreeNodes = (treeIdAccount) => {
  let id_account2;

  const id_account1 = localStorage.getItem('idAccount');
  const { id_account } = treeIdAccount;
  if (id_account1 === 'e2651393-36d1-41d0-93c6-f08112613c52') {
    id_account2 = id_account;
  } else {
    id_account2 = id_account1;
  }

  const [displayName, setDisplayName] = useState('');
  const [progressBtn, setProgressBtn] = useState(false);
  const [textValidation, setTextValidation] = useState('');
  const [intents, setIntents] = useState(null);
  const [reload, setReload] = useState('');
  const [linearProgress,] = useState(false);

  useEffect(() => {
    findIntentsByIdAccount(id_account2)
      .then(response => {
        if (response.length === 0) {
          setIntents(null)
        }
        else {
          setIntents(response)
        }
      })
  }, [reload]);

  const resIntent = () => {
    if (intents !== null) {
     let json = JSON.stringify(intents)
        .replaceAll(null + ',', '')
        .replaceAll(',' + null, '')
        .replaceAll(null, '');
      jsonObject = JSON.parse(json);
      return jsonObject;
    }
    return null;
  };
  return (
    <div id='tree'>
      {resIntent() !== null ? (
        <div style={{ height: '100vh' }}>
          {linearProgress ? <LinearProgress color='secondary' /> : null}
          <TreeIntents data={jsonObject} state={setReload}></TreeIntents>
        </div>
      ) : (
        intentWelcome(
          setDisplayName,
          progressBtn,
          textValidation,
          displayName,
          setProgressBtn,
          setTextValidation,
          setReload,
        )
      )}
    </div>
  );
};
export default LoadTreeNodes;
