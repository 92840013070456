export const fieldValidationLogic =(setProgreso, setValidationInsert, title, setStateValidationInsert,messages,trainingPhrasesParts,initialStatePhrases,typeIntent,previousIntentType,method) => {

    const expressions = {
        
        intentionName: /^(?!\s)([a-zA-ZÀ-ÿ0-9]\s?)+$/, // permitir letras, números, no caracteres especiales ni espacios en blanco al inicio y al final
        messages: /^(?!\s)(?!\.)(?!\,)(?!\?)([a-zA-ZÀ-ÿ0-9.,?¿]\s?)+$/, // permitir letras, numeros y (,.?¿#) para mensajes
        phrases: /^(?!\s)(?!\,)([a-zA-ZÀ-ÿ0-9,]\s?)+$/,
        number: /^(?!\s)(?!\,)([0-9/,]+\s?)+$/ // permitir solo números
    };
    
    setProgreso(true)
    setValidationInsert("")

    console.log("typeIntent"+typeIntent)
    console.log("previous"+previousIntentType)
    if (title === "" && typeIntent!=="2") {
        setValidationInsert("El nombre del nodo no puede estar vacío")
        setStateValidationInsert(false)
        setProgreso(false)
        return false;

    } else if (title.length > 20 || title.length < 1 && typeIntent!=="2") {
        setValidationInsert("El nombre del nodo debe contener menos de 20 caracteres")
        setStateValidationInsert(false)
        setProgreso(false)
        return false;

    } else if (!expressions.intentionName.test(title)&& typeIntent!=="2") {
        setValidationInsert("El nombre del nodo no puede tener caracteres especiales ni espacios en blanco al inicio, ni espacios dobles")
        setStateValidationInsert(false)
        setProgreso(false)
        return false;

    } else if (messages.length ===0 || messages[0]==="") {
        setValidationInsert("El mensaje no puede estar vacío")
        setStateValidationInsert(false)
        setProgreso(false)
        return false;

    }else if(!expressions.messages.test(messages)){
        setValidationInsert("El mensaje no puede tener caracteres especiales ni espacios en blanco al inicio, ni espacios dobles")
        setStateValidationInsert(false)
        setProgreso(false)
        return false;

    } else if (trainingPhrasesParts.length === 0 && typeIntent!=="2") {
        setValidationInsert("La frase de entrenamiento no puede estar vacío")
        setStateValidationInsert(false)
        setProgreso(false)
        return false;

    } else if(!expressions.phrases.test(trainingPhrasesParts) && initialStatePhrases){
        setValidationInsert("La frase de entrenamiento no puede tener caracteres especiales ni espacios en blanco al inicio, ni espacios dobles")
        setStateValidationInsert(false)
        setProgreso(false)
        return false;
    }else if(!expressions.number.test(trainingPhrasesParts) && typeIntent === "3" && method ==="save"){
        setValidationInsert("La frase de entrenamiento solo puede tener valores númericos")
        setStateValidationInsert(false)
        setProgreso(false)
        return false;
    }else if(!expressions.number.test(trainingPhrasesParts) && previousIntentType === "3" && method==="update"){
        setValidationInsert("La frase de entrenamiento solo puede tener valores númericos.")
        setStateValidationInsert(false)
        setProgreso(false)
        return false;
    }else{
        return true;  
    }
}