import React from 'react';
import useStyles from './styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

const ModalForm = ({
  open,
  handleClose,
  requestData,
  setRequestData,
  listVoices,
  setListVoices,
  handleSend,
  handleChangeBotVoice,
  botVoice,
}) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          Parametros de configuración BOT
        </DialogTitle>
        <DialogContent className={classes.conteiner} item={true}>
          <DialogContentText>
            A continuación debera configurar su Bot con algunos parametros
            escenciales para su funcionamiento.
          </DialogContentText>
          <TextField
            defaultValue={requestData.bot_name}
            autoFocus
            id={requestData.bot_name}
            label='Nombre del bot'
            type='text'
            fullWidth
            variant='filled'
            onChange={(e) => {
              setRequestData({
                ...requestData,
                bot_name: e.target.value,
              });
            }}
          />
          <TextField
            defaultValue={requestData.sip_address}
            autoFocus
            id={requestData.sip_address}
            label='Dirección SIP'
            type='text'
            fullWidth
            variant='filled'
            onChange={(e) => {
              setRequestData({
                ...requestData,
                sip_address: e.target.value,
              });
            }}
          />
          <TextField
            defaultValue={requestData.sip_extension}
            autoFocus
            id={requestData.sip_extension}
            label='Extensión del bot'
            type='text'
            fullWidth
            variant='filled'
            onChange={(e) => {
              setRequestData({
                ...requestData,
                sip_extension: e.target.value,
              });
            }}
          />

          <label>Voz del bot</label>
          <Select
            id='listVoices'
            defaultValue={requestData.id_voice}
            fullWidth
            autoFocus
            onChange={(e) => {
              setRequestData({
                ...requestData,
                id_voice: e.target.value,
              });
              handleChangeBotVoice(e.target.value);
            }}
          >
            {listVoices.voicesResponse.map((element) => (
              <MenuItem key={element.id} value={element.id ?? ''}>
                {element.name ?? ''}
              </MenuItem>
            ))}
          </Select>

          <div className={classes.voiceContainer}>
            <audio
              className={classes.voiceContainer}
              src={botVoice}
              controls
            ></audio>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSend} color='primary'>
            Guardar
          </Button>
          <Button onClick={handleClose} color='primary'>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalForm;
