import React from 'react';
import Button from '@material-ui/core/Button';
import useStyles from './styles';

function ButtonComponent({name, setName}) {
  const styles = useStyles();

  const saveEntity = async () => {

    console.log(name)
   
  }

  return (
    <>
      <Button 
      variant='contained' 
      className={styles.button}
      onClick={saveEntity}
      >
        Guardar
      </Button>
    </>
  );
}

export default ButtonComponent;
