import React from 'react';
import {
  Icon,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import clsx from 'clsx';
import Search from '@material-ui/icons/Search';

const InputSearch = ({
  value,
  onChange,
  classes,
  label,
  widthInput,
  widthContainer,
}) => (
  <FormControl
    className={clsx(classes.margin)}
    variant='outlined'
    style={{ width: widthContainer }}
  >
    <InputLabel htmlFor='search'>{label}</InputLabel>
    <OutlinedInput
      autoComplete='off'
      labelWidth={70}
      onChange={onChange}
      style={{ width: widthInput }}
      label={label}
      value={value}
      id='search'
      endAdornment={
        <InputAdornment position='start'>
          <Icon style={{ marginLeft: 6 }}>
            <Search style={{ color: '#aaaaaa' }} />
          </Icon>
        </InputAdornment>
      }
    />
  </FormControl>
);

export default InputSearch;
