import React, { useState } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PhoneIcon from '@material-ui/icons/Phone';
import DialerSipIcon from '@material-ui/icons/DialerSip';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import { Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BarChartIcon from '@material-ui/icons/BarChart';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import SmsIcon from '@material-ui/icons/Sms';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import TimelineSharpIcon from '@material-ui/icons/TimelineSharp';
import TuneSharpIcon from '@material-ui/icons/TuneSharp';
import useStyles from './styles';
import MenuTelliou from '../../assets/img/menu.png';
import { START_CALL_ROUTE, PERIODIC_CALLS_ROUTE, CONVERSATION_ROUTE, ENTITIES_ROUTE, CALLS_PROCESED_ROUTE, CALLS_EFECTIVES_ROUTE, TREE_ROUTE, NODES_ROUTE } from '../../constants/Routes/constantsRoutes';

export default function SwipeableTemporaryDrawer() {
  const array = [
    {
      title: 'Realizar llamada',
      route: START_CALL_ROUTE,
      icon: <PhoneIcon />,
    },
    {
      title: 'Llamadas Periodicas',
      route: PERIODIC_CALLS_ROUTE,
      icon: <DialerSipIcon />,
    },
    {
      title: 'Análisis conversaciones',
      route: CONVERSATION_ROUTE,
      icon: <PhoneIcon />,
    },
  ];
  const training = [
    {
      title: 'Entidades',
      route: ENTITIES_ROUTE,
      icon: <AccountTreeIcon />,
    },
  ];
  const analytics = [
    {
      title: 'Llamadas procesadas',
      route: CALLS_PROCESED_ROUTE,
      icon: <SmsIcon />,
    },
    {
      title: 'Llamadas efectivas',
      route: CALLS_EFECTIVES_ROUTE,
      icon: <AccountTreeIcon />,
    },
  ];
  const configurationBot = [
    {
      title: 'Árbol',
      route: TREE_ROUTE,
      icon: <TimelineSharpIcon />,
    },
    {
      title: 'Nodos',
      route: NODES_ROUTE,
      icon: <FiberManualRecordIcon />,
    },
  ];

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleMore = () => {
    setOpen(!open);
  };
  const handleMore1 = () => {
    setOpen1(!open1);
  };
  const handleMore2 = () => {
    setOpen2(!open2);
  };

  const classes = useStyles();
  const [state, setState] = useState({
    left1: false,
  });

  const toggleDrawer = (side, open3) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [side]: open3 });
  };

  const sideList = (side) => (
    <div className={classes.list} role='presentation'>
      <List
        onClick={toggleDrawer(side, false)}
        onKeyDown={toggleDrawer(side, false)}
      >
        {array.map((text, index) => (
          <Link to={text.route} className={classes.menu} key={text.title}>
            <ListItem button>
              <ListItemIcon>
                {text.icon}
                {/* {index % 2 === 0 ? <PhoneIcon /> : <DialerSipIcon />} */}
              </ListItemIcon>
              <ListItemText primary={text.title} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={handleMore}>
          <ListItemIcon>
            <TuneSharpIcon />
          </ListItemIcon>
          <ListItemText primary='Configuración Bot' />
          {open ? <ExpandLess /> : <ExpandMore />}
          {console.log(open)}
        </ListItem>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List
            component='div'
            disablePadding
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, true)}
          >
            {configurationBot.map((text, index) => (
              <Link to={text.route} className={classes.menu} key={index}>
                <ListItem button className={classes.subMenu}>
                  <ListItemIcon>{text.icon}</ListItemIcon>
                  <ListItemText primary={text.title} />
                </ListItem>
              </Link>
            ))}
          </List>
        </Collapse>
      </List>
      <List>
        <ListItem button onClick={handleMore1}>
          <ListItemIcon>
            <FitnessCenterIcon />
          </ListItemIcon>
          <ListItemText primary='Entrenamiento' />
          {open1 ? <ExpandLess /> : <ExpandMore />}
          {console.log(open1)}
        </ListItem>
        <Collapse in={open1} timeout='auto' unmountOnExit>
          <List
            component='div'
            disablePadding
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, true)}
          >
            {training.map((text, index) => (
              <Link to={text.route} className={classes.menu} key={index}>
                <ListItem button className={classes.subMenu}>
                  <ListItemIcon>{text.icon}</ListItemIcon>
                  <ListItemText primary={text.title} />
                </ListItem>
              </Link>
            ))}
          </List>
        </Collapse>
      </List>
      <Divider />

      <List>
        <ListItem button onClick={handleMore2}>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary='Analítica' />
          {open2 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open2} timeout='auto' unmountOnExit>
          <List
            component='div'
            disablePadding
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, true)}
          >
            {analytics.map((text, index) => (
              <Link to={text.route} className={classes.menu} key={index}>
                <ListItem button className={classes.subMenu}>
                  <ListItemIcon>{text.icon}</ListItemIcon>
                  <ListItemText primary={text.title} />
                </ListItem>
              </Link>
            ))}
          </List>
        </Collapse>
      </List>
    </div>
  );

  return (
    <div className={classes.menuHamburger}>
      <IconButton
        edge='start'
        color='inherit'
        aria-label='menu'
        onClick={toggleDrawer('left', true)}
      >
        {/* <MenuIcon /> */}
        <img src={MenuTelliou} alt='Menu Telliou' />
      </IconButton>

      <SwipeableDrawer
        className={classes.hamburger}
        open={state.left}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        {sideList('left')}
      </SwipeableDrawer>
    </div>
  );
}
