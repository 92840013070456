import React from 'react';
import Nodes from './nodes';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';

function ListNodes() {
  const styles = useStyles();

  return (
    <Grid container className={styles.List} sparcing={2}>
      <Grid item xs={10}>
        <Typography className={styles.textTitle} variant='h3'>
          Lista de nodos
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Nodes />
      </Grid>
    </Grid>
  );
}

export default ListNodes;
