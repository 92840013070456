import React, { useState,useEffect } from 'react';
import useStyles from './styles';
import {
  multiCalls,
  addScheduledMultiCalls,
  cancelCall,
  addCallScheduled,
  callUserNow,
  listScheduledCallsByIdAndState,
} from '../../../Service/calls/CallsConsumeService';
import { DateTimePicker } from '@material-ui/pickers';
import {
  Grid,
  Paper,
  TextField,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
} from '@material-ui/core';
import CSVReader from 'react-csv-reader';

const addScheduledCall = async (phoneNumber, id_account, fechaProgramacion) => {
  let hoy = new Date();

  const scheduled = {
    id: '',
    account_id: id_account,
    phoneNumber: phoneNumber,
    scheduledCallTime: fechaProgramacion,
    state: 'scheduled',
    createAt: hoy,
    updateAt: null,
    task: 'llamar',
  };

  await addCallScheduled(scheduled);
};

const quitarProgramacion = async (idScheduledCall) => {
  await cancelCall(idScheduledCall);
};

export default function SimpleCalls() {
  const idAccount = localStorage.getItem('idAccount');
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState();
  const [fechaProgramacion, cambiarFechaSeleccionada] = useState(null);
  const [scheduledCall, setScheduledCall] = useState(false);
  const [loadCsvFile, setloadCsvFile] = useState(false);
  const [scheduledList, setScheduledRows] = useState([]);
  const regex = /^[0-9]*$/;

  // Listar scheduled calls al cargar la pagina
  useEffect(() => {
    async function listFirstScheduledCalls(){
      const scheduledCalls = await listScheduledCallsByIdAndState(idAccount,'scheduled');
      setScheduledRows(scheduledCalls);
    }
    listFirstScheduledCalls();
  }, []);

  //Listar llamadas por los estados
  const listScheduledCalls = async () => {
    const scheduledCalls = await listScheduledCallsByIdAndState(idAccount,'scheduled');
    setScheduledRows(scheduledCalls);
  };

  const listExecutedCalls = async () => {
    const scheduledCalls = await listScheduledCallsByIdAndState(idAccount,'executed');
    setScheduledRows(scheduledCalls);
  };

  const listDeletedCalls = async () => {
    const scheduledCalls = await listScheduledCallsByIdAndState(idAccount,'deleted');
    setScheduledRows(scheduledCalls);
  };

  /*Validaciones para valores numericos y de fecha*/
  const numberValidation = (e) => {
    if(!regex.test(e)){
      alert("El numero ["+e+"] no es valido");
      return false;
    }else if(e.length>10 || e.length<10){
      alert("El numero ["+e+"] debe contener 10 digitos");
      return false;
    }
    return true;
  }

  const dateValidation = (e) => {
    let hoy = new Date();

    if(e < hoy){
      alert("La fecha de programación debe ser mayor a 5 minutos despues que la hora actual");
      return false;
    }else if(e > hoy){
      return true;
    }
  }

  /* Llamar al instante una o multiples llamadas */
  const callNow = async () => {
    let validate = numberValidation(phoneNumber);

    if(validate){
      if(loadCsvFile && !scheduledCall){
        const params = {
          phone: phoneNumber,
          task: 'llamar',
        };
        console.log(params);
        alert("ENTRO A LLAMDA NORMAL");
      
        await callUserNow(idAccount, params);
      }
      else if(loadCsvFile == true && !scheduledCall){
        alert("Se van a llamar a "+numbersPhone.length+" numeros del archivo->"+csvNameFile);
        console.log(JSON.stringify(numbersPhone));
        await multiCalls(idAccount, numbersPhone);
      }
    }else{
      alert("Error al llamar, el numero no es valido");
    }
  };

  /* Programar LLamada o multiples  llamadas */
  const callScheduled = async (e) => {
    e.preventDefault();

    let validateDate = dateValidation(fechaProgramacion);

    if(validateDate){
      if(loadCsvFile){
        let hoy = new Date();
  
        const multipleScheduledCalls = {
          phones: numbersPhone,
          task: 'llamar',
          scheduledCallTime: fechaProgramacion,
          createAt: hoy,
        };
        console.log(multipleScheduledCalls);
        alert("Se va a programar "+numbersPhone.length+" numeros del archivo->"+csvNameFile);
  
        await addScheduledMultiCalls(idAccount, multipleScheduledCalls);
        let scheduledCalls = await listScheduledCallsByIdAndState(idAccount,'scheduled');
        setScheduledRows(scheduledCalls);
      }else{
        let validateNumber = numberValidation(phoneNumber);
        if(validateNumber){
          alert("Se programo una llamda al numero-> "+phoneNumber);
    
          await addScheduledCall(phoneNumber, idAccount, fechaProgramacion);
          let scheduledCalls = await listScheduledCallsByIdAndState(idAccount,'scheduled');
          setScheduledRows(scheduledCalls);
        }
      }
    }else{
      alert("Error al programar la llamada");
    }
  };

  //Cancelar programacion de la llamada
  const quitarLlamadaProgramada = async (scheduledCall) => {
    window.confirm(
      'Quieres eliminar la llamada al numero' + scheduledCall.phoneNumber + '?'
    );

    await quitarProgramacion(scheduledCall.id);

    let scheduledCalls = await listScheduledCallsByIdAndState(idAccount,'scheduled');
    setScheduledRows(scheduledCalls);
  };

  //Traduccion del estado de las llamadas
  let traduccion = (state) => {
    if (state == 'scheduled') {
      return 'Programada';
    } else if (state == 'executed') {
      return 'Finalizada';
    } else if (state == 'deleted') {
      return 'Cancelada';
    }
  };

  //Funcion obtener el nombre del archivo CSV
  const [csvNameFile, setcsvNameFile] = useState();
  const getCsvNameFile = (csvName) => {
    if(csvName == undefined){
      return "";
    }else{
      return "El archivo cargado es->"+csvName;
    }
  };

  const [numbersPhone, setNumbersPhone] = useState({});
  const [textArea, setTextArea] = useState({
    value: '',
  });

  //Boton para importar archivo csv
  const handleOnFileCsv = async (data, fileInfo) => {
    let i = 0;
    let listNumbers = [];

    const accept = '.csv, text/csv, application/vnd.ms-excel';

    if (accept.indexOf(fileInfo.type) <= 0) {
      alert(new Error(`[strict mode] Accept type not respected: got '${fileInfo.type}' but not in '${accept}'`));
    }

    if(data.length>100){
      alert("El archivo CSV no puede tener mas de 100 numero");
      return null;
    }else{
      console.log("La data\n"+data);
      //Recorre los datos del archivo
      for(i; i < data.length; i++) {
        const onlyNumbers = regex.test(data[i]);
  
        //Filtrar campos con solo numeros y que no este vacio el campo
        if(onlyNumbers && data[i][0] !== '' && data[i][0].length == 10) {
          listNumbers.push(data[i].toString());
          
          setNumbersPhone(
            listNumbers
          );
  
          setcsvNameFile(fileInfo.name);
          setloadCsvFile(true);
        }else if(data[i][0].length < 7) {
          alert('El numero-> '+data[i][0]+' de la fila['+i+'] debe tener 10 digitos');
        }else if(!onlyNumbers) {
          alert('El dato-> '+data[i][0]+' de la fila['+i+'] no es un valor numerico valido');
        }
      }
      console.log(listNumbers);
    }
  };

  /*Paginacion de la tabla*/
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, scheduledList.length - page * rowsPerPage);

  return (
    <div className='call__box'>
      <Paper className={classes.paper}>
        {!loadCsvFile ? (
          <TextField
          required={true}
          id='standard-number'
          label='Ingrese número teléfono'
          className={classes.textField}
          margin='normal'
          InputProps={{ inputProps: { min: 0}, inputMode: 'numeric', pattern: '[0-9]*' }}
          InputLabelProps={{ shrink: true }}
          onChange={(evt) =>setPhoneNumber(evt.target.value)}
          />
        ) : (
          <div style={{ display: 'none' }}></div>
        )}

        {scheduledCall ? (
          <DateTimePicker
            label='yyyy-MM-dd h:mm'
            value={fechaProgramacion}
            minDate={new Date()}
            ampm={true}
            onChange={cambiarFechaSeleccionada}
            disablePast={true}
            format='yyyy-MM-dd h:mm'
            margin='normal'
          />
        ) : (
          <div style={{ display: 'none' }}></div>
        )}
        <Grid className={classes.grid}>
          <CSVReader 
            accept='.csv, text/csv, application/vnd.ms-excel'
            strict={true}
            onFileLoaded={handleOnFileCsv} 
            onError={(error) => alert("ERROR EL ARCIHIVO NO ES .CSV\n"+error)}
            inputStyle={{width: '0'}}
            label='Cargar Archivo .CSV'
            cssClass={classes.btnCSV}
            cssLabelClass={classes.labelbtnCSV}
          />

        <button
          className={classes.btn}
          onClick={() => {
            setScheduledCall(!scheduledCall);
            cambiarFechaSeleccionada(new Date()); 
          }}
        >
          {scheduledCall ? 'Quitar Fecha' : 'ProgramarLlamada'}
        </button>

        {!scheduledCall ? (
          <button className={classes.btn} onClick={callNow}>
            Llamar Ahora
          </button>
        ) : (
          <button className={classes.btn} onClick={callScheduled}>
            Programar Llamada(s)
          </button>
        )}
        </Grid>
      </Paper>

      <button className={classes.btn} onClick={listScheduledCalls}>
        Llamadas Programadas
      </button>

      <button className={classes.btn} onClick={listExecutedCalls}>
        Llamadas Ejecutadas
      </button>

      <button className={classes.btn} onClick={listDeletedCalls}>
        Llamadas Canceladas
      </button>

      <Container style={{ margin: 'auto' }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label='custom pagination table'>
            <TableHead style={{ backgroundColor: '#1b3c5a' }}>
              <TableRow>
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Numero de teléfono
                </TableCell>
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Fecha de Programación
                </TableCell>
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Hora y fecha a realizar llamada
                </TableCell>
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Estado
                </TableCell>
                <TableCell style={{ color: '#FFF' }} align='center'>
                  Eliminar programación
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scheduledList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((x) => (
                <TableRow>
                  <TableCell align='center'>{x.phoneNumber}</TableCell>
                  <TableCell align='center'>{x.createAt}</TableCell>
                  <TableCell align='center'>{x.scheduledCallTime}</TableCell>
                  <TableCell align='center'>{traduccion(x.state)}</TableCell>
                  <TableCell align='center'>
                    <button
                      className={classes.btn}
                      onClick={() => quitarLlamadaProgramada(x)}
                    >
                      Eliminar
                    </button>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={scheduledList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Container>
    </div>
  );
}
