import { makeStyles } from '@material-ui/core/styles';
import WaterMark from '../../assets/img/fondo_icono.jpg';
const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 12,
    maxWidth: 700,
    textAlign: 'center',
    marginLeft: '2px',
    marginBottom: 12,
    marginTop: '35px',
  },
  // containerWaterMark:{
  //   backgroundImage: `url(${WaterMark})`,
  //   objectFit: 'cover',
  //   backgroundRepeat: 'no-repeat',
  //   // backgroundColor: 'white',

  // },
  containerIntent: {
    width: '100%',
    display: 'block',
    height: '130px',
  },
  containerDetails: {
    float: 'left',
    width: '50%',
    marginRight: '1%',
    display: 'block',
    height: '130px',
  },
  containerMessage: {
    float: 'left',
    width: '18%',
    marginRight: '1%',
    display: 'block',
    height: '130px',
  },
  heading: {
    fontSize: '15px',
    color: '#1b3c5a',
    marginTop: '15px',
    textAlign: 'center',
    marginBottom: '0',
  },
  statLabel: {
    color: '#757575',
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: '500',
    margin: 'auto',
  },
  statLabel2: {
    color: '#757575',
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: '500',
    margin: 'auto',
  },
  statValue: {
    fontSize: 20,
    color: '#1b3c5a',
    fontWeight: 'bold',
    marginBottom: 4,
    letterSpacing: '1px',
  },

  button: {
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    height: '35px',
    padding: '0px 20px',
    fontSize: '20px',
    marginTop: '20px',
    fontFamily: 'Roboto Condensed',
    fontWeight: '300',
    borderRadius: '25px',
    backgroundColor: '#e55959',
    '&:hover': {
      backgroundColor: '#e55959',
      filter: 'brightness(70%)',
    },
  },
  fabEdit: {
    float: 'left',
    width: '18%',
    display: 'block',
    height: '130px',
  },
  imgEdit: {
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(70%)',
    },
  },
  List: {
    backgroundImage: `url(${WaterMark})`,
    backgroundColor: 'white',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    // marginTop: '30px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textTitle: {
    color: '#1b3c5a',
    marginTop: '50px',
    marginRight: '55px',
  },
}));

export default useStyles;
