import axios from 'axios';

import {UriConfiguration}  from '../configuration/ApiServices'
let baseURL = UriConfiguration();

/*Llamadas instantaneas */
export const callUserNow = async (id, data) => {
  try {
    return await axios
      .post(`${baseURL}/CallOutJVoIPController/callUserNow/${id}`, data)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error service call SDK Mizutech', { error });
  }
};

export const multiCalls = async (id, data) => {
  try {
    return await axios
      .post(`${baseURL}/CallOutJVoIPController/callMizutechMultiCall/${id}`, data)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error service call SDK Mizutech', { error });
  }
};
/*FIN Llamadas instantaneas */

/*Llamadas programadas */
export const addCallScheduled = async (scheduled) => {
  try {
    return await axios
      .post(`${baseURL}/ScheduledCallControllerNew`, scheduled)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error service call SDK Mizutech', { error });
  }
};

export const addScheduledMultiCalls = async (id,data) => {
  try {
    return await axios
      .post(`${baseURL}/ScheduledCallControllerNew/ScheduledMultiCalls/${id}`, data)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error service call SDK Mizutech', { error });
  }
};

export const listScheduledCallsByIdAndState = async (idAccount, state) => {
  try {
    return await axios
      .get(`${baseURL}/ScheduledCallControllerNew/listScheduledCallsByIdAndState/${idAccount}/${state}`)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error list Scheduled Calls', { error });
  }
};

export const cancelCall = async (idScheduledCall) => {
  try {
    return await axios
      .put(`${baseURL}/ScheduledCallControllerNew/cancelScheduledCall/${idScheduledCall}`)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error cancel ScheduledCall', { error });
  }
};

export const getScheduledCallsToCall = async (idAccount, state) => {
  try {
    return await axios
      .post(`${baseURL}/ScheduledCallControllerNew/getScheduledCallsToCall/${idAccount}/${state}`)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error service call SDK Mizutech', { error });
  }
};
/*FIN Llamadas programadas */

/*Llamadas Periodicas */
export const addPeriodicCall = async (periodic) => {
  try {
    return await axios
      .post(`${baseURL}/PeriodicCallController`, periodic)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error Add Periodic Call', { error });
  }
};

export const cancelPeriodicCall = async (idPeriodicCall) => {
  try {
    return await axios
      .put(`${baseURL}/PeriodicCallController/cancelPeriodicCallById/${idPeriodicCall}`)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error cancel PeriodicCall', { error });
  }
};

export const listPeriodicCallsByIdAndState = async (idAccount, state) => {
  try {
    return await axios
      .get(`${baseURL}/PeriodicCallController/listPeriodicCallsByIdAndState/${idAccount}/${state}`)
      .then((response) => response.data);
  } catch (error) {
    console.error('Error list PeriodicCalls', { error });
  }
};
