import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '10px',
    position: 'absolute',
    width: '50%',
    height: 'auto',
    maxHeight: '500px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #1b3c5a',
    boxShadow: theme.shadows[5],
    overflow: 'auto',
    outline: 'none',
  },
  container: {
    display: 'flex',
    color: '#01579b',
    justifyContent: 'flex-end',
    fontWeight: 'bold',
  },
  containerBtnAdd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btnAddIntent:{
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    height: '37px',
    padding: '0px 20px',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Roboto Condensed',
    fontWeight: '300',
    borderRadius: '25px',
    backgroundColor: '#e55959',
    '&:hover': {
      backgroundColor: '#e55959',
      filter: 'brightness(70%)',
    },
  },
  btnAdd: {
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    height: '37px',
    padding: '0px 20px',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Roboto Condensed',
    fontWeight: '300',
    borderRadius: '25px',
    backgroundColor: '#e55959',
    '&:hover': {
      backgroundColor: '#e55959',
      filter: 'brightness(70%)',
    },
  },
  containerDark: {
    background: '#F2F2F2',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  containerClear: {
    background: '#fff',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  containerTitle: {
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '10px',
    paddingBottom: '10px',
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: '20px',
    color: '#1b3c5a',
  },
  chip: {
    width: 'auto',
    maxWidth: '100%',
    color: '#e55959',
  },
  containerDelete: {
    display: 'flex',
  },
  btnDelete: {
    alignItems: 'center',
  },
  btnEliminar: {
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    height: '37px',
    padding: '0px 20px',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Roboto Condensed',
    fontWeight: '300',
    borderRadius: '25px',
    backgroundColor: '#e55959',
    '&:hover': {
      backgroundColor: '#e55959',
      filter: 'brightness(70%)',
    },
  },
  btnModificar: {
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    height: '37px',
    padding: '0px 20px',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Roboto Condensed',
    fontWeight: '300',
    borderRadius: '25px',
    backgroundColor: '#e55959',
    '&:hover': {
      backgroundColor: '#e55959',
      filter: 'brightness(70%)',
    },
  },
  containerStyles: {
    width: '100%',
    height: '100vh',
  },
  imgBtn: {
    marginRight: '10px',
    verticalAlign: 'bottom',
  },
  titleIntentType: {
    marginLeft: '25px',
  },
  radioIntentType: {
    marginLeft: '16px',
  },
}));

export default useStyles;
