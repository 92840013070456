import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Button } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import useStyles from './styles';
import useChartForDayWeekMonth from './useChartForDayWeekMonth';

export default function ChartForDayWeekMonth({ ID }) {
  const classes = useStyles();
  const {
    isActive,
    dataChart,
    handleClickDay,
    handleClickWeek,
    handleClickMonth,
  } = useChartForDayWeekMonth({ ID });

  const options = {
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
        },
      ],
    },
  };

  return (
    <>
      {isActive ? (
        <Bar data={dataChart} options={options} />
      ) : (
        <h1 style={{ marginLeft: '10%' }}>Seleccione una opción</h1>
      )}

      <div className={classes.root}>
        {
          <ButtonGroup
            variant='contained'
            color='primary'
            aria-label='contained primary button group'
          >
            <Button elementType='button' onClick={() => handleClickDay()}>
              Día
            </Button>
            <Button elementType='button' onClick={() => handleClickWeek()}>
              Semana
            </Button>
            <Button elementType='button' onClick={() => handleClickMonth()}>
              Mes
            </Button>
          </ButtonGroup>
        }
      </div>
    </>
  );
}
