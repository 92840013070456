import { insertIntentDatabase } from '../../../Service/tree/TreeDataBaseConsumeService'
import { insertIntent } from '../../../Service/tree/TreeDialogFlowConsumeService'
import { informationUserDatabase } from '../../../Service/platformUser/PlatformUserConsumeService';

let idIntent='';
let idDialog='';
const parentNodeMessage='Bienvenido a telliou.';
const fallBackMessage = 'No le he entendido, repita su respuesta por favor.';

export const saveIntentWelcome = async (displayName, setProgressBtn, setTextValidation, setReload) => {
    const idAccount = localStorage.getItem('idAccount');
    const expressions = /^(?!\s)([a-zA-ZÀ-ÿ0-9]\s?)+$/; // permitir letras, números, no caracteres especiales ni espacios en blanco al inicio y al final
    const emailDB = await informationUserDatabase(localStorage.getItem('idCognito'));
    const userEmail = emailDB.email.split("@")
        
    setTextValidation("")
    setProgressBtn(true)
    if (displayName === "" || displayName === "A1_") {
        setTextValidation("Ingrese el nombre del nodo.")
        setProgressBtn(false)
    } else if (displayName.length > 23 || displayName.length < 1) {
        setTextValidation("El nombre del nodo debe contener menos de 20 caracteres")
        setProgressBtn(false)
    } else if (!expressions.test(displayName.slice(displayName.indexOf("_") + 1))) {
        setTextValidation("El nombre del nodo no puede tener caracteres especiales ni espacios en blanco al inicio, ni espacios dobles.")
        setProgressBtn(false)
    } else {
        const responseRootNode = await insertIntent({
            id: idIntent,
            dialogFlowName: userEmail[0]+"-"+idDialog,
            messages: [parentNodeMessage],
            trainingPhrasesParts: [idAccount],
            contextIn: [],
            contextOut: [idIntent],
            endInteraction: true,
            fallBackIntent: false
        })

        idIntent = responseRootNode.id
        await insertIntentDatabase({
            "json_intent": JSON.stringify({
                id: idIntent,
                displayName: displayName,
                dialogFlowName: responseRootNode.dialogFlowName,
                messages: [parentNodeMessage],
                trainingPhrasesParts: [idAccount],
                contextIn: [],
                contextOut: [responseRootNode.dialogFlowName],
                endInteraction: true,
                fallBackIntent: false,
                children: 0
            }),
            "id_intent": idIntent,
            "id_account": localStorage.getItem("idAccount"),
            "messages": JSON.stringify([parentNodeMessage]),
            "fall_back_intent": 0,
            "children": 0,
            "id_type": 1,
            "level": 1,
            "state": "active",
            "id_previous_intent":0
        });

        const responseFallBackNode = await insertIntent({

            id: idIntent,
            dialogFlowName: userEmail[0]+"-"+idDialog,
            messages: [fallBackMessage],
            trainingPhrasesParts: [],
            contextIn: [responseRootNode.dialogFlowName],
            contextOut: [responseRootNode.dialogFlowName],
            endInteraction: false,
            fallBackIntent: true,
            children: 0
        })

        idIntent = responseFallBackNode.id

        await insertIntentDatabase({
            "json_intent": JSON.stringify({
                id: idIntent,
                displayName: ("A0_Nodo Recuperador de conversaciones"),
                dialogFlowName: responseFallBackNode.dialogFlowName,
                messages: [fallBackMessage],
                trainingPhrasesParts: [],
                contextIn: [responseRootNode.dialogFlowName],
                contextOut: [responseRootNode.dialogFlowName],
                endInteraction: false,
                fallBackIntent: true,
                children: 0
            }),
            "id_intent": idIntent,
            "id_account": localStorage.getItem("idAccount"),
            "messages": JSON.stringify([fallBackMessage]),
            "fall_back_intent": 1,
            "children": 0,
            "id_type": 2,
            "level": 1,
            "state": "active",
            "id_previous_intent":0
        });
        setReload("insert")
    }
    setProgressBtn(false)
}