import axios from 'axios';

import {UriConfiguration}  from '../configuration/ApiServices'
let baseURL = UriConfiguration();

export const insertEntityDialogFlow = async (data) => {
    try {
      return await axios
        .post(`${baseURL}/EntitiesDialogFlowControllerNew`, data)
        .then((response) => response.data);
    } catch (error) {
      console.error('Error insertEntityDialogFlow service', { error });
    }
  };

  export const updateEntityDialogFlow = async (body, id) => {
    try {
      return await axios
        .put(`${baseURL}/EntitiesDialogFlowControllerNew/${body}`, id)
        .then((response) => response.data);
    } catch (error){
      console.error('Error update entity DialogFlow', { error });
    }
  };

  export const deleteEntityDialogFlow = async (id) => {
    try {
      return await axios
        .delete(`${baseURL}/EntitiesDialogFlowControllerNew/${id}`)
        .then((response) => response.data);
    } catch (error){
      console.error('Error service delete Entity DialogFlow', { error });
    }
  };

  export const findEntityByIdDialogFlow = async (idEntity) => {
    try {
      return await axios
        .get(`${baseURL}/EntitiesDialogFlowControllerNew/findEntityById/${idEntity}`)
        .then((response) => response.data);
    } catch (error){
      console.error('Error service GetEntitiesDialogFlow', { error });
    }
  };
  
  export const findListEntitiesDialogFlow = async () => {
    try {
      return await axios
        .get(`${baseURL}/EntitiesDialogFlowControllerNew/findListEntities`)
        .then((response) => response.data);
    } catch (error){
      console.error('Error service findListEntitiesDialogFlow', { error });
    }
  };