import React from 'react';
import useStyle from './style';
import { Link } from 'react-router-dom';
function TextAndButton(props) {
  const style = useStyle();
  let { tittle, textForInfoRedBold, textInfo, buttonText } = props;
  let { titleCreation, textInfoCreation, buttonCreation } = props;
  let { titleMetrics, infoMetrics, buttonMetrics } = props;
  let { titleEconomic, infoEconomic, buttonEconomic } = props;
  return (
    <>
      {tittle ? <h5>{tittle}</h5> : null}
      <p className={style.interLine}>
        {textForInfoRedBold ? (
          <label className={style.tituloResaltado}>{textForInfoRedBold}</label>
        ) : null}
        {textInfo ? <label className={style.textInfo}>{textInfo}</label> : null}
      </p>
      {buttonText ? (
        <Link className={style.styleLink} to='/login'>
          <button className={style.buttonStyle}>{buttonText}</button>{' '}
        </Link>
      ) : null}

      {titleCreation ? (
        <h5 className={style.titleInfo}>{titleCreation}</h5>
      ) : null}
      <p className={style.interLineInfo}>
        {textInfoCreation ? (
          <label className={style.longText}>{textInfoCreation}</label>
        ) : null}
      </p>
      {buttonCreation ? (
        <button className={style.infoButton}>{buttonCreation}</button>
      ) : null}

      {titleMetrics ? (
        <h5 className={style.titleLeft}>{titleMetrics}</h5>
      ) : null}
      <p className={style.interLineInfo}>
        {infoMetrics ? (
          <label className={style.longText}>{infoMetrics}</label>
        ) : null}
      </p>
      {buttonMetrics ? (
        <button className={style.infoButton}>{buttonMetrics}</button>
      ) : null}

      {titleEconomic ? (
        <h5 className={style.titleLeft}>{titleEconomic}</h5>
      ) : null}
      <p className={style.interLineInfo}>
        {infoEconomic ? (
          <label className={style.longText}>{infoEconomic}</label>
        ) : null}
      </p>
      {buttonEconomic ? (
        <button className={style.infoButton}>{buttonEconomic}</button>
      ) : null}
    </>
  );
}

export default TextAndButton;
