import { makeStyles } from "@material-ui/core";
import Robot from '../../../src/assets/img/Robot.png';

const useStyles = makeStyles({
    root: {
      maxWidth: 345,
      minWidth: 345,
      margin: 40,
    },
    cardBackground: {
      backgroundColor: 'lightseagreen',
      color: 'white',
    },
    button: {
      color: 'white',
      backgroundColor: 'darkcyan',
      border: 0,
      cursor: 'pointer',
    },
    centerButton: {
      justifyContent: 'center',
      backgroundColor: 'darkcyan',
    },
    typografyTitle: {
      fontSize: 20,
    },
    typografyInfo: {
      fontSize: 14,
      marginBottom: 10,
    },
    icon: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    iconImage: {
      backgroundImage: `url(${Robot})`,
      height: 70,
      width: 70,
    },
    textLink: {
      textDecoration: 'none',
    },
  });

  export default useStyles;