import React from 'react';
import Banner from './components/banner';
import Footer from './components/footer';
import Information from './components/information';
import VideoDemo from './components/videoDemo';
import useStyle from './styles';
function LandingPage() {
  const style = useStyle();
  return (
    <>
      <section id='Banner'>
        <Banner />
      </section>

      <section id='Info' className={style.information}>
        <Information />
      </section>

      <section id='VideoDemo'>
        <VideoDemo />
      </section>

      <section id='Footer' className={style.footer}>
        <Footer />
      </section>
    </>
  );
}

export default LandingPage;
