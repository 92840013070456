import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  inputPasword: {
    width: '80%',
    height: '56px',
    borderRadius: 4,
    backgroundColor: '#fff',
    marginTop: '30px'
  },
}));

export default useStyles;
