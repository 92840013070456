import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  title: {
    lineHeight: '35px',
    marginLeft: 8,
  },
  container_button: {
    alignItems: 'center',
    display: 'flex',
  },
  buttonLogin: {
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    height: '35px',
    margin: '0px 50px',
    padding: '5px 25px',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Roboto Condensed',
    fontWeight: '400',
    borderRadius: '25px',
    backgroundColor: '#e55959',
    '&:hover': {
      backgroundColor: '#e55959',
      filter: 'brightness(70%)',
    },

  
  },
}));

export default useStyles;
