import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: '1',
    marginLeft: '10px'
  },
  appBar: {
    backgroundColor: '#1b3c5a',
    position: 'fixed',
    marginTop: '-10px',
    marginBottom: '350px'
  },
  user: {
    marginRight: theme.spacing(2),
    fontSize: '15px',
  },

  close: {
    color: '#fff',
    textDecoration: 'none',
    fontSize: '15px',
  },
  menu: {
    textDecoration: 'none',
    color: '#333',
  },
  menuItem: {
    padding: 12,
  },
  offset: theme.mixins.toolbar,
 
}));

export default useStyles;
