import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container2: {
    padding: '30px',
    height: '90vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    height: '33px',
    padding: '0px 15px',
    margin: '1em',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Roboto Condensed',
    fontWeight: '300',
    borderRadius: '25px',
    backgroundColor: '#e55959',
    '&:hover': {
      backgroundColor: '#e55959',
      filter: 'brightness(70%)',
    },
  },
  textArea: {
    width: '80%',
    marginRight: '10px',
    height: '150px',
    border: '2px solid #CCCCCC',
    background: '#F2F2F2',
    fontSize: '20px',
    fontFamily: 'arial',
  },
  form: {
    width: '80%',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  call: {
    width: '15%',
    marginTop: '-20px',
  },
  import: {
    width: '80%',
    textAlign: 'center',
    marginTop: '10px',
  },
  btn: {
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    height: '33px',
    padding: '0px 15px',
    margin: '1em',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Roboto Condensed',
    fontWeight: '300',
    borderRadius: '25px',
    backgroundColor: '#e55959',
    '&:hover': {
      backgroundColor: '#e55959',
      filter: 'brightness(70%)',
    },
  },
}));

export default useStyles;
