import React from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Graph from '../../../commons/Graph';
import {
  listAllResponseCallByIdPerDay,
  listAllResponseCallByIdPerWeek,
  listAllResponseCallByIdPerMonth,
} from '../../../Service/graphics/GraphicsConsumeService';
import { defaultValuesCallsEffective, defaultData } from '../EffectiveCalls';
const GroupButton = ({
  data,
  setData,
  legend,
  setAllCallsEffective,
  isActive,
  setIsActive,
}) => {
  let idAccount = localStorage.getItem('idAccount');
  return (
    <>
      <ButtonGroup color='primary' aria-label='outlined primary button group'>
        <Button
          onClick={() => {
            setAllCallsEffective(defaultValuesCallsEffective());
            setData(defaultData());
            (async () => {
              let responseDay = await listAllResponseCallByIdPerDay(idAccount);
              console.log(responseDay);
              setData([
                {
                  name: '12-6 am',
                  llamadasEfectivas: responseDay[0].zeroToSix,
                  amt: 100,
                },
                {
                  name: '6-12 m',
                  llamadasEfectivas: responseDay[0].sixToTwelve,
                  amt: 100,
                },
                {
                  name: '12-6 pm',
                  llamadasEfectivas: responseDay[0].twelveToEighteen,
                  amt: 100,
                },
                {
                  name: '6-12 pm',
                  llamadasEfectivas: responseDay[0].eighteenToTwentyFour,
                  amt: 100,
                },
              ]);
            })();
            setIsActive(true);
          }}
        >
          Diarias
        </Button>
        <Button
          onClick={() => {
            setAllCallsEffective(defaultValuesCallsEffective());
            setData(defaultData());
            (async () => {
              let responseWeek = await listAllResponseCallByIdPerWeek(
                idAccount,
              );
              setData([
                {
                  name: '12-6 am',
                  llamadasEfectivas: responseWeek[0].zeroToSix,
                  amt: 100,
                },
                {
                  name: '6-12 m',
                  llamadasEfectivas: responseWeek[0].sixToTwelve,
                  amt: 100,
                },
                {
                  name: '12-6 pm',
                  llamadasEfectivas: responseWeek[0].twelveToEighteen,
                  amt: 100,
                },
                {
                  name: '6-12 pm',
                  llamadasEfectivas: responseWeek[0].eighteenToTwentyFour,
                  amt: 100,
                },
              ]);
            })();
            setIsActive(true);
          }}
        >
          Semanal
        </Button>
        <Button
          onClick={() => {
            setAllCallsEffective(defaultValuesCallsEffective());
            setData(defaultData());
            (async () => {
              let responseMonth = await listAllResponseCallByIdPerMonth(
                idAccount,
              );
              setData([
                {
                  name: '12-6 am',
                  llamadasEfectivas: responseMonth[0].zeroToSix,
                  amt: 100,
                },
                {
                  name: '6-12 m',
                  llamadasEfectivas: responseMonth[0].sixToTwelve,
                  amt: 100,
                },
                {
                  name: '12-6 pm',
                  llamadasEfectivas: responseMonth[0].twelveToEighteen,
                  amt: 100,
                },
                {
                  name: '6-12 pm',
                  llamadasEfectivas: responseMonth[0].eighteenToTwentyFour,
                  amt: 100,
                },
              ]);
            })();
            setIsActive(true);
          }}
        >
          Mensual
        </Button>
      </ButtonGroup>
      {isActive && data !== [] ? (
        <Graph data={data} legend={legend} />
      ) : (
        <h1>No hay datos</h1>
      )}
    </>
  );
};

export default GroupButton;
