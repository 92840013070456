import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';
import { TextField, Typography } from '@material-ui/core';
import useStyles from './styles';
import ButtonLoading from '../../../commons/buttonLoading/ButtonLoading';
import InputPassword from '../../../commons/inputPassword/InputPassword';
import InputPasswordNew from '../../../commons/InputPasswordNew';
import { informationUserDatabase } from '../../../Service/platformUser/PlatformUserConsumeService';
import LogoTipoTelliou from '../../../assets/img/logotipo_telliou.png';
import {
  HOME_ROUTE,
  HOME_ADMIN_ROUTE,
  PASSWORD_RESET_ROUTE,
  REGISTER_ROUTE,
} from '../../../constants/Routes/constantsRoutes';

const rolAdmin = 'alejandro.upegui@ias.com.co';
const session = ({ history }) => {
  Auth.currentSession()
    .then((data) => {
      if (data.accessToken.payload.username === rolAdmin) {
        history.push(HOME_ADMIN_ROUTE);
      } else {
        history.push(HOME_ROUTE);
      }
    })
    .catch((err) => {
      localStorage.clear();
    });
};

const signIn = async ({
  history,
  username,
  password,

  clean,
  setMessageError,
  setLoading,
}) => {
  await Auth.signIn({ username, password })
    .then((response) => {
      const idCognito = response.signInUserSession.idToken.payload.sub;
      const userNameAdmin = response.username;
      localStorage.setItem('idCognito', idCognito);
      informationUser(idCognito, userNameAdmin, history, response);
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
      if (err.code === 'UserNotConfirmedException') {
        clean();
        setMessageError('Verifica tu cuenta');
      } else if (err.code === 'NotAuthorizedException') {
        clean();
        setMessageError('Contraseña o usuario inválido');
      } else if (err.code === 'UserNotFoundException') {
        clean();
        setMessageError('Este usuario no existe');
      }
    });
};

const informationUser = async (idCognito, userNameAdmin, history, response) => {
  const emailCognito = response.signInUserSession.idToken.payload.email;
  const responseDB = await informationUserDatabase(idCognito);
  const idAccount = responseDB?.id_account;
  localStorage.setItem('idAccount', idAccount);

  if (responseDB === undefined) {
    console.log('ocurrio un error en el servidor ');
  }
  if (userNameAdmin === rolAdmin) {
    history.push(HOME_ADMIN_ROUTE);
  } else if (emailCognito === responseDB.email) {
    history.push(HOME_ROUTE);
  }
};

const SignInForm = () => {
  const styles = useStyles();
  let history = useHistory();

  const [errorPassword, setErrorPassword] = useState('');
  const [errorUsername, setErrorUsername] = useState('');
  const [messageError, setMessageError] = useState('');
  const [seePassword, setSeePassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  // const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    session({ history });
  }, [history]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!username.includes('@')) {
      setErrorUsername(
        'Por favor, vuelva a escribir la dirección de correo electrónico.'
      );
    }

    if (!password) {
      setErrorPassword('Ingrese una contraseña');
    }

    await signIn({
      history,
      username,
      password,
      clean,
      setMessageError,
      setLoading,
    });
  };

  const clean = () => {
    setErrorUsername('');
    setErrorPassword('');
  };

  return (
    // !isAuth && (
    <form className={styles.container} onSubmit={handleSubmit}>
      <div className={styles.containerLogin}>
        <div className={styles.login}>
          <div className={styles.logoTelliou}>
            <img src={LogoTipoTelliou} alt='LogoTipo Telliou' />
          </div>

          {/* <InputEmail
            helperText={errorUsername}
            onChange={(e) => setUsername(e.target.value)}
            label='Correo electrónico*'
            error={Boolean(errorUsername)}
          /> */}
          <input
            className={styles.inputLogin}
            type='email'
            name='correo'
            id=''
            placeholder='Correo electrónico*'
            onChange={(e) => setUsername(e.target.value)}
            helperText={errorUsername}
            error={Boolean(errorUsername)}
          />

          {/* <div>
            <input className={styles.inputPasword}
              type='password'
              name='password'
              id='password'
              placeholder='Contraseña*'
            />
            <i className={styles.iEyes} id='togglePassword'></i>
          </div> */}
          <InputPasswordNew
            visibility={seePassword}
            // helperText={errorPassword}
            onChange={(e) => setPassword(e.target.value)}
            onClick={() => setSeePassword(!seePassword)}
            // error={Boolean(errorPassword)}
            // label='Contraseña*'
          />

          <Typography className={styles.messageError} variant='subtitle1'>
            {messageError}
          </Typography>

          <div className={styles.wrapper}>
            <Link to={PASSWORD_RESET_ROUTE} className={styles.forgetPassword}>
              ¿Olvidó su contraseña?
            </Link>
            <ButtonLoading loading={loading} label='Iniciar sesión' />
          </div>
          <div>
            <h3 className={styles.createAccount}>¿Aún no tienes cuenta?</h3>
            <Link to={REGISTER_ROUTE} className={styles.createAccountLink}>
              Crear una cuenta
            </Link>
          </div>
        </div>
      </div>
    </form>
    // )
  );
};

export default SignInForm;
