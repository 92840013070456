import axios from 'axios';
import {UriConfiguration}  from '../configuration/ApiServices'

let baseURL = UriConfiguration();

export const insertUser = async (data) => {
    try {
      return await axios
        .post(`${baseURL}/PlatformUserControllerNew`, data)
        .then((response) => response.data);
    } catch (error) {
      console.error('Error insert user service', { error });
    }
  };
  
  export const informationUserDatabase = async (sub) => {
    try {
      return await axios
        .get(`${baseURL}/PlatformUserControllerNew/findByIdCognito/${sub}`)
        .then((response) => response.data);
    } catch {
      console.error('Error service IntentService');
    }
  };

export const findRolPlatformUser = async (idUser) => {
  try {
    return await axios
      .get(`${baseURL}/api/v1/platform/rol/${idUser}`)
      .then((response) => response.data.idRol);
  } catch {
    console.error('Error service IntentService');
  }
};
