import { Container, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import ModalForm from '../../components/modals/modalForm/ModalForm';
import {
  findAccountById,
  updateAccountById,
  getListVoices,
} from '../../Service/account/AccountConsumeService';
import IconoPlanSeleccionado from '../../assets/img/icono_plan_seleccionado.png';
import useStyles from './styles';

const Account = () => {
  const idAccount = localStorage.getItem('idAccount');
  const [open, setOpen] = useState(false);
  const [requestData, setRequestData] = useState(dataRequest());
  const [listVoices, setListVoices] = useState({ voicesResponse: [] });
  const [botVoice, setBotVoice] = useState();

  useEffect(() => {
    const getAccount = async () => {
      let account = await findAccountById(idAccount);
      let voices = await getListVoices();
      setRequestData(account);
      setListVoices({ voicesResponse: voices });
    };
    getAccount();
  }, []);
  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickSend = async () => {
    await updateAccountById(idAccount, requestData);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };

  const handleChangeBotVoice = (element) => {
    listVoices.voicesResponse.forEach((voice) => {
      if (voice.id === element) {
        setBotVoice(voice.id_bucket);
      }
    });
  };
  const classes = useStyles();
  return (
    <>
      <section className={classes.Container}>
        <div className={classes.Container__user}>
          <Typography className={classes.textTitle} variant='h3'>
            Cuenta
          </Typography>
          <button className={classes.SetUpButton} onClick={handleClickOpen}>
            Configurar BOT
          </button>
          <hr className={classes.hrCuenta} />
        </div>
        <div className={classes.Container__password}>
          <form className={classes.root}>
            <TextField
              id='outlined-password-input'
              label='Contraseña actual'
              type='password'
              autoComplete='current-password'
              variant='outlined'
              spacing={2}
            />
            <TextField
              id='outlined-password-input'
              label='Contraseña nueva'
              type='password'
              autoComplete='current-password'
              variant='outlined'
            />
          </form>
          <button className={classes.ButtonPasswordSave}>
            Actualizar contraseña
          </button>
          <hr className={classes.hrPassword} />
        </div>

        <div className={classes.Container__package}>
          <div className={classes.rootCardGratis}>
            <h2 className={classes.titleGratis}>Gratuito</h2>
            <img
              src={IconoPlanSeleccionado}
              alt='Icono plan seleccionado'
              className={classes.imgGratis}
            />
          </div>
          <div className={classes.rootCardOro}>
            <h2 className={classes.titleOro}>Plan Oro</h2>
            <button className={classes.buttonSubscription}>¡COMPRAR!</button>
          </div>
          <div className={classes.rootCardPlata}>
            <h2 className={classes.titlePlata}>Plan Plata</h2>
            <button className={classes.buttonSubscription}>¡COMPRAR!</button>
          </div>
          <div className={classes.rootCardBronce}>
            <h2 className={classes.titleBronce}>Plan Bronce</h2>
            <button className={classes.buttonSubscription}>¡COMPRAR!</button>
          </div>
        </div>
      </section>
      <ModalForm
        open={open}
        handleClose={handleClose}
        requestData={requestData}
        setRequestData={setRequestData}
        botVoice={botVoice}
        listVoices={listVoices}
        setListVoices={setListVoices}
        handleChangeBotVoice={handleChangeBotVoice}
        handleSend={() => handleClickSend()}
      />
    </>
  );
};

const dataRequest = () => ({
  bot_name: '',
  idSubscriptionModel: '',
  sip_address: '',
  sip_extension: '',
  id_voice: '',
});
export default Account;
