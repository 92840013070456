import React from 'react';
import { useStyle } from './styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';

import ChartForDayWeekMonth from '../../components/charts/chartForDayWeekMonth';
import { ParameterConectionCard } from '../../components/card/parameterConectionCard';
import LoadTreeNodes from '../../components/Nodes/loadTreeNodes';
import ChartPieForMonthMinutes from '../../components/charts/chartPieForMonthMinutes';

export default function ReportByBot(state) {
  const ID = state.location.state.id;
  let classes = useStyle();

  return (
    <>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={3} className={classes.centerInfo}>
          <Card className={classes.card}>
            <CardHeader
              className={classes.cardBackground}
              title={'Reporte Por Llamadas'}
            />
            <CardContent className={classes.content}>
              <ChartForDayWeekMonth ID={ID} />
            </CardContent>
          </Card>

          <Card className={classes.card}>
            <CardHeader
              className={classes.cardBackground}
              title={'Reporte Por Minutos/Mes'}
            />
            <CardContent className={classes.chartPie}>
              <ChartPieForMonthMinutes ID={ID} />
            </CardContent>
          </Card>

          <Card className={classes.card}>
            <CardHeader
              className={classes.cardBackground}
              title={'Árbol De Decisiones'}
            />
            <CardContent className={classes.content}>
              <LoadTreeNodes id_account={ID} />
            </CardContent>
          </Card>

          <Card className={classes.card}>
            <CardHeader
              className={classes.cardBackground}
              title={'Parámetros de conexión'}
            />
            <CardContent className={classes.content}>
              <ParameterConectionCard state={state} classes={classes} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
