import React, { useReducer, createContext, useContext } from 'react';

const initalState = {
  authed: false,
  idAccount: '',
  idCognito: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'signIn':
      return {
        ...state,
        authed: true,
      };
    case 'signOut':
      return {
        ...state,
        authed: true,
      };
    case 'ID_COGNITO': {
      return {
        ...state,
        idCognito: action.idCognito,
      };
    }
    case 'ID_ACCOUNT': {
      return {
        ...state,
        idAccount: action.idAccount,
      };
    }
    default:
      throw new Error();
  }
};

export const useStorage = () => useContext(StorageContext);

const StorageContext = createContext();

const Storage = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initalState);
  return (
    <StorageContext.Provider value={{ state, dispatch }}>
      {children}
    </StorageContext.Provider>
  );
};

export default Storage;
